import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TopBar from '../../../global/TopBar/index';
import AlertBar from '../other-components/AlertBar';
import QuickVideos from '../card-components/QuickVideos';
import ExploreYourOptions from '../card-components/ExploreYourOptions';
import CourseCalendar from '../card-components/CourseCalendar';
import ReferAFriend from '../card-components/ReferAFriend';
import PlaybooksAndResourcesCard from '../card-components/PlaybooksAndResourcesCard';
import ProgramProgress from '../card-components/ProgramProgress';
import AWordFromKate from '../card-components/AWordFromKate';

const RegularDashboard = ({ modal }) => (
  <>
    <TopBar modal={modal} />
    <AlertBar modal={modal} />
    <div
      className="position-relative w-100 px-8 pb-64 pt-136"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
      }}
    >
      <Container style={{ maxWidth: '1856px' }}>
        <Row className="w-100 m-0 pb-16 justify-content-center">
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <QuickVideos modal={modal} />
          </Col>
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <ExploreYourOptions modal={modal} />
          </Col>

          <Col lg={3} xs={12} className="h-100 mt-lg-0 mt-32 pe-lg-0">
            <Row className="m-0">
              <Col
                xs={12}
                className="d-none d-lg-block m-0 p-0"
                style={{ height: '100%' }}
              >
                <CourseCalendar modal={modal} />
                <ReferAFriend />
              </Col>
              <Col
                md={6}
                xs={12}
                className="d-block d-lg-none m-0 ps-0 pe-md-16 pe-0 py-0 mb-md-0 mb-32"
                style={{ height: '100%' }}
              >
                <CourseCalendar modal={modal} />
              </Col>
              <Col
                md={6}
                xs={12}
                className="d-block d-lg-none m-0 ps-md-16 ps-0 pe-0 py-0"
                style={{ height: '100%' }}
              >
                <ReferAFriend />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="w-100 m-0 pt-16 justify-content-center">
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <PlaybooksAndResourcesCard modal={modal} />
          </Col>
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <ProgramProgress modal={modal} />
          </Col>
          <Col lg={3} md={6} xs={12} className="mt-lg-0 mt-32 pe-lg-0">
            <AWordFromKate modal={modal} />
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default RegularDashboard;
