import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';

export const isFullValidFein = (fein) => {
  if (!fein?.length) {
    return false;
  }

  const feinNumbers = fein.replace('-', '');
  const invalidSequences = ['123456789', '987654321'];

  if (/^(.)\1+$/.test(feinNumbers)) { // check if all digits are same
    return false;
  }

  if (invalidSequences.includes(feinNumbers)) {
    return false;
  }

  if (fein.includes('-') && fein.length === 10) {
    return true;
  }

  if (!fein.includes('-') && fein.length === 9) {
    return true;
  }
  return false;
};

const Ein = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
}) => {
  const [maskedFeinValue, setMaskedFeinValue] = useState(isFullValidFein(value) ? `##-###${value.substring(6)}` : '');
  const [actualFeinValue, setActualFeinValue] = useState(value ?? '');

  const handleChange = (e) => {
    setActualFeinValue(e.target.value);
    const displayValue = isFullValidFein(e.target.value) ? maskedFeinValue : actualFeinValue ?? '';
    setMaskedFeinValue(displayValue);
    onChange(e);
  };

  return (
    <div className={className}>
      {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
      <div>
        <TextMask
          id={name}
          mask={[/[0-9#]/, /[0-9#]/, '-', /[0-9#]/, /[0-9#]/, /[0-9#]/, /[0-9#]/, /[0-9#]/, /[0-9#]/, /[0-9#]/]}
          Component={InputAdapter}
          className={`form-control ${inputClassName}`}
          value={actualFeinValue}
          guide={false}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

// const Ein = ({
//   label,
//   name,
//   value,
//   onChange,
//   className,
//   labelClassName,
//   inputClassName,
// }) => (
//   <div className={className}>
//     <Form.Label className={labelClassName}>{label}</Form.Label>
//     <div>
//       <Form.Control
//         name={name}
//         type="number"
//         value={value}
//         id="inputEin"
//         className={inputClassName}
//         onChange={onChange}
//       />
//     </div>
//   </div>
// );

export default Ein;
