/* eslint-disable react/jsx-boolean-value */
import { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Login from 'components/views/Login';
import userSignal from 'signals/User.Signal';
import PrivateRoute from 'components/global/Routes/PrivateRoute';
import PublicRoute from 'components/global/Routes/PublicRoute';
import Loader from 'components/global/Loader';
import Signup from 'components/views/Signup';
import OnBoarding from 'components/views/OnBoarding';
import Videos from 'components/views/Dashboard/Videos';
import SelectPlan from 'components/views/SelectPlan';
import OrderConfirmation from 'components/views/OrderConfirmation';
import Session from 'components/views/Sessions';
import SessionLoader from 'components/views/SessionLoader';
import Packages from 'components/views/Packages';
import Package from 'components/views/Package';
import Settings from 'components/views/Settings';
import SessionsList from 'components/views/SessionsList';
import PackagesList from 'components/views/PackageList';
import SessionCalendar from 'components/views/Calendar';
import Scheduling from 'components/views/Scheduling';
import EditorSessionsList from 'components/views/EditorSessionList/EditorSessionsList';
import PlaybooksAndResources from 'components/views/PlaybooksAndResources';
import TermsAndConditions from 'components/views/TermsAndConditions';
import CheckoutConfirmation from 'components/views/CheckoutConfirmation';
import ForgotPassword from 'components/views/ForgotPassword';
import AgencyClientsList from 'components/views/Agency/Clients';
import ClientDetails from 'components/views/Agency/Client-Details/ClientDetails';
import AgencyPurchasesList from 'components/views/Agency/Purchases';
import AgencyVideosList from 'components/views/Agency/Videos';
import AgencyResourcesList from 'components/views/Agency/Resources';
import AgencyScheduleList from 'components/views/Agency/Schedule';
import Dashboard from '../../views/Dashboard/index';
import InputGroups from '../InputGroups';
import PDFViewer1 from '../SessionComponents/PdfViewer/PDFViewer1';

const LoadingSuspense = () => (
  <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
    <Loader message="Loading..." className="text-center" />
  </div>
);

const UserRoutes = () => (
  <Switch>
    <Route exact path="/">
      {userSignal?.value?.isSignedIn ? (
        <Redirect to="/dashboard" />
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
    <Suspense fallback={<LoadingSuspense />}>
      {/* Public Routes  */}
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/forgot-password" component={ForgotPassword} />
      <PublicRoute path="/signup/:token?" component={Signup} />
      <PublicRoute path="/agency/signup" isAgency={true} component={Signup} />
      <PublicRoute path="/order-confirmation" component={OrderConfirmation} />

      {/* Private Routes  */}
      <PrivateRoute path="/pdf-viewer" component={PDFViewer1} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/onBoarding" component={OnBoarding} />
      <PrivateRoute path="/select-plan" component={SelectPlan} />
      <PrivateRoute path="/scheduling" component={Scheduling} />
      <PrivateRoute path="/resources" component={PlaybooksAndResources} />
      <PrivateRoute
        path="/session/:programId/:sessionId/screen/:screenId"
        component={Session}
      />
      <PrivateRoute
        path="/session/loading/:programId"
        component={SessionLoader}
      />
      <PrivateRoute path="/packages" component={Packages} />
      <PrivateRoute
        path="/checkout/confirmation"
        component={CheckoutConfirmation}
      />
      <PrivateRoute path="/package/:id" component={Package} />
      <PrivateRoute path="/calendar" component={SessionCalendar} />
      <PrivateRoute path="/settings" component={Settings} />
      <PrivateRoute path="/input-groups" component={InputGroups} />
      <PrivateRoute
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <PrivateRoute path="/sessions" component={SessionsList} />
      <PrivateRoute path="/editor/programs" component={PackagesList} />
      <PrivateRoute
        path="/editor/sessions/:programId"
        component={EditorSessionsList}
      />
      <PrivateRoute
        path="/editor/:programId/:sessionId/screen/:screenId"
        component={Session}
      />
      <PrivateRoute path="/videos" component={Videos} />
      <PrivateRoute path="/agency/videos" component={AgencyVideosList} />
      <PrivateRoute path="/agency/clients" component={AgencyClientsList} />
      <PrivateRoute path="/agency/client/:id" component={ClientDetails} />
      <PrivateRoute path="/agency/billing" component={AgencyPurchasesList} />
      <PrivateRoute path="/agency/resources" component={AgencyResourcesList} />
      <PrivateRoute path="/agency/calendar" component={AgencyScheduleList} />
    </Suspense>
  </Switch>
);

export default UserRoutes;
