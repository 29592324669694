/* eslint-disable no-multi-assign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable eqeqeq */

import { COLLECTIONS, CLOCK_STATES } from 'constants/index';
import alertSignal from 'signals/Alert.signal';
import Signal, { Effect } from 'libs/signals';
import { getNowSeconds } from 'utils/time';
import { firestore } from 'utils/firebase';
import subscribe from 'libs/subscribe';
import ScaleChart from 'components/global/SessionComponents/ScaleChart/ScaleChart';
// import Chart from 'components/global/SessionComponents/Chart';
import TableRadioWithImage from 'components/global/SessionComponents/TableRadioWithImage/TableRadioWithImage';
import FiveWhysResults from 'components/global/InputResults';
import RepeaterTextInputTableWithTabs from 'components/global/SessionComponents/RepeaterTextInputTable/RepeaterTextInputTableWithTabs';
import TopTargetAudienceInput from 'components/global/SessionComponents/TableTargetComponents/TargetAudience';
import TargetAudienceResults from 'components/global/SessionComponents/TableTargetComponents/TargetAudienceResults';
import TableRadioWithImageReview from 'components/global/SessionComponents/TableRadioWithImage/TableRadioWithImageReview';
// import RepeaterTextInputTableReview from 'components/global/SessionComponents/RepeaterTextInputTable/RepeaterTextInputTableReview';
import RepeaterTextInputTableWithTabsReview from 'components/global/SessionComponents/RepeaterTextInputTable/RepeaterTextInputTableWithTabsReview';
// import TextArea from 'components/global/Field/FieldComponents/TextArea';
import TaglineChart from 'components/global/SessionComponents/TaglineChart';
import TaglineTableResults from 'components/global/SessionComponents/TaglineTableResults';
import TopTaglineChart from 'components/global/SessionComponents/TopTagline/TopTaglineChart';
import TopTaglineReview from 'components/global/SessionComponents/TopTagline/TopTaglineReview';
import InputGroupsWithTabs from 'components/global/InputGroupsTabs/InputGroupWithTabs';
import userSignal from 'signals/User.Signal';
import Fetcher from '../../../libs/fetcher';
import TableMultiSelect from '../TableMultiSelect/index';
import MultiColumnTable from '../MultiColumnTable/components/MulticolumnTable';
// import TableMultiSelectResultsTable from '../TableMultiSelect/components/MultiSelectResultsTable';
import TableMultiSelectResults from '../TableMultiSelect/MultiSelectResults';
import TextAreaPrompt from './components/TextAreaPrompt';
import MultiColumnResultsTable from '../MultiColumnTable/components/ResultsTable';
import { notesSignal } from './components/ResourceTab';

export const screenSignal = Signal({});
export const sessionSignal = Signal({});
export const checkpointModeSignal = Signal('review');
export const trifectaSignal = Signal({});
export const programSignal = Signal({});
export const programPointerSignal = Signal({});

export const timerSignal = Signal({ remaining: null, session: '' });

const fetcher = new Fetcher();

export const updateSessionAndScreenSignals = async ({ screenId, sessionId }) => {
  sessionSignal.reset();
  screenSignal.reset();

  const changeDate = new Date('2023-11-13');
  const whenDocWasCreated = programSignal.value._createdAt.toDate();
  let sessionObj;

  const sessionRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id).collection(sessionId).doc(programSignal.value._id);
  const sessionSnapshot = await sessionRef.get();
  if (whenDocWasCreated < changeDate) {
    sessionObj = programSignal.value.sessions?.find(({ uid }) => uid === sessionId);
  } else {
    sessionObj = sessionSnapshot.data();
  }
  const screenObj = sessionObj.screens?.find(({ uid }) => uid === `${sessionId}-${screenId}`);

  if (screenObj?.checkpoints) {
    screenObj?.checkpoints?.forEach((screen) => {
      screen.prompts?.forEach((prompt) => {
        if (!prompt.screenId || !prompt.sessionId) {
          prompt.screenId = screen.screenId;
          prompt.sessionId = screen.sessionId;
        }
      });
    });
  }

  if (!timerSignal.value.remaining) {
    timerSignal?.update({
      remaining: sessionObj.remaining,
      session: sessionObj.uid,
    });
  } else if (timerSignal.value.remaining !== sessionObj.remaining && timerSignal.value.session !== sessionObj.uid) {
    timerSignal?.update({
      remaining: sessionObj.remaining,
      session: sessionObj.uid,
    });
  } else {
    sessionSignal.update({
      remaining: timerSignal.value.remaining,
    });
  }

  sessionSignal.update(sessionObj);
  screenSignal.update({
    screenNum: screenId,
    ...screenObj,
  });
};

export const handleFormChange = ({ name, value }) => {
  sessionSignal.update({
    [name]: value,
  });
};

export const togglTimer = () => {
  let interval;
  if (sessionSignal.value.state === CLOCK_STATES.active && timerSignal.value.remaining > 0) {
    interval = setInterval(() => {
      const initRemaining = timerSignal.value.remaining;

      timerSignal.update({
        remaining: Math.ceil(initRemaining - 1),
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }

  if (sessionSignal.value.state === CLOCK_STATES.paused) {
    return clearInterval(interval);
  }

  return clearInterval(interval);
};

Effect(() => togglTimer(), [timerSignal.value]);

export const progressBarStyle = ({ current, count }) => {
  if (!current && !count) {
    return 0;
  }
  const individualWidth = (100 / count);

  return individualWidth * current;
};

export async function fetchAndSetScreenConfig({ programId, sessionUid, screenId, token }) {
  try {
    screenSignal.reset();
    const results = await fetcher.get({
      path: `/ sessions / ${programId} / ${sessionUid} / screen / ${screenId}`,
      query: { airtable: 'true' },
      headers: { Authorization: token },
    });

    return results[0];
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'Unable to load screen config',
      error,
    });
    return error;
  }
}

export async function subscribeOrGetProgram({ programId, isEditor, token }) {
  try {
    if (isEditor) {
      const results = await fetcher.get({
        path: '/programs/airtable',
        query: { fetchOnly: true, programId },
        headers: { Authorization: token },
      });
      programSignal.update({
        ...results,
        editorMode: true,
      });
    } else {
      const results = await fetcher.get({
        path: `/programs/${programId}`,
        query: { fetchOnly: true, programId },
        headers: { Authorization: token },
      });
      programPointerSignal.update({
        ...results,
      });
      await subscribe({
        collection: COLLECTIONS.programs,
        docId: programId,
        signal: programSignal,
        shouldCreateIfNotExist: false,
      });
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export async function pauseTimer() {
  let sessionRef;
  const changeDate = new Date('2023-11-13');
  const programRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);
  const programGet = await programRef.get();
  const programDoc = programGet.data();
  const whenDocWasCreated = programDoc._createdAt.toDate();

  if (whenDocWasCreated < changeDate) {
    sessionRef = programRef;
  }

  sessionRef = programRef.collection(sessionSignal.value.uid).doc(programSignal.value._id);

  if (whenDocWasCreated < changeDate) {
    firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(sessionRef);
      if (!doc.exists) {
        console.log('Document does not exist');
      }

      const { sessions } = doc.data();
      const sessionIdxToUpdate = sessions.findIndex(session => session.uid === sessionSignal.value.uid);

      if (sessions && sessions[sessionIdxToUpdate] && sessionIdxToUpdate !== -1) {
        const timeEntries = [...sessions[sessionIdxToUpdate].timeEntries, {
          start: sessions[sessionIdxToUpdate].lastStart,
          end: getNowSeconds(),
          duration: getNowSeconds() - sessions[sessionIdxToUpdate].lastStart,
        }];

        sessions[sessionIdxToUpdate].state = CLOCK_STATES.paused;
        sessions[sessionIdxToUpdate].timeEntries = timeEntries;
        sessions[sessionIdxToUpdate].remaining = timerSignal.value.remaining;

        transaction.update(sessionRef, { sessions });
        sessionSignal.update({ state: CLOCK_STATES.paused });
        // timerSignal.update({ remaining });
      } else {
        console.log('Session not found at the specified index');
      }
    });
  } else {
    firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(sessionRef);
      if (!doc.exists) {
        console.log('Document does not exist');
      }

      const sessionDoc = doc.data();

      if (sessionDoc) {
        const timeEntries = [...sessionDoc.timeEntries, {
          start: sessionDoc.lastStart,
          end: getNowSeconds(),
          duration: getNowSeconds() - sessionDoc.lastStart,
        }];

        sessionDoc.state = CLOCK_STATES.paused;
        sessionDoc.timeEntries = timeEntries;
        sessionDoc.remaining = timerSignal.value.remaining;

        transaction.update(sessionRef, sessionDoc);
        sessionSignal.update({ state: CLOCK_STATES.paused });
        // timerSignal.update({ remaining });
      } else {
        console.log('Session not found at the specified index');
      }
    });
  }
}

export async function startTimer() {
  let sessionRef;
  const changeDate = new Date('2023-11-13');
  const programRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);
  const programGet = await programRef.get();
  const programDoc = programGet.data();
  const whenDocWasCreated = programDoc._createdAt.toDate();

  if (whenDocWasCreated < changeDate) {
    sessionRef = programRef;
  }

  sessionRef = programRef.collection(sessionSignal.value.uid).doc(programSignal.value._id);

  if (whenDocWasCreated < changeDate) {
    firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(sessionRef);
      if (!doc.exists) {
        console.log('Document does not exist');
      }
      console.log({ doc });

      const { sessions } = doc?.data();
      const sessionIdxToUpdate = sessions?.findIndex(session => session.uid === sessionSignal.value.uid);

      if (sessions && sessions[sessionIdxToUpdate] && sessionIdxToUpdate !== -1) {
        sessions[sessionIdxToUpdate].state = CLOCK_STATES.active;
        sessions[sessionIdxToUpdate].lastStart = getNowSeconds();

        transaction.update(sessionRef, { sessions });
        sessionSignal.update({ state: CLOCK_STATES.active });
      } else {
        console.log('Session not found at the specified index');
      }
    });
  } else {
    firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(sessionRef);
      if (!doc.exists) {
        console.log('Document does not exist');
      }

      const sessionDoc = doc?.data();

      if (sessionDoc) {
        sessionDoc.state = CLOCK_STATES.active;
        sessionDoc.lastStart = getNowSeconds();

        transaction.update(sessionRef, sessionDoc);
        sessionSignal.update({ state: CLOCK_STATES.active });
      } else {
        console.log('Session not found at the specified index');
      }
    });
  }
}

function getPromptLocation({ prompt }) {
  const changeDate = new Date('2023-11-13');
  const whenDocWasCreated = programSignal.value._createdAt.toDate();

  if (whenDocWasCreated < changeDate) {
    const sessionIdx = programSignal.value
      .sessions
      .findIndex(({ uid }) => prompt.sessionId === uid);

    const screenIdx = programSignal?.value
      .sessions[sessionIdx]
      .screens
      .findIndex(({ uid }) => prompt.screenId === uid);

    const { prompts } = programSignal.value
      .sessions[sessionIdx]
      .screens[screenIdx];

    const promptIdx = prompts.findIndex(({ id }) => id === prompt.id);

    return {
      sessionIdx, screenIdx, promptIdx,
    };
  }

  let prompts;
  let screenIdx;
  let promptIdx;

  if (prompt.sessionId !== sessionSignal.value.uid) {
    const sessionIdx = programPointerSignal.value.sessions.findIndex(({ uid }) => prompt.sessionId === uid);

    screenIdx = programPointerSignal.value.sessions[sessionIdx].screens?.findIndex(({ uid }) => prompt.screenId === uid);

    prompts = programPointerSignal.value.sessions[sessionIdx].screens[screenIdx].prompts;

    promptIdx = prompts?.findIndex(({ id }) => id === prompt.id);

    return {
      screenIdx, promptIdx, sessionIdx,
    };
  }

  const session = sessionSignal.value;
  screenIdx = session?.screens?.findIndex(({ uid }) => prompt.screenId === uid);
  if (session?.screens?.length > 0) {
    prompts = session?.screens[screenIdx]?.prompts;
  }
  promptIdx = prompts?.findIndex(({ id }) => id === prompt.id);

  return {
    screenIdx, promptIdx,
  };
}

export async function handlePromptChange({ value, prompt }) {
  const changeDate = new Date('2023-11-13');
  const whenDocWasCreated = programSignal.value._createdAt.toDate();

  if (whenDocWasCreated < changeDate) {
    const tmpProgram = programSignal.value;
    const {
      sessionIdx, screenIdx, promptIdx,
    } = getPromptLocation({ prompt });
    tmpProgram
      .sessions[sessionIdx]
      .screens[screenIdx]
      .prompts[promptIdx].value = value;

    await firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id).update(tmpProgram);
  } else {
    const { screenIdx, promptIdx } = getPromptLocation({ prompt });
    if (prompt.sessionId !== sessionSignal.value.uid) {
      const sessionRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id).collection(prompt.sessionId).doc(programSignal.value._id);
      const sessionGet = await sessionRef.get();
      const tempSession = sessionGet.data();
      tempSession.screens[screenIdx].prompts[promptIdx].value = value;
      await firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id).collection(prompt.sessionId).doc(programSignal.value._id)
        .update(tempSession);
    } else {
      const tmpSession = sessionSignal.value;
      tmpSession.screens[screenIdx].prompts[promptIdx].value = value;
      await firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id).collection(sessionSignal.value.uid).doc(programSignal.value._id)
        .update(tmpSession);
    }
  }
}

export function handleUpdateAnswersClick(mode) {
  checkpointModeSignal.update(mode);
}

export function getPromptValueFromProgramSignal(prompt) {
  const changeDate = new Date('2023-11-13');
  const whenDocWasCreated = programSignal.value._createdAt.toDate();

  if (whenDocWasCreated < changeDate) {
    const {
      sessionIdx, screenIdx, promptIdx,
    } = getPromptLocation({ prompt });

    return programSignal.value
      .sessions[sessionIdx]
      .screens[screenIdx]
      .prompts[promptIdx].value;
  }

  const {
    screenIdx, promptIdx, sessionIdx,
  } = getPromptLocation({ prompt });
  if (prompt.sessionId !== sessionSignal.value.uid) {
    return programPointerSignal.value.sessions[sessionIdx]?.screens[screenIdx]?.prompts[promptIdx]?.value;
  }
  if (sessionSignal.value.screens?.length > 0) {
    return sessionSignal.value.screens[screenIdx]?.prompts[promptIdx]?.value;
  }
}

export const setScreenToComplete = async ({ screenId, sessionId }) => {
  const changeDate = new Date('2023-11-13');
  const programRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);
  const programGet = await programRef.get();
  const programDoc = programGet.data();
  const whenDocWasCreated = programDoc._createdAt.toDate();
  let sessionRef;
  // Connect to Firestore
  if (whenDocWasCreated < changeDate) {
    sessionRef = programRef;
  }

  sessionRef = programRef.collection(sessionId).doc(programSignal.value._id);

  // Get the session document
  const doc = await sessionRef.get();

  if (!doc.exists) {
    console.log('[DEBUG] Document does not exist');
    return;
  }

  if (whenDocWasCreated < changeDate) {
    const { sessions } = doc.data();

    const sessionIdxToUpdate = sessions.findIndex(session => session.uid === sessionId);

    if (sessionIdxToUpdate === -1) {
      console.log('[DEBUG] Session not found');
      return;
    }

    const updatedSessions = [...sessions];
    const sessionToUpdate = { ...updatedSessions[sessionIdxToUpdate] };
    const updatedScreens = [...sessionToUpdate.screens];

    const screenToUpdateIndex = Number(screenId) - 1;

    if (screenToUpdateIndex < 0 || screenToUpdateIndex >= updatedScreens.length) {
      console.log('[DEBUG] Invalid screen ID');
      return;
    }

    if (updatedScreens[screenToUpdateIndex].completed === true) {
      console.log(`[DEBUG] ${sessionId}-${screenId} Screen is already completed`);
      return;
    }

    // Add the "completed" field to the specific scree
    updatedScreens[screenToUpdateIndex].completed = true;
    sessionToUpdate.screens = updatedScreens;
    updatedSessions[sessionIdxToUpdate] = sessionToUpdate;

    console.log(`[DEBUG] completing screen ${sessionId}-${screenId}`);
    await sessionRef.update({ sessions: updatedSessions });
  } else {
    const sessionData = doc.data();
    const { screens } = sessionData;
    const screenToUpdateIndex = Number(screenId) - 1;

    if (screenToUpdateIndex < 0) {
      console.log('[DEBUG] Invalid screen ID');
      return;
    }

    if (screens[screenToUpdateIndex].completed) {
      console.log(`[DEBUG] ${sessionId}-${screenId} Screen is already completed`);
      return;
    }

    screens[screenToUpdateIndex].completed = true;

    await doc.ref.update({ screens });
  }
};

export const switchPromptType = ({ type, prompt }) => {
  switch (type) {
    case 'personalityChart':
      return <ScaleChart prompt={prompt} />;
    case 'chart':
      return <TextAreaPrompt prompt={prompt} />;
    case 'brandArchitecture':
      return <TableRadioWithImage prompt={prompt} />;
    case 'tableMultiSelect':
      return <TableMultiSelect prompt={prompt} />;
    case 'repeaterTextInputTable':
      return <TopTargetAudienceInput prompt={prompt} />;
    case 'topTargetAudience':
      return <TopTargetAudienceInput prompt={prompt} />;
    case 'targetTableBuyerCriteria':
      return <RepeaterTextInputTableWithTabs prompt={prompt} />;
    case 'multiColumnTable':
      return <MultiColumnTable prompt={prompt} />;
    /*
    case 'mutiColumnTable':
      return <MultiColumnTable prompt={prompt} />;
     */
    case 'fiveWhys':
      return <InputGroupsWithTabs prompt={prompt} />;
    case 'inputGroupsRepeaterWithTabsInput2':
      return <TaglineChart prompt={prompt} />;
    case 'topTagline':
      return <TopTaglineChart prompt={prompt} />;
    case 'textarea':
      return <TextAreaPrompt prompt={prompt} />;
    default:
      return <TextAreaPrompt prompt={prompt} />;
  }
};

export const getCheckPointChart = ({ type, prompt }) => {
  switch (type) {
    case 'personalityChart':
      return <ScaleChart prompt={prompt} />;
    case 'chart':
      return <TextAreaPrompt prompt={prompt} />;
    case 'topTargetAudience':
      return <TargetAudienceResults prompt={prompt} />;
    case 'repeaterTextInputTable':
      return <TargetAudienceResults prompt={prompt} />;
    case 'brandArchitecture':
      return <TableRadioWithImageReview prompt={prompt} />;
    case 'tableMultiSelect':
      return <TableMultiSelectResults prompt={prompt} />;
    case 'targetTableBuyerCriteria':
      return <RepeaterTextInputTableWithTabsReview prompt={prompt} />;
    /*
 case 'mutiColumnTable':
   return <MultiColumnResultsTable prompt={prompt} />;
  */
    case 'multiColumnTable':
      return <MultiColumnResultsTable prompt={prompt} />;
    case 'fiveWhys':
      return <FiveWhysResults prompt={prompt} />;
    case 'inputGroupsRepeaterWithTabsInput2':
      return <TaglineTableResults prompt={prompt} />;
    case 'topTagline':
      return <TopTaglineReview prompt={prompt} />;
    default:
  }
};

export const handleSessionCompleted = async () => {
  notesSignal.reset();
  const sessionRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);
  try {
    const doc = await sessionRef.get();
    if (!doc.exists) {
      console.log('Document does not exist');
      return;
    }

    const { sessions } = doc.data();
    const sessionIdxToUpdate = sessions.findIndex(session => session.uid === sessionSignal.value.uid);

    if (sessionIdxToUpdate !== -1) {
      const updatedSessions = [...sessions];
      updatedSessions[sessionIdxToUpdate].completed = true;

      console.log(`[DEBUG] ${sessionSignal.value.uid} Session completed`);
      await sessionRef.update({ sessions: updatedSessions });
    }

    console.log('Are sessions completed', checkIfAllSessionsAreCompleted());
    if (checkIfAllSessionsAreCompleted()) {
      console.log('All sessions are completed');
      setProgramToCompleted();
    }
  } catch (error) {
    console.log('Error updating session', error);
  }
};

export const getSidebarValues = ({ screenId, sessionId }) => {
  let view;
  const changeDate = new Date('2023-11-13');
  const whenDocWasCreated = programSignal.value._createdAt.toDate();

  if (whenDocWasCreated < changeDate) {
    const sessionObj = programSignal.value.sessions?.find(({ uid }) => uid === sessionId);
    const screenObj = sessionObj?.screens?.find(({ uid }) => uid === screenId);
    const prompts = screenObj?.prompts;
    if (prompts) {
      view = switchPromptTypeForReview(prompts[0]);
    }
    return view;
  }

  const sessionObj = programPointerSignal.value.sessions?.find(({ uid }) => uid === sessionId);
  const screenObj = sessionObj?.screens?.find(({ uid }) => uid === screenId);
  const prompts = screenObj?.prompts;
  if (prompts) {
    view = switchPromptTypeForReview(prompts[0]);
  }
  return view;
};

const switchPromptTypeForReview = (prompts) => {
  const promptsArr = [];
  let promptsView;
  let tabs;
  const values = [];
  if (!prompts.value || prompts.value === '' || prompts.value === null || prompts.value === undefined) {
    return <p className="fs-5 mx-24">No Previous Response</p>;
  }
  switch (prompts?.type) {
    case 'repeaterTextInputTable':
      Object.keys(prompts?.value).forEach((key, index) => {
        if (key === 'rows') {
          return;
        }
        promptsArr.push(`${index + 1}. ${prompts?.value[key]}`);
      });
      promptsView = promptsArr.map((promptValues) => <p className="fs-5 mx-24">{promptValues}</p>);
      break;
    case 'targetTableBuyerCriteria':
      tabs = prompts?.value?.tabs;
      tabs.forEach((tabPrompt) => {
        tabPrompt.forEach((tab) => {
          promptsArr.push(`${tab.label}: ${tab.value}`);
        });
      });
      promptsView = promptsArr.map((promptValues, idx) => <p key={idx} className="fs-5 mx-24">{promptValues}</p>);
      break;
    case 'inputGroupsRepeaterWithTabsInput2':
      const view = [];
      for (const [variation, nestedObject] of Object.entries(prompts?.value)) {
        const elements = [];

        for (const [key, value] of Object.entries(nestedObject)) {
          elements.push(
            <div key={key}>
              <p className="fs-5 mx-24">{`${value}`}</p>
            </div>,
          );
        }

        view.push(
          <div key={variation}>
            <p className="fs-5 mx-24 mt-40 fw-semibold italic">{`Variations ${variation.split('-')[1]}`}</p>
            {elements}
          </div>,

        );
      }
      promptsView = view;
      break;
    case 'textArea':
      promptsView = <p className="fs-5 mx-24">{prompts?.value}</p>;
      break;
    case 'topTagline':
      const taglineView = [];
      for (const [key, value] of Object.entries(prompts?.value)) {
        taglineView.push(
          <div>
            <p key={key} className="fs-5 mx-24">{value}</p>
          </div>,
        );
      }
      promptsView = taglineView;
      break;
    default:
      promptsView = <p className="fs-5 mx-24">Prompt type not accessible</p>;
  }
  return (
    <div>
      <h5 className="mb-24 mt-32 fw-bold">{prompts?.prompt}</h5>
      {promptsView}
    </div>
  );
};

const checkIfAllSessionsAreCompleted = () => {
  const { sessions } = programSignal.value;
  const completedSessions = sessions?.filter(session => session.completed === true);
  return completedSessions?.length === sessions?.length;
};

const setProgramToCompleted = async () => {
  const programRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);
  const programData = await programRef.get();
  if (!programData) return;
  if (programData.data().completedProgram) return;
  await programRef.update({ ...programData.data(), completedProgram: true });
};

export const saveSideBarNotes = ({ sessionId, notes }) => {
  const sessionRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);

  firestore.runTransaction(async (transaction) => {
    const doc = await transaction.get(sessionRef);
    if (!doc.exists) {
      console.log('Document does not exist');
    }
    const { sessions } = doc.data();
    const sessionIdxToUpdate = sessions.findIndex(session => session.uid === sessionId);
    if (sessions && sessions[sessionIdxToUpdate] && sessionIdxToUpdate !== -1) {
      sessions[sessionIdxToUpdate].notes = notes;
      transaction.update(sessionRef, { sessions });
      notesSignal.update({
        notes,
      });
    } else {
      console.log('Session not found!');
    }
  });
};

export const getNotesfromSessionScreen = ({ screenId, sessionId }) => {
  const sessionRef = firestore.collection(COLLECTIONS.programs).doc(programSignal.value._id);
  firestore.runTransaction(async (transaction) => {
    const doc = await transaction.get(sessionRef);
    if (!doc.exists) {
      console.log('Document does not exist');
    }
    const { sessions } = doc.data();
    const sessionIdxToUpdate = sessions.findIndex(session => session.uid === sessionId);
    if (sessions && sessions[sessionIdxToUpdate] && sessionIdxToUpdate !== -1) {
      notesSignal.update({
        notes: sessions[sessionIdxToUpdate].notes,
      });
    }
  });
};

export default progressBarStyle;
