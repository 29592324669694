import { useState } from 'react';
import { Card, Button, FormControl, Form } from 'react-bootstrap';
import { sendReferralEmail } from '../Dashboard.helpers';

const ReferAFriend = ({ modal }) => {
  const [emailInput, setEmailInput] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailInputChange = (e) => {
    const { value } = e.target;
    setEmailInput(value);
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailPattern.test(value);
    setIsValidEmail(isValid);
  };

  const handleSubmit = () => {
    sendReferralEmail(emailInput);

    setEmailInput('');
  };

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 p-0 font-josefin mt-lg-40 mt-0"
      style={{
        minHeight: '25.70rem',
        zIndex: modal === 'ScheduleModal' ? 2000 : 0,
      }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16 mb-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">REFER A FRIEND</h4>
      </Card.Header>
      <Card.Body className="text-wrap px-32 pt-24">
        <p className="base mb-40 fs-6">
          Refer a friend and gift them a discount
        </p>
        <FormControl
          type="email"
          name="email"
          value={emailInput}
          placeholder="Enter email here and submit"
          className="text-primary border border-secondary border-2 mt-8"
          onChange={handleEmailInputChange}
          isInvalid={!isValidEmail}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email.
        </Form.Control.Feedback>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleSubmit}
          disabled={!isValidEmail}
        >
          Submit
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ReferAFriend;
