import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import userSignal from 'signals/User.Signal';
import businessTypes from 'components/views/OnBoarding/utils/businessTypes';

export const findBusinessTypeName = ({ value }) => {
  const result = businessTypes.find((type) => type.value === value);
  return result?.label;
};

const AgencyClients = ({ modal }) => {
  const history = useHistory();

  const { userData } = userSignal.value;
  const currentOrgId = window.localStorage.getItem('organizationId');

  const handleViewAllVideosClick = () => {
    history.push('/agency/clients');
  };

  const orgsToShow = userData.orgData
    .filter((org) => org.id !== Number(currentOrgId))
    .slice(0, 5);

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 mb-32 font-josefin"
      style={{
        zIndex: modal === 'ExploreModal' ? 2000 : 0,
        minHeight: '600px',
      }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">CLIENTS</h4>
      </Card.Header>
      <Card.Body className="text-wrap px-0 py-0">
        {orgsToShow.length > 0 ? (
          orgsToShow?.map((org, idx) => (
            <Row key={idx} className="py-32 m-0 ps-32 pe-0">
              <Col xs={8} className="m-0 p-0">
                <p className="lead-400 fs-5 mb-8">{org.name}</p>
                <Button
                  className="d-flex align-items-center bg-transparent border-0 text-primary p-0"
                  onClick={() => history.push('/agency/clients')}
                >
                  <p className="base fs-6 mb-0 pe-8 text-secondary">
                    Learn More
                  </p>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </Col>
            </Row>
          ))
        ) : (
          <Row className="py-32 m-0 ps-32 pe-0">
            <Col xs={8} className="m-0 p-0">
              <p className="lead-400 fs-5 mb-8">
                Currently, you don&apos;t have clients in your Agency
              </p>
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleViewAllVideosClick}
        >
          View All Clients
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default AgencyClients;
