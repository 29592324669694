import documentsSignal from 'signals/Documents.signal';
import createSessionPdf from 'components/global/SessionComponents/PdfViewer/PDFBuildUtility';
import alertSignal from 'signals/Alert.signal';
import userSignal from 'signals/User.Signal';

export const downloadDocument = (sessionPrompt) => {
  if (!sessionPrompt) {
    alertSignal.update({
      type: 'alert',
      message: 'Unable to download document',
    });
    return;
  }
  const { value, type, prompt } = sessionPrompt;
  let pdf;
  switch (type.toLowerCase()) {
    case 'differentiator':
      pdf = createSessionPdf(value);
      pdf.downloadDifferentiatorStatementsPdf();
      break;
    case 'toptargetaudience':
      pdf = createSessionPdf(value);
      pdf.downloadTopTargetAudiencePdf();
      break;
    case 'fivewhys':
      pdf = createSessionPdf(value);
      pdf.downloadFiveWhysPdf();
      break;
    case 'tablemultiselect':
      pdf = createSessionPdf(value);
      pdf.downloadBrandArchetypePdf();
      break;
    case 'textarea':
      pdf = createSessionPdf({ title: prompt, value });
      pdf.downloadTextAreaPdf();
      break;
    case 'personalitychart':
      pdf = createSessionPdf(value);
      pdf.downloadPersonalityChartPdf();
      break;
    case 'targettablebuyercriteria':
      pdf = createSessionPdf(value);
      pdf.downloadBuyerCriteriaPdf();
      break;
    default:
      pdf = createSessionPdf(value);
      pdf.downloadTextAreaPdf();
  }
};

export const downloadCombinedDocument = (sessionPrompts, name) => {
  if (!sessionPrompts) {
    alertSignal.update({
      type: 'alert',
      message: 'Unable to download document',
    });
    return;
  }
  const pdf = createSessionPdf(sessionPrompts, name);
  pdf.downloadPartOnePdf();

  window.dataLayer.push({
    event: 'playbookDownloaded',
    eventSettingsTable: [
      { parameter: 'eventCategory', parameterValue: 'Playbook Downloaded' },
      {
        parameter: 'eventAction',
        parameterValue: `Playbook Downloaded: ${name}`,
      },
      { parameter: 'eventLabel', parameterValue: userSignal.value?.userData?.email },
    ],
  });
};

export const downloadAllDocuments = (sessionPrompts, sessionId) => {
  if (!sessionPrompts) {
    alertSignal.update({
      type: 'alert',
      message: 'Unable to download document. Session may not have any unique prompts.',
    });
    return;
  }
  const filterSessions = sessionPrompts.filter((session) => session.sessionId === sessionId);
  if (!filterSessions.length) {
    alertSignal.update({
      type: 'alert',
      message: 'Unable to download document. Session may not have any unique prompts.',
    });
    return;
  }
  const pdf = createSessionPdf(filterSessions);
  pdf.downloadPartOnePdf();

  window.dataLayer.push({
    event: 'playbookDownloaded',
    eventSettingsTable: [
      { parameter: 'eventCategory', parameterValue: 'Playbook Downloaded' },
      {
        parameter: 'eventAction',
        parameterValue: `Playbook Downloaded: ${sessionId}`,
      },
      { parameter: 'eventLabel', parameterValue: userSignal.value?.userData?.email },
    ],
  });
};

export const getPDFValuesFromProgramSessions = (sessions) => {
  const pdfValues = [];
  sessions?.forEach((session) => {
    session?.screens?.forEach((screen) => {
      if (screen?.style === 'prompt') {
        pdfValues.push(...screen?.prompts);
      }
    });
  });
  const values = pdfValues.filter(
    (prompt) => prompt.type !== 'brandArchitecture' && prompt?.value,
  );
  return values;
};

export default documentsSignal;
