/* eslint-disable import/order */
import { InputGroupContainer } from 'components/global/InputGroups/components/inputContainer';
import React, { useEffect, useState } from 'react';
import { FormControl, Row, Col } from 'react-bootstrap';
import {
  getPromptValueFromProgramSignal,
  handlePromptChange,
} from 'components/views/Sessions/Session.helpers';

const numOfForms = new Array(5).fill(0);

const TopTaglineChart = ({ prompt }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (!savedValues) {
      return;
    }
    if (savedValues) {
      setValues(savedValues);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const tempValues = {
      ...values,
      [name]: value,
    };
    setValues(tempValues);
    handlePromptChange({ value: tempValues, prompt });
  };

  return (
    <div className="border border-dark rounded-0 mb-24 mt-16">
      <InputGroupContainer>
        {numOfForms.map((form, idx) => (
          <Row key={idx}>
            <Col className="col-1">
              <p className="text-center">{idx + 1}.</p>
            </Col>
            <Col className="col-11">
              <FormControl
                key={idx}
                className="border rounded-0 mb-16"
                rows={3}
                name={`${idx}`}
                defaultValue={values[`${idx}`]}
                placeholder="Begin Typing..."
                onBlur={(e) => {
                  handleChange(e);
                }}
              />
            </Col>
          </Row>
        ))}
      </InputGroupContainer>
    </div>
  );
};

export default TopTaglineChart;
