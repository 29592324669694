/* eslint-disable import/no-cycle */
import MultiColumnSignal from 'signals/MultiColumnSignal';
import { handlePromptChange } from '../Sessions/Session.helpers';

export const handleMultiColumnTableInputs = (e, prompt, defaultValues) => {
  const { name, value } = e.target;
  const wordIndex = name.split('_')[1];
  const inputName = name.split('_')[0];
  if (inputName === 'words') {
    const previousWords = defaultValues.words;
    previousWords[wordIndex] = value;
    MultiColumnSignal.value = {
      ...defaultValues,
      words: previousWords,
    };
  } else {
    const previousSentences = defaultValues.sentences;
    previousSentences[wordIndex] = value;
    MultiColumnSignal.value = {
      ...defaultValues,
      sentences: previousSentences,
    };
  }
  handlePromptChange({ value: MultiColumnSignal.value, prompt });
};

export const handleMultiColumnTableSelect = (e) => {
  const { name, value } = e.target;
  MultiColumnSignal.value = {
    ...MultiColumnSignal.value,
    [name]: value,
  };
};
