/* eslint-disable array-callback-return */
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import TopBar from 'components/global/TopBar';
import { dashboardDataSignal } from '../Dashboard.helpers';

const Videos = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const videoNumber = queryParams.get('video');

  const handleBackToDashboardClick = () => {
    history.push('/dashboard');
  };

  const { quickStartVideos } = dashboardDataSignal.value;

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBar />
      <div
        className="position-relative w-100 px-lg-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header
            className="h3 bg-secondary text-primary text-center text-uppercase rounded-0 py-32"
            as="h3"
          >
            Quick Start Videos
          </Card.Header>
          <Card.Body className="px-xl-80 px-md-64 pt-xl-80 pt-md-64">
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-lg-0 px-16 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  {quickStartVideos?.length > 0 ? (
                    quickStartVideos?.map((video, idx) => {
                      if (!videoNumber) {
                        return (
                          <Col
                            lg={6}
                            md={6}
                            xs={12}
                            className="my-40 px-xl-32 px-lg-24 px-16"
                            key={video.id}
                          >
                            <Card className="rounded-0 border-0">
                              <div className="position-relative d-inline-block">
                                <ContentVideo content={video} />
                              </div>
                              <Card.Body className="px-8">
                                <Card.Title className="lead mb-5 text-center">
                                  {video.documentName}
                                </Card.Title>
                                <Card.Text className="base text-secondary">
                                  {video.notes}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      }
                      if (videoNumber && Number(videoNumber) === idx) {
                        return (
                          <Col lg={12} md={6} xs={12} key={video.id}>
                            <Card className="rounded-0 border-0">
                              <ContentVideo videoNumber content={video} />
                              <Card.Body className="px-8">
                                <Card.Title className="lead mb-5">
                                  {video.documentName}
                                </Card.Title>
                                <Card.Text className="base text-secondary">
                                  {video.notes}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      }
                    })
                  ) : (
                    <p>Currently we don&apos;t have videos in this section</p>
                  )}
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center border-0 bg-white pb-64">
            <Button
              className="btn-outline-secondary py-16"
              onClick={handleBackToDashboardClick}
            >
              Back to Dashboard
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

const ContentVideo = ({ content, videoNumber }) => {
  if (!content.link) {
    return <div className="bg-light p-32"> Missing Video Link</div>;
  }
  return (
    <iframe
      className="w-100"
      height={videoNumber ? '500px' : '200px'}
      width="100%"
      src={`${content.link?.trim()}?enablejsapi=1`}
      title={content.documentName}
      frameBorder="0"
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      allowFullScreen
    />
  );
};

export default Videos;
