import { useState } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import loginSignal from 'signals/Login.signal';
import { handleSubmit } from './Login.helpers';
import Field from '../../global/Field/Field';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLoginFormChange = (e) => {
    if (e.name === 'email') {
      setEmail(e.value);
    } else {
      setPassword(e.value);
    }
  };

  const handleSignInClick = async () => {
    loginSignal.update({
      email,
      password,
    });
    const submit = await handleSubmit();

    if (submit) {
      window.dataLayer.push({
        event: 'userLogin',
        eventSettingsTable: [
          { parameter: 'eventCategory', parameterValue: 'User Login' },
          {
            parameter: 'eventAction',
            parameterValue: `Login: ${email}`,
          },
          { parameter: 'eventLabel', parameterValue: email },
        ],
      });

      history.push('/dashboard');
    }
  };

  return (
    <div
      className="position-relative w-100"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
      }}
    >
      <Row
        className="p-0 m-0 w-100 position-absolute top-50 start-50"
        style={{ transform: 'translate(-50%,-50%)' }}
      >
        <Col xs={0} md={1} lg={3} />
        <Col xs={12} md={10} lg={6} className="pe-lg-8 px-0">
          <div className="px-xl-72">
            <Card className="bg-light w-100 rounded-0 mt-md-56 px-xl-80 px-lg-64 px-md-40 px-24 py-xl-80 py-lg-48 py-40">
              <Card.Body>
                <div className="custom-image-padding">
                  <Card.Img variant="top" src="/images/bt-main.png" />
                </div>
                <div className="lead text-center pt-72 mb-16">Login</div>
                <Field
                  type="text-input"
                  name="email"
                  value={email}
                  label="Email Address"
                  className="pt-lg-56 pt-32 text-primary"
                  labelClassName="fw-bold mb-lg-8 mb-0"
                  inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                  onChange={handleLoginFormChange}
                />
                <Field
                  type="password-input"
                  value={password}
                  name="password"
                  label="Password"
                  className="pt-lg-32 pt-24 text-primary"
                  labelClassName="fw-bold mb-lg-8 mb-0"
                  inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                  onChange={handleLoginFormChange}
                />
                <Button
                  className="btn-outline-secondary mt-72 mb-16 w-100"
                  // type="submit"
                  disabled={!email || !password}
                  onClick={() => handleSignInClick()}
                >
                  Sign in
                </Button>
              </Card.Body>
              <Card.Footer
                className="d-flex justify-content-between"
                style={{ background: 'transparent' }}
              >
                <p>
                  Don&apos;t have an account?&nbsp;
                  <button
                    type="button"
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#B2A05E',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      padding: 0,
                      font: 'inherit',
                    }}
                    onClick={() => history.push('/signup')}
                  >
                    Click Here!
                  </button>
                </p>
                <p>
                  <button
                    type="button"
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#B2A05E',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      padding: 0,
                      font: 'inherit',
                    }}
                    onClick={() => history.push('/forgot-password')}
                  >
                    Forgot Password?
                  </button>
                </p>
              </Card.Footer>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
