/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  handlePromptChange,
  getPromptValueFromProgramSignal,
} from 'components/views/Sessions/Session.helpers';

const dummyData = {
  title: 'brand architecture selection',
  instructions:
    'Please select the brand trifecta that fits best with your company',
  prompt: [
    {
      id: 0,
      columnOneLabel: 'Single Brand Trifecta',
      columnOneText:
        'For an organization needing a single Brand Trifecta that speaks to all of their target audiences, and where all target audiences have the same bottom-line heart pain which the brand solves.',
      imageSrc: '/images/single-brand-trifecta.svg',
    },
    {
      id: 1,
      columnOneLabel: 'Two Brand Trifectas',
      columnOneText:
        'For an organization needing two Brand Trifectas - one for their B2B sales channel and another for their B2C sales channel. Each Brand Trifecta speaks to how the brand solves the bottom-line heart pain for each channel’s respective target audiences.',
      imageSrc: '/images/two-brand-trifecta.svg',
    },
    {
      id: 2,
      columnOneLabel: 'Three Brand Trifectas',
      columnOneText:
        'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top two target audiences. These supporting Brand Trifectas speak to how the brand solves each target audience’s bottom-line heart pain.',
      imageSrc: '/images/three-brand-trifecta.svg',
    },
    {
      id: 3,
      columnOneLabel: 'Four Brand Trifectas',
      columnOneText:
        'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top two target audiences. These supporting Brand Trifectas speak to how the brand solves each target audience’s bottom-line heart pain.',
      imageSrc: '/images/four-brand-trifecta.svg',
    },
    {
      id: 4,
      columnOneLabel: 'Five Brand Trifectas',
      columnOneText:
        'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top two target audiences. These supporting Brand Trifectas speak to how the brand solves each target audience’s bottom-line heart pain.',
      imageSrc: '/images/five-brand-trifecta.svg',
    },
  ],
};

const TableRadioWithImage = ({ prompt }) => {
  const [selectedId, setSelectedId] = useState(0); // dummy state for selecting radio button

  const handleRadioClick = (id) => {
    // dummy function for selecting radio button
    handlePromptChange({
      value: id,
      prompt,
    });
    setSelectedId(id);
  };

  useEffect(() => {
    setSelectedId(getPromptValueFromProgramSignal(prompt));
  }, []);

  return (
    <Container className="border border-secondary border-3 pt-xl-80 py-48 rounded-0 bg-light">
      <Row className="bg-light">
        <Col>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img
              alt="Icon"
              src="/images/decorative-icon-1.svg"
              className="pb-8"
            />
            <div className="h3 text-primary text-uppercase text-center mb-0 mx-56">
              {dummyData.title}
            </div>
            <img
              alt="icon"
              src="/images/decorative-icon-1.svg"
              className="pb-8"
            />
          </div>
          <div
            className="bg-secondary w-25 mx-auto"
            style={{ height: '4px' }}
          />
          <div className="text-dark mb-lg-48 my-24 text-center base">
            {dummyData.instructions}
          </div>
        </Col>
      </Row>
      {dummyData.prompt.map((row, index) => (
        <Row className={index % 2 === 0 ? 'bg-white' : 'bg-light'}>
          <Col xs={5}>
            <div
              style={{ paddingLeft: 80, paddingRight: 16 }}
              className="d-flex h-100 w-100 justify-content-end align-items-center"
            >
              <div className="mt-80 mb-64">
                <p className="lead-600 text-uppercase">{row.columnOneLabel}</p>
                <p className="base">{row.columnOneText}</p>
              </div>
            </div>
          </Col>
          <Col
            xs={6}
            className="border border border-2 border-top-0 border-bottom-0"
          >
            <div className="d-flex h-100 justify-content-center align-items-center">
              <img alt="img" src={row.imageSrc} className="img-fluid" />
            </div>
          </Col>
          <Col>
            <div
              className="d-flex h-100 justify-content-center align-items-center"
              onClick={() => handleRadioClick(row.id)}
              style={{ cursor: 'pointer' }}
            >
              <img
                alt="img"
                src={
                  selectedId === row.id
                    ? '/images/radio-selected.svg'
                    : '/images/radio-unselected.svg'
                }
              />
            </div>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default TableRadioWithImage;
