import { apiFetch } from 'api/api';
import Signal from 'libs/signals';
import alertSignal from 'signals/Alert.signal';
import userSignal from 'signals/User.Signal';

export const clientsSignal = Signal([]);

export const getClientsData = async ({ orgData }) => {
  const token = userSignal.value.fbUser.Aa;
  let response = [];
  try {
    if (orgData.length > 1) {
      const request = await apiFetch({
        path: '/agency/clients',
        method: 'POST',
        token,
        body: orgData,
      });

      response = request?.data;
    }
    clientsSignal.update(response);
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'Could not load the data',
      error,
    });
  }
};
