/* eslint-disable no-confusing-arrow */
import { Route, Redirect } from 'react-router-dom';
import userSignal from 'signals/User.Signal';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn } = userSignal.value;
  const creds = window.localStorage.getItem('creds');
  const isAgency = JSON.parse(creds)?.isAgency;
  return (
    <Route
      {...rest}
      render={(props) =>
        isSignedIn ? (
          <Component isAgency={isAgency} {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
