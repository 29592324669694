import { Button } from 'react-bootstrap';

const Plans = ({ onSubmit }) => (
  <div className="d-flex flex-row justify-content-center mt-64">
    <div className="d-flex flex-column align-items-center justify-content-center border border-secondary border-4 bg-white pt-40 pb-56 px-40 me-16">
      <p className="text-primary-light mb-24">Best Value</p>
      <p className="text-primary fs-3 fw-bolder mb-32">
        6 Monthly Payments of $300
      </p>
      <Button
        className="bg-primary fw-bold text-secondary border-0 rounded-0 py-16 px-32"
        onClick={() => onSubmit('sixMonthlyPayments')}
      >
        Purchase Now
      </Button>
    </div>
    <div className="d-flex flex-column align-items-center justify-content-center border border-secondary border-4 bg-white pt-40 pb-56 px-40 ms-16">
      <p className="text-primary-light mb-24">Best Savings</p>
      <p className="text-primary fs-3 fw-bolder mb-32">
        One Time Purchase of $1800
      </p>
      <Button
        className="bg-primary fw-bold text-secondary border-0 rounded-0 py-16 px-32"
        onClick={() => onSubmit('oneTimePurchase')}
      >
        Purchase Now
      </Button>
    </div>
  </div>
);

export default Plans;
