import { useCallback, useEffect, useState } from 'react';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import TopBarAgency from 'components/views/Dashboard/agency-dashboard/agency-components/TopBarAgency';
import AddClientModal from 'components/views/Dashboard/agency-dashboard/agency-modals/AddClientModal';
import formatMoney from 'utils/formatMoney';
import userSignal from 'signals/User.Signal';
import usePageReloadDetector from 'hooks/usePageReloadDetector';
import {
  agencyPurchasesListSignal,
  getAgencyPurchasesList,
} from './AgencyPurchasesList.Helpers';

const AgencyPurchasesList = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const orgData = userSignal.value?.userData?.orgData;
  const signal = agencyPurchasesListSignal.value;

  const isReloaded = usePageReloadDetector();

  const refetch = useCallback(async () => {
    if (isReloaded) {
      await getAgencyPurchasesList({ orgData });
    }
  }, [isReloaded]);

  useEffect(() => {
    refetch();
  }, [isReloaded]);

  const handleBackToDashboardClick = () => {
    history.push('/dashboard');
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBarAgency />
      <div
        className="position-relative w-100 px-lg-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header
            className="h3 bg-secondary-light text-primary text-center text-uppercase rounded-0 py-32"
            as="h3"
          >
            Purchases
          </Card.Header>
          <Card.Body className="px-xl-80 px-md-64 pt-xl-80 pt-md-64">
            <Row>
              <Col>
                <h5 className="fw-semibold text-uppercase">Purchases</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr
                  style={{ borderTop: 'solid 4px', marginBottom: '20px' }}
                  className="text-secondary-light"
                />
              </Col>
            </Row>
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-lg-0 px-16 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  {orgData && signal.length > 0 ? (
                    <Table
                      bsPrefix="custom-table-header"
                      className="text-center custom-table-styles"
                      bordered
                      hover
                    >
                      <thead>
                        <tr>
                          <th>Client</th>
                          <th>Invoice Date</th>
                          <th>Number</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {signal?.map((data, idx) => (
                          <tr className="row-divider" key={idx}>
                            <td>{data.orgName}</td>
                            <td>
                              <Moment format="YYYY-MM-DD">
                                {data.createdAt}
                              </Moment>
                            </td>
                            <td>{data.webhookId}</td>
                            <td>
                              {formatMoney(
                                data.webhook.webhookData.data.object.amount /
                                  100,
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>
                      Currently, you don&apos;t have purchases to show in your
                      Agency.
                    </p>
                  )}
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center border-0 bg-white pb-64">
            <Button
              className="btn-outline-secondary py-16"
              onClick={handleBackToDashboardClick}
            >
              Back to Dashboard
            </Button>
          </Card.Footer>
        </Card>
        <AddClientModal show={show} setShow={setShow} />
      </div>
    </>
  );
};

export default AgencyPurchasesList;
