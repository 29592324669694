import { useState } from 'react';
import { Modal, Button, Row, Col, Card, Tab, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Field from 'components/global/Field/Field';
import businessTypes from 'components/views/OnBoarding/utils/businessTypes';
import companySize from 'components/views/OnBoarding/utils/companySize';
import { apiFetch } from 'api/api';
import userSignal from 'signals/User.Signal';
import alertSignal from 'signals/Alert.signal';
import { addClientSignal, handleFormChange } from './addClientModal.helpers';

const AddClientModal = ({ show, setShow }) => {
  const [tab, setTab] = useState('OnBoarding');
  const [isChecked, setIsChecked] = useState(false);

  const { fbUser } = userSignal.value;

  const handleSubmit = async () => {
    const signal = addClientSignal.value;
    const body = {
      businessName: signal.businessName,
      businessType: signal.businessType,
      companySize: signal.companySize,
      firstName: signal.clientFirstName,
      lastName: signal.clientLastName,
      email: signal.clientEmailAddress,
      role: 'Client',
      isPrimary: isChecked,
    };

    try {
      const request = await apiFetch({
        path: '/agency/client',
        method: 'POST',
        token: fbUser.Aa,
        body,
      });
      if (request) {
        window.location.reload();
      }
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: 'An error occured when adding the new client',
        error,
      });
    }
  };
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      onHide={() => setShow(false)}
      contentClassName="rounded-0"
      size="lg"
    >
      <Modal.Header className="d-flex flex-column text-center border-0 mb-0 text-center font-josefin text-secondary rounded-0">
        <Button
          variant="white"
          className="bg-transparent align-self-end border-0 text-secondary fw-bold fs-5 pb-0"
          onClick={() => setShow(false)}
        >
          <FontAwesomeIcon icon={faXmark} className="text-secondary" />
        </Button>
        <div className="custom-image-padding">
          <Card.Img variant="top" src="/images/bt-main.png" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={12} lg={12} className="px-0">
            <div className="px-xl-72">
              <Card className="border-0">
                <Card.Body>
                  <Tab.Container activeKey={tab} className="border-0">
                    <Tab.Content className="w-100">
                      <Tab.Pane eventKey="OnBoarding">
                        <div className="text-center text-primary">
                          <p className="mb-0 fs-3">Tell us about this client</p>
                        </div>
                        <Field
                          type="text-input"
                          name="businessName"
                          value={addClientSignal.value.businessName}
                          label="Business Name"
                          className="pt-lg-40 pt-32 text-primary"
                          labelClassName="fw-bold mb-lg-8 mb-0"
                          inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                          onChange={handleFormChange}
                        />
                        <Field
                          type="select"
                          name="businessType"
                          value={addClientSignal.value.businessType}
                          label="What Type of Business Are You"
                          className="pt-32 text-primary"
                          labelClassName="fw-bold"
                          dropDownClassName="border-0 border-bottom border-primary bg-transparent text-primary ps-32"
                          placeholder="Business Type"
                          onChange={handleFormChange}
                          options={businessTypes}
                        />
                        <Field
                          type="select"
                          name="companySize"
                          value={addClientSignal.value.companySize}
                          label="Company Size"
                          className="pt-32 text-primary"
                          labelClassName="fw-bold"
                          dropDownClassName="border-0 border-bottom border-primary bg-transparent text-primary ps-32"
                          placeholder="Number of employees"
                          onChange={handleFormChange}
                          options={companySize}
                        />
                        <div className="w-100 px-32 mt-96">
                          <Button
                            variant="secondary"
                            className="w-100 btn-outline-secondary rounded-0 py-16"
                            disabled={
                              !addClientSignal.value.businessName ||
                              !addClientSignal.value.businessType ||
                              !addClientSignal.value.companySize
                            }
                            onClick={() => setTab('AddUser')}
                          >
                            Next
                          </Button>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="AddUser">
                        <div className="text-center text-primary">
                          <p className="mb-0 fs-3">Add User</p>
                        </div>
                        <Row>
                          <Col lg={6}>
                            <Field
                              type="text-input"
                              name="clientFirstName"
                              value={addClientSignal.value.clientFirstName}
                              label="First Name"
                              className="pt-lg-40 pt-32 text-primary"
                              labelClassName="fw-bold mb-lg-8 mb-0"
                              inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                              onChange={handleFormChange}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text-input"
                              name="clientLastName"
                              value={addClientSignal.value.clientLastName}
                              label="Last Name"
                              className="pt-lg-40 pt-32 text-primary"
                              labelClassName="fw-bold mb-lg-8 mb-0"
                              inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                              onChange={handleFormChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Field
                              type="text-input"
                              name="clientEmailAddress"
                              value={addClientSignal.value.clientEmailAddress}
                              label="Email Address"
                              className="pt-lg-40 pt-32 text-primary"
                              labelClassName="fw-bold mb-lg-8 mb-0"
                              inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                              onChange={handleFormChange}
                            />
                          </Col>
                          <Col lg={6}>
                            <Field
                              type="text-input"
                              name="clientCompanyRole"
                              value="Client"
                              label="Company Role"
                              className="pt-lg-40 pt-32 text-primary"
                              labelClassName="fw-bold mb-lg-8 mb-0"
                              inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                              disabled
                              onChange={null}
                            />
                          </Col>
                        </Row>
                        <Form.Check
                          id="user"
                          name="clientPrimary"
                          type="checkbox"
                          className="d-flex align-items-center ps-0 mt-32 mb-0"
                        >
                          <Form.Check.Input
                            name="clientPrimary"
                            type="checkbox"
                            className="m-0 me-8"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                          />
                          <Form.Check.Label className="mb-0 text-muted">
                            Is this user the Primary Contact of this Company?
                          </Form.Check.Label>
                        </Form.Check>
                        <div className="w-100 px-32 mt-96">
                          <Button
                            variant="secondary"
                            className="w-100 btn-outline-secondary rounded-0 py-16"
                            disabled={
                              !addClientSignal.value.clientFirstName ||
                              !addClientSignal.value.clientLastName ||
                              !addClientSignal.value.clientEmailAddress
                            }
                            onClick={handleSubmit}
                          >
                            Next
                          </Button>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientModal;
