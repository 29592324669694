import React from 'react';

const DashboardFooter = () => (
  <div className="bg-success footer w-100 py-32">
    <div className="flex-row">
      <div className="text-center">
        <img src="/images/bt-main.png" alt="logo" />
      </div>
      <div className=" d-flex justify-content-center">
        <hr className="w-50 border-2" />
      </div>
      <div className="d-flex justify-content-center mb-16 w-100">
        <a className="mx-8 text-info">Terms and Conditions</a>
        <a className="mx-8 text-info">Privacy Policy</a>
      </div>
      <div className="d-flex justify-content-center">
        <p className="text-center text-info mt-24">
          COPYRIGHT © 2023 The Brand Trifecta, LLC
        </p>
      </div>
    </div>
  </div>
);

export default DashboardFooter;
