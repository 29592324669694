/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */

export function secondsToMinutes(seconds) {
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Pad the minutes and seconds with leading zeros, if required
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${seconds}`;
}

export function getNowSeconds() {
  return Math.ceil(new Date().getTime() / 1000);
}
