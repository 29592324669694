import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { handleFormChange } from 'components/views/Settings/Settings.helpers';
import userSignal from 'signals/User.Signal';
import settingsSignal from 'signals/Settings.signal';
import { apiFetch } from 'api/api';
import Field from '../../Field/Field';

const Notifications = () => {
  const [value, setValue] = useState([]);

  const signal = userSignal.value.userData.notificationSettings;
  const settings = settingsSignal.value.notificationSettings;

  const valueArray = useCallback(() => {
    const array = [];
    Object.keys(signal).forEach((key) => {
      const arrayValue = signal[key];
      if (key !== 'id' && key !== 'userId') {
        if (arrayValue) {
          settingsSignal.update({
            notificationSettings: key,
          });
          array.push(key);
        }
      }
    });
    return array;
  });

  useEffect(() => {
    const array = valueArray();
    setValue(array);
  }, []);

  const handleRequest = async () => {
    const body = {};
    const token = userSignal.value.fbUser.Aa;
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      if (settings.includes(element)) {
        body[element] = true;
      } else {
        body[element] = false;
      }
    }

    try {
      const request = await apiFetch({ path: '/notification-settings', body, method: 'PUT', token });

      if (request) {
        const userData = await apiFetch({ path: '/users', token });

        userSignal.update({
          userData,
        });
      }
    } catch (error) {
      console.log('[DEBUG] Error', error);
    }
  };

  return (
    <div className="h-100">
      <div className="lead mb-16">
        Company Notifications
      </div>
      <div
        className="border border-secondary p-40"
      >
        <Field
          type="checkbox-group"
          name="notificationSettings"
          value={value}
          label="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          className="text-primary"
          labelClassName="mb-lg-32 mb-0 text-dark"
          inputClassName="ps-64 custom-checkbox mb-16"
          options={[
            { label: 'Session Alerts', value: 'sessionAlerts' },
            { label: 'Team Member Added', value: 'teamMemberAdded' },
            { label: 'Document Updates', value: 'documentUpdates' },
          ]}
          onChange={handleFormChange}
        />
      </div>
      <div className="w-100 mt-xxxl-56 mt-32">
        <Button variant="secondary" className="w-100 bg-primary text-secondary rounded-0 py-xxxl-16 py-8" onClick={handleRequest}>Save Changes</Button>
      </div>
    </div>
  );
};

export default Notifications;
