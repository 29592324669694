import React, { useEffect, useState } from 'react';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';
import { ResultsTable, ResultsSingleTable } from './components/results';

const defaultResults = new Array(8).fill('N/A');
const FiveWhysResults = ({ prompt }) => {
  const [results, setResults] = useState(defaultResults);
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    if (!values) return;
    if (values?.tabs) {
      setTabs(values?.tabs);
      delete values.tabs;
      setResults(values);
    } else {
      setResults(values);
    }
  }, []);
  return (
    <div className="py-24">
      {tabs.length > 0 ? (
        Object.keys(results)?.map((result, index) => (
          <ResultsTable key={index} results={results[result]} />
        ))
      ) : (<ResultsSingleTable results={results} />)}

    </div>
  );
};

export default FiveWhysResults;
