/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const PackagesWelcomeModal = ({ show, setShow }) => {
  const { isAgency, userType } = JSON.parse(
    window.localStorage.getItem('creds'),
  );

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      onHide={() => setShow(false)}
      className="rounded-0 d-flex justify-content-center"
      contentClassName="rounded-0"
      size="lg"
      style={{ overflow: 'visible' }}
    >
      <Modal.Header className="d-flex flex-column text-center border-0 mb-0 text-center font-josefin bg-primary text-secondary pb-16 pt-8 rounded-0">
        <Button
          variant="white"
          className="bg-transparent align-self-end border-0 text-secondary fw-bold fs-5 pb-0"
          onClick={() => setShow(false)}
        >
          <FontAwesomeIcon icon={faXmark} className="text-secondary" />
        </Button>
      </Modal.Header>
      <Modal.Body className="position-relative bg-primary rounded-0 px-72 pt-0 pb-72">
        <p className="lead text-center text-white mb-0 fs-4">
          {isAgency
            ? 'Please Visit Client Page'
            : userType === 'Client'
            ? ''
            : 'Please Wait to Purchase'}
        </p>
        {userType === 'Client' ? null : (
          <hr
            className="text-secondary border-secondary mt-8 mx-auto"
            style={{ width: '50%', borderWidth: '3px', opacity: '1' }}
          />
        )}
        <p className="base text-center text-white mt-8 mb-32 px-48">
          {isAgency
            ? "In order to purchase Parts One and Two of The Brand Trifecta, you must first select a client. Please visit your client's dashboard to continue with a purchase. If you need assistance please contact help@brandtrifecta.com."
            : userType === 'Client'
            ? 'Please contact your agency to purchase Parts One and Two of The Brand Trifecta.'
            : 'It is recommended that you wait to purchase Part Two of the program until after you have completed Part One. Why? Because Part One will ensure you determine precisely how many Brand Trifectas you need to write in order to effectively reach and engage your target audiences.'}
        </p>
        <div className="d-flex justify-content-center w-100">
          <Button
            className="d-flex align-items-center btn bg-success border-success text-primary rounded-0 px-40 py-8"
            onClick={() => setShow(false)}
          >
            <p className="mb-0 pe-4">
              {isAgency ? 'Please Select Your Client from Nav Bar' : 'Continue'}
            </p>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PackagesWelcomeModal;
