import { Button, Row, Col, Card, Tab } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import userSignal from 'signals/User.Signal';
import loaderSignal from 'signals/Loader.signal';
import TextInput from 'components/global/Field/FieldComponents/TextInput';
import Field from '../../global/Field/Field';
import { handleOnBoardingSubmit } from './OnBoarding.helpers';
import businessTypes from './utils/businessTypes';
import companySize from './utils/companySize';

const OnBoarding = () => {
  const creds = JSON.parse(window.localStorage.getItem('creds'));

  if (creds?.isAgency === undefined) {
    loaderSignal.update({
      isContentLoading: true,
    });
  } else {
    loaderSignal.reset();
  }

  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState(
    creds?.isAgency ? 'agency' : '',
  );
  const [businessSize, setBusinessSize] = useState('');

  const history = useHistory();
  const { fbUser, userData } = userSignal.value;

  const handleSubmit = async () => {
    const response = await handleOnBoardingSubmit(
      businessName,
      businessType,
      businessSize,
      fbUser.Aa,
    );
    if (response.message) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (userData.User_Organization.length > 0) {
      history.push('/dashboard');
    }
  }, []);

  return (
    <div
      className="position-relative w-100"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
      }}
    >
      <Row className="p-0 m-0 w-100">
        <Col xs={0} md={1} lg={3} />
        <Col xs={12} md={10} lg={6} className="pe-lg-8 px-0">
          <div className="px-xl-72">
            <Card className="bg-light w-100 rounded-0 mt-md-56 px-xl-80 px-lg-64 px-md-40 px-24 py-xl-80 py-lg-48 py-40">
              <Card.Body>
                <div className="custom-image-padding">
                  <Card.Img variant="top" src="/images/bt-main.png" />
                </div>
                <Tab.Container activeKey="OnBoarding" className="border-0">
                  <Tab.Content className="w-100">
                    <Tab.Pane eventKey="OnBoarding">
                      <div className="pt-lg-64 pt-48 text-center text-primary">
                        <p className="mb-0 fs-3">Let&apos;s get to know you</p>
                      </div>
                      <Field
                        type="text-input"
                        name="businessName"
                        value={businessName}
                        label="Business Name"
                        className="pt-lg-40 pt-32 text-primary"
                        labelClassName="fw-bold mb-lg-8 mb-0"
                        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                        onChange={(e) => setBusinessName(e.value)}
                      />
                      {creds?.isAgency ? (
                        <TextInput
                          name="businessType"
                          value="Agency"
                          label="What Type of Business Are You"
                          className="pt-32 text-primary"
                          labelClassName="fw-bold"
                          inputClassName="border-0 border-bottom border-primary bg-transparent text-primary ps-32"
                        />
                      ) : (
                        <Field
                          type="select"
                          name="businessType"
                          value={businessType}
                          label="What Type of Business Are You"
                          className="pt-32 text-primary"
                          labelClassName="fw-bold"
                          dropDownClassName="border-0 border-bottom border-primary bg-transparent text-primary ps-32"
                          placeholder="Business Type"
                          onChange={(e) => setBusinessType(e.value)}
                          options={businessTypes}
                        />
                      )}
                      <Field
                        type="select"
                        name="companySize"
                        value={businessSize}
                        label="Company Size"
                        className="pt-32 text-primary"
                        labelClassName="fw-bold"
                        dropDownClassName="border-0 border-bottom border-primary bg-transparent text-primary ps-32"
                        placeholder="Number of employees"
                        onChange={(e) => setBusinessSize(e.value)}
                        options={companySize}
                      />
                      <div className="w-100 px-32 mt-96">
                        <Button
                          variant="secondary"
                          className="w-100 btn-outline-secondary rounded-0 py-16"
                          disabled={
                            !businessName || !businessType || !businessSize
                          }
                          onClick={handleSubmit}
                        >
                          Next
                        </Button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OnBoarding;
