/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useCallback, useEffect, useState } from 'react';
import TopBar from 'components/global/TopBar';
import userSignal from 'signals/User.Signal';
import fetchDataforSessionsList from './EditorSessionsList.helpers';

const EditorSessionsList = () => {
  const [sessions, setSessions] = useState([]);
  const { programId } = useParams();
  const history = useHistory();

  const { fbUser } = userSignal.value;

  const init = useCallback(async (uid, token) => {
    await Promise.all([fetchDataforSessionsList(uid, token)]).then(
      (response) => {
        setSessions(response[0].sessions);
      },
    );
  });

  useEffect(() => {
    init(programId, fbUser.Aa);
  }, [programId, fbUser]);

  const handleGoToSession = (session) => {
    history.push(`/editor/${programId}/${session.uid}/screen/1`);
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBar />
      <div
        className="position-relative w-100 px-8 pb-64 pt-80"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Container>
          <Link className="text-secondary base back-button" to="/dashboard">
            <FontAwesomeIcon style={{ marginRight: 16 }} icon={faArrowLeft} />{' '}
            Back to Dashboard
          </Link>
          <Card className="border-0 rounded-0 mt-56">
            <Card.Header className="bg-secondary border-0 rounded-0 text-center py-32 text-primary h3 text-uppercase">
              Sessions
            </Card.Header>
            <Card.Body className="px-64 px-xl-104 py-64">
              <h4 className="text-uppercase text-primary">Session Progress</h4>
              <Row className="mt-16">
                <Col lg={9}>
                  <p className="base text-info">
                    This page provides a comprehensive overview of all scheduled
                    sessions.
                  </p>
                </Col>
              </Row>
              <Row className="mt-72 mb-8 px-40">
                <Col lg={6}>
                  <p className="text-primary base-bold">Session</p>
                </Col>
                <Col lg={6}>
                  <p className="text-primary base-bold">Got to Session</p>
                </Col>
              </Row>
              {sessions
                ? sessions.map((session, index) => (
                    <Row
                      key={session.id}
                      className={`bg-light py-32 px-24 px-xl-40 align-items-center${
                        index > 0 ? ' mt-32' : ''
                      }`}
                    >
                      <Col lg={6}>
                        <p className="text-primary base-bold mb-4">Session</p>
                        <p className="text-primary base mb-0">
                          {session.title}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <Button
                          className="text-primary small mb-0 bg-light border-0 px-0 py-8"
                          onClick={() => handleGoToSession(session)}
                        >
                          Go to session
                        </Button>
                      </Col>
                    </Row>
                  ))
                : null}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default EditorSessionsList;
