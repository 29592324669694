import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TopBar from 'components/global/TopBar';
import userSignal from 'signals/User.Signal';
import getAllPrograms from './PackageList.Helpers';

const PackagesList = () => {
  const [programs, setPrograms] = useState([]);

  const history = useHistory();

  const { fbUser } = userSignal.value;

  const init = useCallback(async (token) => {
    await Promise.all([
      getAllPrograms(token),
    ]).then((data) => {
      setPrograms(data[0]);
    });
  });

  useEffect(() => {
    init(fbUser.Aa);
  }, []);

  return (

    <>
      <div className="bg-primary w-100 py-24" />
      <TopBar />
      <div
        className="position-relative w-100 px-8 pb-64 pt-80"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Container>
          <Link className="text-secondary base back-button" to="/dashboard">
            <FontAwesomeIcon style={{ marginRight: 16 }} icon={faArrowLeft} /> Back to dashboard
          </Link>
          <Card className="border-0 rounded-0 mt-56">
            <Card.Header className="bg-secondary border-0 rounded-0 text-center py-32 text-primary h3 text-uppercase">Programs</Card.Header>
            <Card.Body className="px-64 px-xl-104 py-64">
              <h4 className="text-uppercase text-primary">Programs in editor view</h4>
              <Row className="mt-16">
                <Col lg={9}>
                  <p className="base text-info">This page provides all the programs available and the oportunity to go over all the sessions and screens in editor mode.</p>
                </Col>
              </Row>
              <Row className="mt-72 mb-8 px-40">
                <Col lg={4}>
                  <p className="text-primary base-bold">Program</p>
                </Col>
                <Col lg={4}>
                  <p className="text-primary base-bold">Number of Sessions</p>
                </Col>
                <Col lg={4}>
                  <p className="text-primary base-bold">Go to Session</p>
                </Col>
              </Row>
              {programs.map((program, index) => (
                <Row key={program.name} className={`bg-light py-32 px-24 px-xl-40 align-items-center${index > 0 ? ' mt-32' : ''}`}>
                  <Col lg={4}>
                    <p className="text-primary base-bold mb-4">Program</p>
                    <p className="text-primary base mb-0">{program.name}</p>
                  </Col>
                  <Col lg={4}>
                    <p className="text-info base mb-0">{program.sessions.length}</p>
                  </Col>
                  <Col lg={4}>
                    <Button
                      className="text-primary small mb-0 bg-light border-0 px-0 py-8"
                      onClick={() => history.push(`/editor/sessions/${program.uid}`)}
                    >
                      View Sessions
                    </Button>
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default PackagesList;
