import { Container } from 'react-bootstrap';

const SessionContainer = ({ children, sessionContent }) => (
  <Container
    className={'"mb-40"'}
    style={{
      maxWidth: !sessionContent ? '950px' : '75%',
      minHeight: '100%',
    }}
  >
    {children}
  </Container>
);

export default SessionContainer;
