import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import defaultData from './data';

const TableMultiSelectResultsTable = ({ inputData = defaultData.slice(1, 4) }) => {
  const [filteredData, setFilteredData] = React.useState([]);
  useEffect(() => {
    // filter indexes from defaultData that are in inputData
    setFilteredData(defaultData.filter((item, index) => inputData.includes(index.toString())));
  }, [inputData]);
  return (
    <Table size="xl" bordered responsive className="border border-2 border-black overflow-auto">
      <thead>
        <tr>
          <th className="fs-5 bg-transparent text-center">Archetypes</th>
          <th className="fs-5 bg-transparent text-center ">Drivers</th>
          <th className="fs-5 bg-transparent text-center ">Values</th>
          <th className="fs-5 bg-transparent text-center  ">Goals</th>
          <th className="fs-5 bg-transparent  text-center ">Fears</th>
          <th className="fs-5 bg-transparent text-center">Order</th>
        </tr>
      </thead>
      <tbody className="">
        {
                filteredData.map((item, index) => (
                  <tr key={index} className="text-center content-center border border-black border-2">
                    <div style={{ verticalAlign: 'middle', display: 'table-cell', backgroundColor: 'transparent' }}>
                      <td style={{ borderBottom: 'none' }} className="bg-transparent d-flex justify-content-center align-self-center">
                        {item.title}
                      </td>
                    </div>
                    <td className="bg-transparent border border-black border-2 ">
                      {item.lines[0]}
                    </td>
                    <td style={{ borderBottom: 'none' }} className="  bg-transparent">
                      {item.lines[1]}
                    </td>
                    <td style={{ borderBottom: 'none' }} className=" bg-transparent ">
                      {item.lines[2]}
                    </td>

                    <td style={{ borderBottom: 'none' }} className=" bg-transparent ">
                      {item.lines[3]}
                    </td>
                    <td style={{ borderBottom: 'none' }} className="bg-transparent ">
                      {index + 1}
                    </td>
                  </tr>
                ))
}

      </tbody>
    </Table>
  );
};

export default TableMultiSelectResultsTable;
