import { useEffect, useState } from 'react';
import { Card, Row, Col, Tab } from 'react-bootstrap';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import jwt from 'jwt-decode';
import signupSignal from 'signals/Signup.signal';
import alertSignal from 'signals/Alert.signal';
import PersonalInfo from './components/PersonalInfo';
import CreatePassword from './components/CreatePassword';

const Signup = (props) => {
  const [tab, setTab] = useState('');
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      const decodeToken = jwt(token);
      const currentDate = new Date();

      if (decodeToken.exp * 1000 < currentDate.getTime()) {
        alertSignal.update({
          type: 'alert',
          message: 'Token is not valid anymor, please request another one',
        });
      } else {
        signupSignal.update({
          firstName: decodeToken.firstName,
          lastName: decodeToken.lastName,
          email: decodeToken.email,
          role: decodeToken.roleId,
          organizationId: decodeToken.organizationId,
          isInvitation: true,
        });
        setTab('CreatePassword');
      }
    } else {
      setTab('PersonalInfo');
    }
  }, []);

  return (
    <div
      className="position-relative w-100"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
      }}
    >
      <Row className="p-0 m-0 w-100">
        <Col xs={0} md={1} lg={3} />
        <Col xs={12} md={10} lg={6} className="pe-lg-8 px-0">
          <div className="px-xl-72 pb-40">
            <Card className="bg-light w-100 rounded-0 mt-md-56 px-xl-80 px-lg-64 px-md-40 px-24 py-xl-80 py-lg-48 py-40">
              <Card.Body>
                <div className="custom-image-padding">
                  <Card.Img variant="top" src="/images/bt-main.png" />
                </div>
                <Tab.Container activeKey={tab} className="border-0">
                  <Tab.Content className="w-100">
                    <Tab.Pane eventKey="PersonalInfo">
                      <PersonalInfo onSubmit={setTab} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="CreatePassword">
                      <CreatePassword
                        isAgency={props?.isAgency}
                        onSubmit={setTab}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
              <Card.Footer style={{ background: 'transparent' }}>
                <p>
                  Have an account?&nbsp;
                  <button
                    type="button"
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#B2A05E',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      padding: 0,
                      font: 'inherit',
                    }}
                    onClick={() => history.push('/login')}
                  >
                    Click Here!
                  </button>
                </p>
              </Card.Footer>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Signup;
