import React from 'react';
import { Container, Button, Table } from 'react-bootstrap';

export const ResultsContainer = ({ children }) => (
  <Container
    style={{ height: '60vh' }}
    fluid
    className="bg-light row px-56 py-24"
  >
    <div className=" ">
      <h4 className="text-center mt-16 text-uppercase">Session 5</h4>
      <h6 className="text-center text-uppercase">Value Propostion</h6>
      {children}
    </div>
    <div className="d-flex justify-content-center align-items-end p-16">
      <div>
        <Button variant="primary" className="mx-16 text-secondary" size="lg">
          Update Answers
        </Button>
        <Button
          variant="secondary"
          className="text-nowrap text-primary"
          size="lg"
        >
          Ready to Move-On
        </Button>
      </div>
    </div>
  </Container>
);

export const ResultsInputsContainer = ({ children }) => (
  <div className="border border-secondary">
    <h6 className="text-center text-uppercase mt-16 mb-16"> Five Why&apos;s</h6>
    <div className="p-32">{children}</div>
  </div>
);

export const ResultsTable = ({ results }) => (
  <Table responsive className="border border-primary p-24">
    <thead className="border border-primary">
      <tr>
        <th className="border border-primary fs-5">{results?.title}</th>
      </tr>
    </thead>
    <tbody style={{ textAlign: 'left' }}>
      <tr>
        <td>1. {results?.['needs-1'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>2. {results?.['needs-2'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>3. {results?.['needs-3'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>4. {results?.['needs-4'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>5. {results?.['needs-5'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>{results?.bottomLineNeeds}</td>
      </tr>
    </tbody>
  </Table>
);

export const ResultsSingleTable = ({ results }) => (
  <Table responsive className="border border-primary p-24">
    <thead className="border border-primary">
      <tr>
        <th className="border border-primary fs-5">{results[0]?.title}</th>
      </tr>
    </thead>
    <tbody style={{ textAlign: 'left' }}>
      <tr>
        <td>1. {results[0]?.['needs-1'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>2. {results[0]?.['needs-2'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>3. {results[0]?.['needs-3'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>4. {results[0]?.['needs-4'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>5. {results[0]?.['needs-5'] || ''}</td>
      </tr>
      <tr>
        <td>WHY?</td>
      </tr>
      <tr>
        <td>{results[0]?.bottomLineNeeds}</td>
      </tr>
    </tbody>
  </Table>
);
