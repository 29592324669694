/* eslint-disable no-useless-escape */
import { Form } from 'react-bootstrap';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';
import { useState } from 'react';

const isFullValidPhone = (number) => {
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return regex.test(number);
};

const Phone = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
}) => {
  const [maskedPhoneValue, setMaskedPhoneValue] = useState(isFullValidPhone(value) ? `###-###-###${value.substring(6)}` : '');
  const [actualPhoneValue, setActualPhoneValue] = useState(value ?? '');

  const handleChange = (e) => {
    setActualPhoneValue(e.target.value);
    const displayValue = isFullValidPhone(e.target.value) ? maskedPhoneValue : actualPhoneValue ?? '';
    setMaskedPhoneValue(displayValue);
    onChange(e);
  };

  return (
    <div className={className}>
      {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
      <div>
        <TextMask
          id={name}
          name={name}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          Component={InputAdapter}
          className={`form-control ${inputClassName}`}
          value={actualPhoneValue}
          guide={false}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Phone;
