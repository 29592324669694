/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-alert */
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useState, useEffect } from 'react';

const GlobalCalendar = ({ eventsData }) => {
  const [eventsToShow, setEventsToShow] = useState();
  useEffect(() => {
    const filteredEvents = eventsData.map((currentObj) => ({
      start: currentObj.startTime,
      end: currentObj.endTime,
      title: currentObj.title,
    }));
    setEventsToShow(filteredEvents);
  }, [eventsData]);

  const localizer = momentLocalizer(moment);
  /*
  const handleSelectSlot = useCallback(({ start, end }) => {
    const title = window.prompt('New Event Name');
    const description = window.prompt('Event Description');
    const id = eventsData.length + 1;
    if (title) {
      const newSessionsData = [...eventsData, { id, start, end, title, description }];
      const sortedSessionsData = newSessionsData.sort((a, b) => a.start - b.start);
    }
  }, [eventsData]);

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    [],
  );
   */

  return (
    <div style={{ height: '600px' }}>
      <Calendar
        localizer={localizer}
        showAllEvents={true}
        events={eventsToShow}
        showMultiDayTimes
        onSelectEvent={() => null}
        views={['month', 'week']}
      />
    </div>
  );
};

export default GlobalCalendar;
