import { apiFetch } from 'api/api';

export default async function fetchAndSetSessionConfig(programId, token) {
  try {
    return await apiFetch({
      path: '/programs',
      method: 'Post',
      body: { programId },
      token,
    });
  } catch (error) {
    throw new Error(error);
  }
}
