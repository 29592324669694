import { apiFetch } from 'api/api';
import Signal from 'libs/signals';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';

export const sessionListSignal = Signal([]);

export async function fetchAndSetSessionListSignal({ programId, token }) {
  try {
    const result = await apiFetch({
      path: `/programs/${programId}`,
      token,
    });
    sessionListSignal.update(result.data.sessions);
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: 'Unable to fetch session list',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
}

export const isDateEqual = (dateToCompare) => {
  const currentDate = new Date();
  const targetDate = new Date(dateToCompare);

  return (
    currentDate.getFullYear() === targetDate.getFullYear() &&
    currentDate.getMonth() === targetDate.getMonth() &&
    currentDate.getDate() === targetDate.getDate()
  );
};

export const getSessionProgress = (session) => {
  const screenCount = session?.screens?.length;
  const completedScreens = session?.screens?.filter((screen) => screen.completed);
  const completedScreenCount = completedScreens?.length;

  return Math.round((completedScreenCount / screenCount) * 100) || 0;
};
