import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import {
  InputGroupContainer,
  InputsContainer,
} from 'components/global/InputGroups/components/inputContainer';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';

const TargetAudienceResults = ({ prompt }) => {
  const [results, setResults] = useState({});

  useEffect(() => {
    const newResults = getPromptValueFromProgramSignal(prompt);
    if (newResults && Object.keys(newResults).length > 0) {
      setResults(newResults);
    }
  }, [prompt]);

  return (
    <div>
      <InputGroupContainer>
        <InputsContainer>
          <div className="h4 text-center mb-32 mt-32 text-uppercase">
            Top Target Audiences
          </div>
          <Table bordered>
            <tbody className="border border-primary">
              {results?.rows?.map((result, idx) => (
                <tr key={idx}>
                  <td style={{ textAlign: 'left' }}>
                    {`${idx + 1}. ${results[result]}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InputsContainer>
      </InputGroupContainer>
    </div>
  );
};

export default TargetAudienceResults;
