import { Container } from 'react-bootstrap';
import { screenSignal } from '../Session.helpers';

const SessionContent = () => (
  <Container className="text-center">
    {screenSignal.value.content?.map((content) => (
      <div key={content.title}>
        {content.title && <SessionContentHeader content={content} />}
        <SessionContentBodySwitch content={content} />
      </div>
    ))}
  </Container>
);

const SessionContentHeader = ({ content }) => (
  <div
    className="text-white py-24 lead-600 text-center bg-dark"
    style={{ opacity: 0.8 }}
  >
    {content.title}
  </div>
);
const SessionContentBodySwitch = ({ content, onLoad }) => {
  switch (content.type) {
    case 'videoExplainer':
      return <SessionContentVideo content={content} onLoad={onLoad} />;
    case 'videoInteractive':
      return <SessionInteractiveVideo content={content} onLoad={onLoad} />;
    default:
      return 'foo';
  }
};

const SessionContentVideo = ({ content }) => {
  if (!content.link) {
    return <div className="bg-light p-32"> Missing Video Link</div>;
  }
  return (
    <iframe
      className="w-100"
      height="510px"
      width="100%"
      src={`${content.link?.trim()}?enablejsapi=1`}
      title={content.title}
      frameBorder="0"
      allow="camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;"
      allowFullScreen
    />
  );
};

const SessionInteractiveVideo = ({ content }) => {
  if (!content.link) {
    return <div className="bg-light p-32"> Missing Video Link</div>;
  }
  return (
    <iframe
      className="w-100"
      height="480px"
      width="100%"
      src={`${content.link?.trim()}?enablejsapi=1`}
      title={content.title}
      frameBorder="0"
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *; picture-in-picture *;"
      allowFullScreen
    />
  );
};

export default SessionContent;
