import { useState, useEffect } from 'react';
import { Card, Image, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import selectPlanSignal from 'signals/SelectPlan.signal';
import Plans from './components/Plans';
import PaymentInfo from './components/PaymentInfo';
import { handleFormChange } from './SelectPlan.helpers';

const SelectPlan = () => {
  const [tab, setTab] = useState('Plans');
  const history = useHistory();

  const handleSelectPlan = async (plan) => {
    await handleFormChange({ name: 'plan', value: plan });
    await handleFormChange({ name: 'totalCost', value: plan === 'oneTimePurchase' ? 1800 : 300 });
    setTab('PaymentInfo');
  };

  const handleToggleCoaching = (isBuying) => {
    handleFormChange({ name: 'isBuyingCoaching', value: !!isBuying });
    handleFormChange({ name: 'totalCost', value: isBuying ? selectPlanSignal.value.totalCost + 250 : selectPlanSignal.value.totalCost - 250 });
  };

  const handleSubmitPayment = () => {
    // TODO: Use payment integration here once we have that
    history.push('/order-confirmation');
  };

  useEffect(() => {
    handleFormChange({ name: 'totalCost', value: 0 });
    handleFormChange({ name: 'isBuyingCoaching', value: false });
  }, []);

  return (
    <div
      className="position-relative w-100 px-192 pb-64 pt-136"
      style={{ width: '100vw', minHeight: '100vh', backgroundImage: 'url(/images/bg-squares.png)' }}
    >
      <Card className="w-100 rounded-0 bg-light py-80 px-104">
        <div className="d-flex justify-content-center">
          <Image src="/images/bt-main.png" className="" style={{ objectFit: 'contain', maxWidth: '200px' }} />
        </div>
        <Tab.Container activeKey={tab} className="border-0">
          <Tab.Content className="w-100">
            <Tab.Pane eventKey="Plans">
              <Plans onSubmit={handleSelectPlan} />
            </Tab.Pane>
            <Tab.Pane eventKey="PaymentInfo">
              <PaymentInfo onToggleCoaching={handleToggleCoaching} onSubmit={handleSubmitPayment} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  );
};

export default SelectPlan;
