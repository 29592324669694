/* eslint-disable import/no-cycle */
import React from 'react';
import TableMultiSelect from './components/MultiSelect';

const TableMultiSelectView = ({ prompt }) => (
  <div className="d-flex flex-column h-100">
    {/* <div
      className="py-56 vh-100 container-fluid"
      style={{ width: '100vw',
        backgroundImage: 'url(/images/bg-sessions.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingLeft: '10%',
        paddingRight: '10%',
        flex: '1' }}
    > */}
    <div className="bg-light py-8">
      <div className="container-fluid">
        <div className=" my-32">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img src="/images/decorative-icon-1.svg" alt="" className="pb-8" />
            <div className="h3 text-primary text-uppercase text-center mb-0 mx-56">
              Top Three Brand Archetypes
            </div>
            <img src="/images/decorative-icon-1.svg" alt="" className="pb-8" />
          </div>
          <div className="d-flex justify-content-center align-self-center">
            <hr
              className=" "
              style={{
                border: '2px solid var(--kd-gold, #B2A05E)',
                width: '75%',
              }}
            />
          </div>
          <div className="text-center">
            <subtitle
              style={{ fontSize: '16px', textAlign: 'center' }}
              className="text-center"
            >
              Please rank your top 3 brand personalties. Assign a rank of 1 to
              your most preferred brand personality, 2 to the second most
              preferred, and 3 to the third most preferred.
            </subtitle>
          </div>
        </div>
        <TableMultiSelect prompt={prompt} />
      </div>
    </div>
  </div>
  // </div>
);

export default TableMultiSelectView;
