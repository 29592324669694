import schedulingSignal from 'signals/Scheduling.signal';
// import { apiFetch } from 'api/api';
// import userSignal from 'signals/User.Signal';
import { firestore } from 'utils/firebase';
import Signal from 'libs/signals';
// import loaderSignal from 'signals/Loader.signal';

export const scheduleSessionSignal = Signal([]);
export const sessionsToScheduleSignal = Signal([]);

export const handleFormChange = ({ name, value }) => {
  if (name === 'sessionStartTime') {
    const time = new Date(value);
    const endTime = new Date(time.getTime() + 90 * 60000);
    schedulingSignal.update({
      [name]: value,
      sessionEndTime: endTime,
    });
  }
  if (name === 'sessionDate') {
    const date = new Date(value);
    const startTime = schedulingSignal.value.sessionStartTime || date;
    const endTime = schedulingSignal.value.sessionEndTime || date;
    const newStartTime = new Date(startTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate()));
    const newEndTime = new Date(endTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate()));
    schedulingSignal.update({
      [name]: value,
      sessionStartTime: newStartTime,
      sessionEndTime: newEndTime,
    });
  }
  schedulingSignal.update({
    [name]: value,
  });
};

export const handleSessionScheduleFormChange = ({ name, value }) => {
  sessionsToScheduleSignal.update({
    [name]: value,
  });
};

/*
export async function fetchAndSetProgramsWithSessionsSignal(id) {
  try {
    const doc = await firestore.collection('packages');
    const snapshot = await doc.where('organizationId', '==', id).get();
    const programs = [];
    const sessions = [];
    snapshot.forEach((document) => {
      const docData = document.data();
      programs.push({ label: docData.name, value: docData._id });
      sessions.push({ recordId: docData._id, sessions: docData.sessions });
    });

    scheduleSessionSignal.update([
      {
        programs,
      },
      {
        sessions,
      },
    ]);
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: 'Unable to fetch list',
      error,
    });
  }
}
 */

export const setProgramToActive = async (programId) => {
  const programRef = await firestore.collection('packages').doc(programId);
  const programData = await programRef.get();
  if (!programData) return;
  if (programData.data().activeProgram) return;
  await programRef.update({ ...programData.data(), activeProgram: true });
};
