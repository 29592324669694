/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Card, Button, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import oneTrifectaImg from '../../../../images/one-trifecta.png';
import twoTrifectaImg from '../../../../images/two-trifecta.png';
import threeTrifectaImg from '../../../../images/three-trifecta.png';

const brandTrifectaOptions = [
  {
    id: 0,
    image: oneTrifectaImg,
    title: 'One Brand Trifecta',
    description:
      'For an organization needing a single Brand Trifecta that speaks to all of their target audiences, and where all target audiences have the same bottom-line heart pain which the brand solves.',
  },
  {
    id: 1,
    image: twoTrifectaImg,
    title: 'Two Brand Trifectas',
    description:
      'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but a supporting Brand Trifecta for the brand’s target audience to speak to how the brand solves the target audience’s heart pain.',
  },
  {
    id: 2,
    image: threeTrifectaImg,
    title: 'Three Brand Trifectas',
    description:
      'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top target audiences to speak to how the brand solves each target audience’s heart pain.',
  },
];

const ExploreYourOptions = ({ modal }) => {
  const history = useHistory();

  const handleViewOptionsClick = () => {
    history.push('/packages');
  };

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 mb-32 font-josefin"
      style={{ zIndex: modal === 'ExploreModal' ? 2000 : 0 }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">BRAND TRIFECTA OPTIONS</h4>
      </Card.Header>
      <Card.Body className="text-wrap px-0 py-0">
        {brandTrifectaOptions.map((option, idx) => (
          <Row key={idx} className="py-32 m-0 px-0">
            <Col
              xs={4}
              lg={4}
              className="d-flex flex-column align-items-center justify-content-center m-0 p-0"
            >
              <Image
                src={option.image}
                style={{ width: '130px', height: 'auto' }}
              />
            </Col>
            <Col xs={8} lg={8} className="m-0 p-0">
              <p className="lead-400 fs-5 mb-8">{option.title}</p>
              <p
                style={{ paddingRight: '30px' }}
                className="base fs-6 text-secondary text-truncate text-wrap"
              >
                {option.description}
              </p>
              <Button className="d-flex align-items-center bg-transparent border-0 text-primary p-0">
                <p
                  onClick={handleViewOptionsClick}
                  className="base fs-6 mb-0 pe-8"
                >
                  Learn More
                </p>
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Col>
          </Row>
        ))}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleViewOptionsClick}
        >
          View All Options
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ExploreYourOptions;
