import { useState, useEffect, useCallback } from 'react';
import { Card, Image, Tab } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import schedulingSignal from 'signals/Scheduling.signal';
import userSignal from 'signals/User.Signal';
import alertSignal from 'signals/Alert.signal';
import { apiFetch } from 'api/api';
import { EMAIL_REGEX } from '../../../constants';
import QuickVideo from './components/QuickVideo';
import ScheduleSession1 from './components/ScheduleSession1';
import { handleFormChange, setProgramToActive } from './Scheduling.helpers';
import {
  allProgramsSignal,
  fetchAndSetProgramData,
  programsSignal,
} from '../Dashboard/Dashboard.helpers';

const Scheduling = () => {
  const [tab, setTab] = useState('QuickVideo');
  const [showModal, setShowModal] = useState(false);
  const [programDocId, setProgramDocId] = useState(
    programsSignal.value._id || '',
  );
  const [nameOfProgram, setNameOfProgram] = useState('');
  const [unscheduledSession, setUnscheduledSession] = useState([]);

  const history = useHistory();

  const init = useCallback(async () => {
    fetchAndSetProgramData();
  });

  useEffect(() => {
    init();
  }, []);

  const handleToggleModal = () => {
    handleFormChange({
      name: 'newAttendee',
      value: { firstName: '', lastName: '', email: '', role: '' },
    });
    setShowModal(!showModal);
  };

  const handleChangeNewAttendee = ({ name, value }) => {
    handleFormChange({
      name: 'newAttendee',
      value: {
        ...schedulingSignal.value.newAttendee,
        [name]:
          name !== 'email'
            ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            : value.toLowerCase(),
      },
    });
  };

  const handleSubmitNewAttendee = () => {
    const newAttendee = {
      ...schedulingSignal.value.newAttendee,
      isEmailSent: false,
    };

    if (EMAIL_REGEX.test(schedulingSignal.value.newAttendee.email)) {
      handleFormChange({
        name: 'teamMembers',
        value: [...schedulingSignal.value.teamMembers, newAttendee],
      });
      alertSignal.update({
        type: 'notification',
        message: 'Attendee added to the list',
        className: 'bg-success',
      });
      handleToggleModal();
    } else {
      alertSignal.update({
        type: 'alert',
        message: 'Email not valid, please update',
      });
    }
  };

  const handleToggleIsEmailSent = async (index) => {
    if (
      !schedulingSignal.value.sessionDate ||
      !schedulingSignal.value.sessionStartTime
    ) {
      alertSignal.update({
        type: 'alert',
        message: 'You have to select a Date and Time for your Session',
      });
      return;
    }

    const updateMembers = schedulingSignal.value.teamMembers;
    const memberToUpdate = updateMembers.splice(index, 1);
    memberToUpdate[0].isEmailSent = !memberToUpdate[0].isAttending;
    handleFormChange({
      name: 'teamMembers',
      value: [...updateMembers, ...memberToUpdate],
    });
    const body = {
      date: schedulingSignal.value.sessionDate,
      time: schedulingSignal.value.sessionStartTime,
      teamMembers: memberToUpdate,
      sessionId: unscheduledSession[0]?.uid,
    };

    try {
      const response = await apiFetch({
        path: '/sessions/scheduling/sendEmail',
        body,
        method: 'POST',
        token: userSignal.value.fbUser.Aa,
      });

      if (response.message) {
        alertSignal.update({
          type: 'notification',
          message: 'Email sent',
          className: 'bg-success',
        });
      }
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: `${error.message}`,
      });
    }
  };

  const handleInviteAll = async () => {
    if (
      !schedulingSignal.value.sessionDate ||
      !schedulingSignal.value.sessionStartTime
    ) {
      alertSignal.update({
        type: 'alert',
        message: 'You have to select a Date and Time for your Session',
      });
      return;
    }

    const updatedSignal = schedulingSignal.value.teamMembers.map((member) => ({
      ...member,
      isEmailSent: true,
    }));

    schedulingSignal.update({
      teamMembers: updatedSignal,
    });
    const body = {
      date: schedulingSignal.value.sessionDate,
      time: schedulingSignal.value.sessionStartTime,
      teamMembers: updatedSignal,
      sessionId: unscheduledSession[0].uid,
    };

    try {
      const response = await apiFetch({
        path: '/sessions/scheduling/sendEmail',
        body,
        method: 'POST',
        token: userSignal.value.fbUser.Aa,
      });

      if (response.message) {
        alertSignal.update({
          type: 'notification',
          message: 'Emails sent',
          className: 'bg-success',
        });
      }
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: `${error.message}`,
      });
    }
  };

  useEffect(() => {
    // To do
    if (!schedulingSignal.value.teamMembers) {
      // TODO: Get firstName, lastName, email, and companyRole from userSignal and put it
      // below in order to begin with one attendee (the user) with all info already filled in
      schedulingSignal.update({
        teamMembers: [],
        newAttendee: {},
      });
    }

    const programSelected = allProgramsSignal.value.filter(
      (program) => program._id === programDocId,
    );
    const sessions = programSelected[0]?.sessions;
    const sessionsToBeScheduled = [];

    sessions?.forEach((session) => {
      if (!session.schedule) {
        // handleSessionScheduleFormChange({ name: 'sessions', value: [...sessionsToScheduleSignal.value.sessions, session] });
        sessionsToBeScheduled.push(session);
      }
    });
    setNameOfProgram(programSelected[0]?.name);
    setUnscheduledSession(sessionsToBeScheduled);
  }, [programDocId]);

  const handleSubmit = async () => {
    try {
      const body = {
        ...schedulingSignal.value,
        programId: programDocId,
        sessionId: unscheduledSession[0].uid,
      };
      const response = await apiFetch({
        path: '/sessions/scheduling',
        body,
        method: 'POST',
        token: userSignal.value.fbUser.Aa,
      });

      if (response) {
        const updatedSessions = [...unscheduledSession];
        updatedSessions.splice(0, 1);
        setUnscheduledSession(updatedSessions);
        schedulingSignal.update({
          teamMembers: [],
          newAttendee: {},
        });
        await fetchAndSetProgramData();
        if (programDocId === programsSignal.value._id) {
          setProgramToActive(programsSignal.value._id);
        }
        if (updatedSessions.length === 0) {
          history.push('/dashboard');
        }
      }
      alertSignal.update({
        type: 'notification',
        message: 'Session Scheduled',
        className: 'bg-success',
      });
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: error.message,
        error,
      });
    }
  };

  return (
    <div
      className="position-relative text-primary w-100 pb-64 pt-136"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
        paddingLeft: '15%',
        paddingRight: '15%',
      }}
    >
      <div style={{ marginBottom: '50px' }}>
        <Link className="text-secondary base back-button" to="/dashboard">
          <FontAwesomeIcon style={{ marginRight: 16 }} icon={faArrowLeft} />{' '}
          Back to Dashboard
        </Link>
      </div>
      <Card className="text-center w-100 rounded-0 bg-light py-80 px-104">
        <div className="d-flex justify-content-center">
          <Image
            src="/images/bt-main.png"
            className=""
            style={{ objectFit: 'contain', maxWidth: '200px' }}
          />
        </div>
        <Tab.Container activeKey={tab} className="border-0">
          <Tab.Content className="w-100">
            <Tab.Pane eventKey="QuickVideo">
              <QuickVideo onSubmit={setTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="ScheduleSession1">
              <ScheduleSession1
                onSubmit={handleSubmit}
                show={showModal}
                toggleShow={handleToggleModal}
                onChangeAttendee={handleChangeNewAttendee}
                onSubmitNewAttendee={handleSubmitNewAttendee}
                onToggleIsEmailSent={handleToggleIsEmailSent}
                unscheduledSession={unscheduledSession}
                handleInviteAll={handleInviteAll}
                allPrograms={allProgramsSignal.value}
                setProgramDocId={setProgramDocId}
                nameOfProgram={nameOfProgram}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  );
};

export default Scheduling;
