/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import TopBarAgency from 'components/views/Dashboard/agency-dashboard/agency-components/TopBarAgency';
import { dashboardDataSignal } from '../../Dashboard/Dashboard.helpers';

const AgencyResourcesList = () => {
  const [downloading, setDownloading] = useState(false);

  const { pdfResources } = dashboardDataSignal.value;

  const history = useHistory();

  const handleBackToDashboardClick = () => {
    history.push('/dashboard');
  };

  const handleDownloadAll = async () => {
    setDownloading(true);

    for (const resource of pdfResources) {
      try {
        const url = resource.link;
        if (!url) {
          throw new Error('Invalid URL');
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to download: ${url}`);
        }

        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectURL;
        link.download = resource.link || 'downloaded-file.pdf';
        link.style.display = 'none';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(objectURL);
      } catch (error) {
        console.error(error);
      }
    }

    setDownloading(false);
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBarAgency />
      <div
        className="position-relative w-100 px-lg-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header
            className="h3 bg-success text-primary text-center text-uppercase rounded-0 py-32"
            as="h3"
          >
            Agency Resources
          </Card.Header>
          <Card.Body className="px-xl-80 px-24 pt-xl-80 pt-md-64">
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-0 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  <div className="py-56">
                    <Row className="p-0 m-0 w-100 px-16">
                      <Col lg={9} md={8} xs={12} className="p-0 m-0">
                        <h4 className="h4 fw-600 text-primary">
                          RESOURCE CENTER
                        </h4>
                        <p className="base-400 text-primary-light">
                          Description of doc center and what to expect here...
                        </p>
                      </Col>
                      <Col
                        lg={3}
                        md={4}
                        xs={12}
                        className="text-md-end text-start p-0 my-md-0 my-16"
                      />
                    </Row>
                    <Row className="m-0 w-100 px-16 pt-40">
                      <Col lg={9} md={8} xs={12} className="p-0 m-0">
                        <p className="lead-600">All Session Resources</p>
                      </Col>
                      <Col lg={3} md={4} xs={12} className="p-0">
                        <Button
                          onClick={handleDownloadAll}
                          className="base py-8 w-100"
                          variant="outline-primary"
                          disabled={downloading}
                        >
                          {downloading ? 'Downloading...' : 'Download All'}
                        </Button>
                      </Col>
                    </Row>
                    <Row className="px-16 pt-40">
                      {pdfResources?.length <= 0 ? (
                        <p className="fw-bold">
                          {' '}
                          No available resources. Please complete active
                          Sessions to view or download Resources
                        </p>
                      ) : (
                        pdfResources?.map((resource) => (
                          <Col
                            key={resource?.id}
                            lg={4}
                            md={6}
                            xs={12}
                            className="my-24"
                          >
                            <Card
                              className="border border-primary-light"
                              style={{ borderRadius: '5px' }}
                            >
                              <Card.Body className="px-8 bg-primary-light">
                                <Card.Img
                                  className="resourceImage"
                                  src={
                                    resource.attachment?.thumbnails?.large?.url
                                  }
                                  style={{ minHeight: '130px' }}
                                />
                              </Card.Body>
                              <Card.Footer className="bg-white px-32 py-16">
                                <Card.Title className="base fw-bold mb-5 text-truncate">
                                  {resource?.documentName}
                                </Card.Title>
                                <Card.Text className="small w-100 d-flex justify-content-end pt-8">
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      window.open(resource.link, '_blank')
                                    }
                                    icon={faDownload}
                                    className="text-secondary"
                                    role="button"
                                  />
                                </Card.Text>
                              </Card.Footer>
                            </Card>
                          </Col>
                        ))
                      )}
                    </Row>
                  </div>
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center border-0 bg-white pb-64">
            <Button
              className="btn-outline-secondary bg-success border-success"
              onClick={handleBackToDashboardClick}
            >
              Back to Dashboard
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

export default AgencyResourcesList;
