/* eslint-disable no-alert */
/* eslint-disable no-console */
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import alertSignal from 'signals/Alert.signal';
import { getConfig } from 'config/config';

const Notification = ({ alert, onCloseClick }) => (
  <div style={{ zIndex: 2000 }} className="container-fluid fixed-top">
    <Row
      className={`text-dark shadow-sm border-bottom border-dark py-16 ${
        alert.className || 'bg-warning'
      }`}
    >
      <Col sm="4" className="offset-4 text-center">
        {alert.message}
      </Col>
      <Col sm="4" className="d-flex justify-content-end align-items-center">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onCloseClick}
          cursor="pointer"
        />
      </Col>
    </Row>
  </div>
);

const AlertModal = ({ alert, onCloseClick, overrideCloseMessage }) => (
  <Modal
    className="fade rounded-0"
    show
    centered
    contentClassName="rounded-0 border-dark"
  >
    <Modal.Header className="bg-danger rounded-0 text-white">
      An Error has occured!
    </Modal.Header>
    <Modal.Body className="rounded-0">{alert.message}</Modal.Body>
    <Modal.Footer className="rounded-0">
      <Button
        className="btn btn-dark rounded-0"
        onClick={onCloseClick}
        type="button"
      >
        {overrideCloseMessage || 'Close'}
      </Button>
    </Modal.Footer>
  </Modal>
);

const Alert = () => {
  const alert = alertSignal.value;
  const env = getConfig('ENV');
  console.log({ alert, env });

  if (alert.error && env !== 'development' && env !== 'qa') {
    alert.message = 'An error has occurred, please reach out to support.';
  }

  if (alert.error) {
    console.error(alert.error);
  }

  const handleCloseClick = () => {
    if (alert.onClose) {
      alert.onClose();
    }
    alertSignal.reset();
  };

  if (!alert.type) {
    return null;
  }

  if (alert.type === 'notification') {
    setTimeout(() => {
      alertSignal.reset();
    }, 5000);
    return <Notification alert={alert} onCloseClick={handleCloseClick} />;
  }

  if (alert.type === 'alert') {
    return (
      <AlertModal
        alert={alert}
        onCloseClick={handleCloseClick}
        overrideCloseMessage={alert.overrideCloseMessage}
      />
    );
  }

  if (alert.type === 'critical') {
    window.alert(alert.message);
    alertSignal.reset();
    return null;
  }

  if (alert.type === 'passive') {
    return null;
  }

  return <div />;
};

export default Alert;
