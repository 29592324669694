import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import schedulingSignal from 'signals/Scheduling.signal';
import { monthNames } from 'components/views/Calendar/SessionCalendar';
import userSignal from 'signals/User.Signal';
import Loader from 'components/global/Loader';
import { fetchAndSetProgramData } from '../../Dashboard.helpers';
import {
  agencyScheduleSignal,
  getAgencySchedules,
} from '../../card-components/courseCalendar.helpers';

const AgencySchedules = ({ modal }) => {
  const [event, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const handleViewCalendarClick = () => {
    history.push('/agency/calendar');
  };

  const { userData } = userSignal.value;
  const events = agencyScheduleSignal.value;

  const init = useCallback(async () => {
    await Promise.all([
      await fetchAndSetProgramData(),
      await getAgencySchedules({
        orgData: userData.orgData,
      }),
    ]);
  });

  useEffect(() => {
    if (Object.keys(events).length > 0) {
      setIsLoading(false);
    } else {
      init();

      setTimeout(() => {
        setIsLoading(false);
      }, [4000]);
    }
  }, []);

  useEffect(() => {
    // const today = new Date();
    if (events?.length > 0) {
      /*
      const filteredEvents = events.filter((evnt) => {
        const eventDate = new Date(evnt.date);
        return eventDate > today;
      });
       */
      const sortedFilteredEvents = events.sort((a, b) => a.date - b.date);
      setEvent([sortedFilteredEvents[0]]);
    }
  }, [events]);

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 p-0 font-josefin"
      style={{
        zIndex: modal === 'ScheduleModal' ? 2000 : 0,
      }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">SCHEDULE</h4>
      </Card.Header>
      <Card.Body className="text-wrap p-0">
        {isLoading ? (
          <Row
            className="justify-content-center align-items-center"
            style={{ height: '50%' }}
          >
            <Loader className="text-center" />
          </Row>
        ) : (
          <Row className="py-8 m-0 px-0">
            <Col xs={12}>
              <p className="base text-secondary">Next Session</p>
            </Col>
            <Col
              xs={3}
              className="d-flex flex-column align-items-center m-0 px-8 py-0"
            >
              {event?.length > 0 ? (
                <>
                  <h1 className="mb-0 fw-bold">
                    {event ? new Date(event[0]?.date).getDate() : ''}
                  </h1>
                  <p className="mb-0 fs-4">
                    {event
                      ? monthNames[new Date(event[0]?.date).getMonth()]
                      : ''}
                  </p>
                </>
              ) : null}
            </Col>
            <Col xs={9} className="m-0 ps-0 pe-16">
              {event?.length > 0 ? (
                <div className="d-flex flex-row justify-content-between align-items-center  mb-16">
                  <div className="d-flex flex-column">
                    <p className="lead-400 mb-16">{event[0]?.description}</p>
                    <p className="base mb-8">
                      {new Date(event[0]?.date).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </p>
                    <p className="base-bold mb-0">{event[0]?.title}</p>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center bg-primary rounded-circle p-8"
                    style={{ height: '2.25rem', width: '2.25rem' }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="text-light h-100"
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push('/agency/calendar')}
                    />
                  </div>
                </div>
              ) : (
                <p className="mb-0">
                  {schedulingSignal.value?.userSchedule ||
                    'No upcoming sessions...'}
                </p>
              )}
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleViewCalendarClick}
          disabled={event.length === 0}
        >
          View All
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default AgencySchedules;
