/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-nested-ternary */
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import TopBar from 'components/global/TopBar';
import userSignal from 'signals/User.Signal';
import alertSignal from 'signals/Alert.signal';
import Field from 'components/global/Field';
import {
  fetchAndSetSessionListSignal,
  getSessionProgress,
  isDateEqual,
  sessionListSignal,
} from './SessionsList.helpers';
import {
  allProgramsSignal,
  fetchAndSetProgramData,
} from '../Dashboard/Dashboard.helpers';

const SessionsList = () => {
  const [programs, setPrograms] = useState([{ label: '', value: '' }]);
  const [nameOfProgram, setNameOfProgram] = useState('');
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const programId = searchParams.get('programId');

  const init = useCallback(async () => {
    await Promise.all([
      fetchAndSetSessionListSignal({
        programId,
        token: userSignal.value.fbUser.Aa,
      }),
      fetchAndSetProgramData(),
    ]);
  }, [programId]);

  useEffect(() => {
    init();
  }, [programId]);

  useEffect(() => {
    setPrograms(() =>
      allProgramsSignal.value.map((program) => ({
        label:
          program.name === 'Part One'
            ? program.name
            : program.name === 'Single Trifecta'
            ? 'Part Two - One Brand Trifecta'
            : `Part Two - ${program.name.split(' ')[0]} Brand ${
                program.name.split(' ')[1]
              }`,
        value: program._id,
      })),
    );
    setNameOfProgram(() =>
      allProgramsSignal.value.filter((program) => program._id === programId),
    );
  }, [allProgramsSignal.value.length]);

  const handleProgramIdChange = ({ value }) => {
    setNameOfProgram(() =>
      allProgramsSignal.value.filter((program) => program._id === value),
    );
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('programId', value);

    history.replace({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    });
  };

  const handleGoToSession = (session) => {
    if (session.number === 1 && session.programs[0].uid === 'part-one') {
      history.push(`/session/${programId}/${session.uid}/screen/1`);
    } else if (session.number === 1 && session.programs[0].uid !== 'part-one') {
      const sessionToCompare = allProgramsSignal.value.filter(
        (partOneProgram) => partOneProgram.uid === 'part-one',
      );
      if (sessionToCompare[0] && sessionToCompare[0].completedProgram) {
        history.push(`/session/${programId}/${session.uid}/screen/1`);
      } else {
        alertSignal.update({
          type: 'alert',
          message:
            'Please finish Part One to gain access to your Part Two Program',
        });
      }
    } else {
      const sessionToCompare = sessionListSignal.value.filter(
        (allSessions) => allSessions.number === session.number - 1,
      );
      if (sessionToCompare[0] && sessionToCompare[0].completed) {
        history.push(`/session/${programId}/${session.uid}/screen/1`);
      } else if (sessionToCompare[0] && !sessionToCompare[0].completed) {
        alertSignal.update({
          type: 'alert',
          message:
            'Please finish the previous session to gain access to your next session',
        });
      }
    }
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBar />
      <div
        className="position-relative w-100 px-8 pb-64 pt-80"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Container>
          <Link className="text-secondary base back-button" to="/dashboard">
            <FontAwesomeIcon style={{ marginRight: 16 }} icon={faArrowLeft} />{' '}
            Back to Dashboard
          </Link>
          <Card className="border-0 rounded-0 mt-56">
            <Card.Header className="bg-secondary border-0 rounded-0 text-center py-32 text-primary h3 text-uppercase">
              Sessions
            </Card.Header>
            <Card.Body className="px-64 px-xl-104 py-64">
              <h4 className="text-uppercase text-primary">Session Progress</h4>
              <Row className="mt-16">
                <Col lg={9}>
                  <p className="base text-info">
                    {`Review status and access sessions of ${
                      nameOfProgram[0]?.name === 'Part One'
                        ? nameOfProgram[0]?.name
                        : nameOfProgram[0]?.name === 'Single Trifecta'
                        ? 'Part Two - One Brand Trifecta'
                        : `Part Two - ${
                            nameOfProgram[0]?.name.split(' ')[0]
                          } Brand ${nameOfProgram[0]?.name.split(' ')[1]}`
                    }`}
                    .
                  </p>
                </Col>
                <Col lg={3}>
                  <Field
                    type="select"
                    name="program_id"
                    value=""
                    placeholder="Choose Program"
                    inputClassName="border-0 bg-gray-100 rounded-2"
                    dropDownClassName="border-0 bg-gray-100 rounded-2"
                    onChange={handleProgramIdChange}
                    options={programs}
                  />
                </Col>
              </Row>
              <Row className="mt-72 mb-8 px-40">
                <Col lg={2}>
                  <p className="text-primary base-bold text-center">Session</p>
                </Col>
                <Col lg={2}>
                  <p className="text-primary base-bold text-center">
                    Date Scheduled
                  </p>
                </Col>
                <Col lg={2}>
                  <p className="text-primary base-bold text-center">
                    {' '}
                    Review Status
                  </p>
                </Col>
                <Col lg={2}>
                  <p className="text-primary base-bold text-center">Progress</p>
                </Col>
                <Col lg={2}>
                  <p className="text-primary base-bold text-center">Playbook</p>
                </Col>
                <Col lg={2}>
                  <p className="text-primary base-bold text-center">
                    Access Session
                  </p>
                </Col>
              </Row>
              {sessionListSignal.value?.map((session, index) => {
                const isSessionAvailable = session.schedule?.date
                  ? isDateEqual(session.schedule?.date)
                  : false;
                return (
                  <Row
                    key={session.id}
                    className={`bg-light py-32 px-24 px-xl-40 align-items-center${
                      index > 0 ? ' mt-32' : ''
                    }`}
                  >
                    <Col className="container text-center" lg={2}>
                      <p className="text-primary base-bold mb-4">{`Session ${
                        index + 1
                      }`}</p>
                      <p className="text-primary base mb-0">{session.title}</p>
                    </Col>
                    <Col className="container text-center" lg={2}>
                      {session.schedule ? (
                        // <p className="text-info base mb-0">{new Date(session.schedule.date).toLocaleString()}</p>
                        <Moment className="text-info" format="YYYY-MM-DD">
                          {session.schedule.date}
                        </Moment>
                      ) : (
                        <Button
                          style={{ marginLeft: '10px' }}
                          className="btn-outline-secondary"
                          onClick={() => history.push('/scheduling')}
                        >
                          Schedule
                        </Button>
                      )}
                    </Col>
                    <Col className="container text-center" lg={2}>
                      <p className="text-info base mb-0 text-uppercase">
                        {getSessionProgress(session) === 100
                          ? 'Completed'
                          : getSessionProgress(session) > 0
                          ? 'Active'
                          : 'Not Started'}
                      </p>
                    </Col>
                    <Col className="container text-center" lg={2}>
                      <p className="text-info base mb-0">{`${getSessionProgress(
                        session,
                      )}%`}</p>
                    </Col>
                    <Col lg={2}>
                      <Button
                        role="link"
                        className="text-primary small mb-0 bg-light border-0 px-0 py-8"
                        onClick={() => {
                          history.push('/resources');
                        }}
                      >
                        Download Playbook
                      </Button>
                    </Col>
                    <Col className="container text-center" lg={2}>
                      <Button
                        className="text-primary small mb-0 bg-light border-0 px-0 py-8"
                        onClick={() => handleGoToSession(session)}
                        disabled={
                          !isSessionAvailable &&
                          getSessionProgress(session) !== 100
                        }
                      >
                        Go to session
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SessionsList;
