/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCircleRight } from '@fortawesome/free-solid-svg-icons';
import TopBar from 'components/global/TopBar';
// import { css } from '@emotion/react';
import GlobalCalendar from 'components/global/GlobalCalendar';
import editScheduleSignal from 'signals/EditSchedule.signal';
import Field from 'components/global/Field';
import { getCourseScheduleFromProgramSignal } from '../Dashboard/card-components/courseCalendar.helpers';
import {
  allProgramsSignal,
  fetchAndSetProgramData,
} from '../Dashboard/Dashboard.helpers';
import {
  handleEditScheduleFormChange,
  handleEditScheduleSubmit,
} from './SessionCalendar.helpers';

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const SessionCalendar = () => {
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const [programs, setPrograms] = useState([{ label: '', value: '' }]);
  const [programIdSelectedDropDown, setProgramIdSelectedDropDown] =
    useState('');
  const [nameOfProgramSelected, setNameOfProgramSelected] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [edit, setEdit] = useState([false, 0]);
  const [hovered, setHovered] = useState([false, 0]);

  useEffect(() => {
    const programSelected = allProgramsSignal.value.filter(
      (program) => program._id === programIdSelectedDropDown,
    );
    setNameOfProgramSelected(programSelected[0]?.name);
    const eventsData = getCourseScheduleFromProgramSignal(
      programSelected[0] ? programSelected[0] : {},
    );
    if (eventsData === undefined || eventsData === null) {
      fetchAndSetProgramData();
    }
    if (eventsData?.length > 0) {
      const filteredData = eventsData.map((event) => {
        const dateString = event.startTime;
        const dateObj = new Date(dateString);
        const timeString = dateObj.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
        const splittedDate = dateObj.toISOString().split('T')[0].split('-');
        event.day = splittedDate[2];
        event.month = splittedDate[1] - 1;
        event.time = timeString;
        return event;
      });
      filteredData.sort((a, b) => {
        const dateA = new Date(a.startTime);
        const dateB = new Date(b.startTime);
        return dateA - dateB;
      });
      setFilteredEvents(filteredData);
      setEvents(eventsData);
    } else {
      setEvents([]);
      setFilteredEvents([]);
    }
  }, [programIdSelectedDropDown, allProgramsSignal.value]);

  useEffect(() => {
    setProgramIdSelectedDropDown(allProgramsSignal.value[0]._id);
    setPrograms(() =>
      allProgramsSignal.value.map((program) => ({
        label:
          program.name === 'Part One'
            ? program.name
            : program.name === 'Single Trifecta'
            ? 'Part Two - One Brand Trifecta'
            : `Part Two - ${program.name.split(' ')[0]} Brand ${
                program.name.split(' ')[1]
              }`,
        value: program._id,
      })),
    );
  }, []);

  const handleProgramIdChange = ({ value }) => {
    setProgramIdSelectedDropDown(value);
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBar />
      <div
        className="position-relative w-100 px-xl-128 px-md-40 px-32 pb-64 pt-136 calendar"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header className="bg-success text-primary text-center rounded-0 py-32">
            <h3 className="h3 mb-0 text-uppercase">Session Schedule</h3>
          </Card.Header>
          <Card.Body className="p-0 m-0">
            <Row className="py-40 p-16 m-0">
              <Col lg={8}>
                <h2 className="fw-bold text-primary text-center">
                  Program{' '}
                  {nameOfProgramSelected === 'Part One'
                    ? nameOfProgramSelected
                    : `Part Two - ${
                        nameOfProgramSelected === 'Single Trifecta'
                          ? 'One Brand Trifecta'
                          : `${nameOfProgramSelected?.split(' ')[0]} Brand ${
                              nameOfProgramSelected?.split(' ')[1]
                            }`
                      }`}
                </h2>
              </Col>
              <Col style={{ marginTop: '10px' }} lg={4}>
                <Field
                  type="select"
                  name="program_id"
                  value=""
                  placeholder="Choose Program"
                  inputClassName="border-0 bg-gray-100 rounded-2"
                  dropDownClassName="border-0 bg-gray-100 rounded-2"
                  onChange={handleProgramIdChange}
                  options={programs}
                />
              </Col>
            </Row>
            <Row className="w-100 py-24 p-0 m-0">
              <Col lg={7} xs={12} className="ps-64">
                {filteredEvents.length > 0 ? (
                  filteredEvents.map((session, idx) => (
                    <Row
                      key={idx}
                      className="w-100 py-40 px-48 m-0 border-bottom position-relative session-row"
                      style={{ borderBottom: '2px solid #33394A26' }}
                      onMouseEnter={() => setHovered([true, idx])}
                      onMouseLeave={() => setHovered([false, idx])}
                    >
                      <Button
                        role="button"
                        className="position-absolute fs-6 top-0 end-0 pt-8 pe-8 text-end change-date border-0 bg-transparent text-primary w-50"
                        style={{
                          display:
                            hovered[0] && hovered[1] === idx ? 'block' : 'none',
                        }}
                        onClick={() => setEdit([!edit[0], idx])}
                      >
                        Change date <FontAwesomeIcon icon={faPencil} />
                      </Button>
                      <Col xs={2} className="text-center">
                        <p className="lead fs-3 fw-bold mb-0">{session.day}</p>
                        <p className="lead">{monthNames[session.month]}</p>
                      </Col>
                      <Col xs={10} className="pt-16">
                        <Row>
                          <Col xs={10}>
                            <p className="base">{session.time}</p>
                            <p className="base fw-bold">{`Session ${session.sessionIdx}`}</p>
                            <p className="base">{session.description}</p>
                          </Col>
                          <Col xs={2}>
                            <Link
                              to={`/sessions?programId=${session.programId}`}
                              style={{ cursor: 'pointer' }}
                            >
                              <FontAwesomeIcon
                                icon={faCircleRight}
                                className="fs-4"
                              />
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                      {edit[0] && edit[1] === idx ? (
                        <div>
                          <p>Edit Schedule</p>
                          <Row>
                            <Col xs={5}>
                              <label htmlFor="label" className="base">
                                <span
                                  className={
                                    editScheduleSignal.value?.sessionDate
                                      ? ''
                                      : 'text-danger'
                                  }
                                >
                                  {editScheduleSignal.value?.sessionDate
                                    ? 'Date'
                                    : 'Please Select Date *'}
                                </span>
                              </label>
                              <Field
                                type="date"
                                name="sessionDate"
                                dateFormat="MMMM d, yyyy"
                                inputClassName="bg-transparent border-0 font-josefin fs-5 text-primary"
                                onChange={handleEditScheduleFormChange}
                                className="bg-light border-0 rounded-0"
                                value={editScheduleSignal.value?.sessionDate}
                              />
                            </Col>
                            <Col xs={5}>
                              <label htmlFor="label" className="base">
                                <span
                                  className={
                                    editScheduleSignal.value?.sessionStartTime
                                      ? ''
                                      : 'text-danger'
                                  }
                                >
                                  {editScheduleSignal.value?.sessionStartTime
                                    ? 'Start Time'
                                    : 'Please Select Start Time *'}
                                </span>
                              </label>
                              <Field
                                type="date"
                                name="sessionStartTime"
                                value={
                                  editScheduleSignal.value?.sessionStartTime
                                }
                                dateFormat="h:mm aa"
                                showTimeSelect
                                className="bg-light border-0 rounded-0"
                                showTimeSelectOnly
                                inputClassName="bg-transparent border-0 font-josefin fs-5 text-primary pe-auto"
                                onChange={handleEditScheduleFormChange}
                              />
                            </Col>

                            <Col xs={2}>
                              <label htmlFor="label" className="base">
                                <span
                                  className={
                                    editScheduleSignal.value?.sessionEndTime
                                      ? ''
                                      : 'text-danger'
                                  }
                                >
                                  End Time
                                </span>
                              </label>
                              <Field
                                type="date"
                                name="sessionEndTime"
                                disabled
                                dateFormat="h:mm aa"
                                showTimeSelect
                                className="bg-light border-0 rounded-0"
                                value={editScheduleSignal.value?.sessionEndTime}
                                inputClassName="bg-transparent border-0 font-josefin fs-5 text-primary"
                              />
                            </Col>
                          </Row>
                          <Row className="mt-24">
                            <div className="d-flex justify-content-center">
                              <Button
                                className="btn-secondary mx-8 w-25"
                                onClick={() => {
                                  setEdit([!edit[0], idx]);
                                  editScheduleSignal.reset();
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="btn-primary w-25"
                                onClick={() => {
                                  handleEditScheduleSubmit(session);
                                  editScheduleSignal.reset();
                                  setEdit([!edit[0], idx]);
                                }}
                                disabled={
                                  !editScheduleSignal.value?.sessionDate ||
                                  !editScheduleSignal.value?.sessionStartTime
                                }
                              >
                                Save
                              </Button>
                            </div>
                          </Row>
                        </div>
                      ) : null}
                    </Row>
                  ))
                ) : (
                  <Row className="w-100 py-40 px-48 m-0 border-bottom position-relative">
                    <Col>
                      <h4>
                        This Program does not have any Session Scheduled yet.
                      </h4>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col lg={5} xs={12} className="ps-32 pe-40 pt-64">
                <GlobalCalendar eventsData={events} setEvents={setEvents} />
              </Col>
            </Row>
          </Card.Body>
          <div className="text-center w-100 my-40">
            <Button
              className="btn-outline-secondary py-16"
              onClick={() => history.push('/dashboard')}
            >
              Back to Dashboard
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default SessionCalendar;
