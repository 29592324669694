const companySize = [
  { label: '1-10', value: '1-10' },
  { label: '11-20', value: '11-20' },
  { label: '21-50', value: '21-50' },
  { label: '51-100', value: '51-100' },
  { label: '101-200', value: '101-200' },
  { label: '201-500', value: '201-500' },
  { label: '500+', value: '500+' },
];

export default companySize;
