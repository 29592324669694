const { useState, useEffect } = require('react');

const usePageReloadDetector = () => {
  const [isReloaded, setIsReloaded] = useState(false);

  useEffect(() => {
    const storedIsReloaded = sessionStorage.getItem('isReloaded');

    if (storedIsReloaded === null) {
      sessionStorage.setItem('isReloaded', 'false');
    } else if (storedIsReloaded === 'true') {
      setIsReloaded(true);
    }

    const beforeUnloadHandler = () => {
      sessionStorage.setItem('isReloaded', 'true');
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      sessionStorage.removeItem('isReloaded');
      setIsReloaded(false);
    };
  }, []);

  return isReloaded;
};

export default usePageReloadDetector;
