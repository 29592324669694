import { useLayoutEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  // createOrStartProgram,
  // programIdSignal,
  programsSignal,
} from '../Dashboard.helpers';

const AlertBar = ({ modal }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  }, []);
  /*
  const handleStartProgram = async () => {
      await createOrStartProgram();

      if (programIdSignal.value.id) {
        history.push(`/sessions/${programIdSignal.value.id}`);
      }
    };
   */

  return (
    <Card
      className="position-relative d-flex flex-row align-items-center justify-content-center w-100 bg-secondary text-primary border-0 border-bottom border-4 border-secondary py-16 px-0 m-0 rounded-0"
      style={{ zIndex: modal === 'AlertsModal' ? 2000 : 0 }}
    >
      {programsSignal.value._id ? (
        <Button
          variant="primary"
          className="rounded-0 text-secondary-light px-24 py-8 lead-600"
          onClick={() =>
            history.push(`/sessions?programId=${programsSignal.value._id}`)
          }
          disabled={loading}
        >
          {loading ? 'Loading Data...' : 'Resume Active Program'}
          {loading ? null : (
            <FontAwesomeIcon
              icon={faPlay}
              className="text-secondary"
              style={{ marginLeft: 24 }}
            />
          )}
        </Button>
      ) : (
        <Button
          variant="primary"
          className="rounded-0 text-secondary-light px-24 py-8 lead-600"
          onClick={() => {
            history.push('/packages');
          }}
          disabled={loading}
        >
          {loading ? 'Loading Data...' : 'Purchase Part One'}
          {loading ? null : (
            <FontAwesomeIcon
              icon={faPlay}
              className="text-secondary"
              style={{ marginLeft: 24 }}
            />
          )}
        </Button>
      )}
    </Card>
  );
};

export default AlertBar;
