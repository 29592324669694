import { Form } from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';

const Country = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
}) => (
  <div className={className}>
    {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
    <CountryDropdown
      value={value}
      onChange={(updatedValue) => { onChange({ name, value: updatedValue }); }}
      classes={inputClassName}
    />
  </div>
);

export default Country;
