import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import signupSignal from 'signals/Signup.signal';
import Field from '../../../global/Field/Field';
import { handleFormChange, handleSubmit } from '../Signup.helpers';
// import EmailConfirmationModal from '../../../global/EmailConfirmationModal';
import MismatchedPasswordsModal from './MismatchedPasswordsModal';

const CreatePassword = ({ isAgency, onSubmit }) => {
  const signal = signupSignal.value;
  // const [showModal, setShowModal] = useState(false);
  const [showMismatchedPasswordModal, setShowMismatchedPasswordModal] =
    useState(false);
  // const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [termsAgreement, setTermsAgreement] = useState(false);

  const history = useHistory();

  /*
  const confirmEmail = () => {
    setEmailConfirmed(true);
  };

  const handleCloseModal = () => {
    onSubmit('GetToKnowYou');
    setShowModal(false);
  };
  */

  const handleSubmitPassword = async () => {
    if (signal.password !== signal.confirmPassword) {
      setShowMismatchedPasswordModal(true);
    } else {
      handleFormChange({ name: 'hasCompletedTutorial', value: true });
      const submit = await handleSubmit({ isAgency });

      if (submit) {
        // setShowModal(true);
        if (isAgency) {
          window.localStorage.setItem(
            'creds',
            JSON.stringify({ isAgency: true, userType: 'Practitioner' }),
          );
        }
        history.push('/dashboard');
      } else {
        onSubmit('PersonalInfo');
      }
    }
  };

  const handleTermsAgreementClick = () => {
    setTermsAgreement(!termsAgreement);
  };

  return (
    <>
      <div className="pt-lg-64 pt-48 text-center text-primary">
        <p className="mb-0 fs-3">Create a Password</p>
      </div>
      <Field
        type="password-input"
        name="password"
        value={signal.password}
        label="Password"
        className="pt-lg-40 pt-32 text-primary"
        labelClassName="fw-bold"
        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
        onChange={handleFormChange}
      />
      <Field
        type="password-input"
        name="confirmPassword"
        value={signal.confirmPassword}
        label="Confirm Password"
        className="pt-32 text-primary"
        labelClassName="fw-bold"
        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
        onChange={handleFormChange}
      />
      <div className="d-flex justify-content-center align-items-center mt-40">
        <Field
          type="checkbox"
          labelClassName="d-none"
          className="justify-content-center"
          value={termsAgreement}
          onChange={handleTermsAgreementClick}
        />
        <p className="mb-0">
          I agree to these{' '}
          <a
            href="https://brandtrifecta.com/terms-conditions/"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#0000FF' }}
          >
            terms and conditions
          </a>
        </p>
      </div>
      <div className="w-100 px-32 mt-40">
        <Button
          className="w-100 btn-outline-secondary rounded-0 py-16"
          disabled={
            !termsAgreement || !signal.password || !signal.confirmPassword
          }
          onClick={() => handleSubmitPassword()}
        >
          Submit
        </Button>
      </div>
      {/*
      <EmailConfirmationModal
        show={showModal}
        setShow={setShowModal}
        isEmailConfirmed={emailConfirmed}
        confirmEmail={confirmEmail}
        closeModal={handleCloseModal}
      />
       */}
      <MismatchedPasswordsModal
        show={showMismatchedPasswordModal}
        setShow={setShowMismatchedPasswordModal}
        closeModal={() => setShowMismatchedPasswordModal(false)}
      />
    </>
  );
};

export default CreatePassword;
