import { Card, Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const PlaybooksAndResourcesCard = ({ modal }) => {
  const history = useHistory();

  const handleViewAllDocumentsClick = () => {
    history.push('/resources');
  };

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 font-josefin"
      style={{ zIndex: modal === 'DocumentsModal' ? 2000 : 0 }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">DOCUMENTS AND RESOURCES</h4>
      </Card.Header>
      <Card.Body className="text-wrap p-0">
        <div className="px-112 py-56">
          <Image src="/images/open-folder-secondary.svg" className="w-100" />
        </div>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleViewAllDocumentsClick}
        >
          View Full Library
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default PlaybooksAndResourcesCard;
