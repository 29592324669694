import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import Field from 'components/global/Field/';
import settingsSignal from 'signals/Settings.signal';
import {
  handleFormChange,
  handleAddContributor,
  handleCancelAddContributor,
} from 'components/views/Settings/Settings.helpers';

const practitionerOption = [
  {
    label: 'Practitioner',
    value: 'Practitioner',
  },
  {
    label: 'Client',
    value: 'Client',
  },
];

const regularOption = [
  {
    label: 'Owner',
    value: 'Owner',
  },
];

const AddTeamMember = () => {
  const creds = JSON.parse(window.localStorage.getItem('creds'));

  return (
    <div
      className="position-relative border-bottom border-secondary py-32 ps-32"
      style={{
        direction: 'ltr',
      }}
    >
      <Row className="m-0">
        <Col xs={7} className="ps-0 pe-8">
          <Field
            type="text-input"
            name="newName"
            value={settingsSignal.value.newName}
            placeholder="Full Name"
            className=""
            inputClassName=""
            onChange={handleFormChange}
          />
        </Col>
        <Col xs={4} className="ps-8 pe-0">
          <Field
            type="select"
            name="newRole"
            value={settingsSignal.value.newRole}
            placeholder="Role"
            inputClassName="border-0 bg-gray-100 rounded-2"
            dropDownClassName="border-0 bg-gray-100 rounded-2"
            onChange={handleFormChange}
            options={
              creds?.userType === 'Practitioner'
                ? practitionerOption
                : regularOption
            }
          />
        </Col>
      </Row>
      <Col xs={11} className="p-0 mt-16">
        <Field
          type="text-input"
          name="newEmail"
          value={settingsSignal.value.newEmail}
          placeholder="Email"
          className=""
          inputClassName=""
          onChange={handleFormChange}
        />
      </Col>
      <Col xs={11} className="d-flex justify-content-end p-0">
        <Button
          className="bg-transparent text-primary border-0"
          onClick={() => handleAddContributor()}
          disabled={
            !settingsSignal.value.newEmail ||
            !settingsSignal.value.newRole ||
            !settingsSignal.value.newName
          }
        >
          <FontAwesomeIcon icon={faPlus} className="text-primary" /> Add
        </Button>
        <Button
          className="bg-transparent text-primary border-0"
          onClick={() => handleCancelAddContributor()}
        >
          <FontAwesomeIcon icon={faX} className="ms-16 text-primary" /> Cancel
        </Button>
      </Col>
    </div>
  );
};

export default AddTeamMember;
