import { allProgramsSignal, dashboardDataSignal, programsSignal } from 'components/views/Dashboard/Dashboard.helpers';
import { programSignal } from 'components/views/Sessions/Session.helpers';
import userSignal from 'signals/User.Signal';

const resetAllSignalsOnSignout = () => {
  userSignal.reset();
  allProgramsSignal.reset();
  programSignal.reset();
  programsSignal.reset();
  dashboardDataSignal.reset();
};

export default resetAllSignalsOnSignout;
