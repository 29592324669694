import { Form } from 'react-bootstrap';
import { RegionDropdown } from 'react-country-region-selector';

const State = ({
  label,
  countryValue,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  name,
}) => (
  <div className={className}>
    {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
    <RegionDropdown
      country={countryValue}
      value={value}
      onChange={(newVal) => onChange({ name, value: newVal })}
      classes={inputClassName}
    />
  </div>
);

export default State;
