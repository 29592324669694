import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const AlertsModal = ({ show, onBack, onNext }) => (
  <Modal
    show={show === 'AlertsModal'}
    centered
    backdrop="static"
    onHide={onNext}
    className="rounded-0"
    contentClassName="rounded-0"
    dialogClassName="alerts-modal-position"
    size="md"
  >
    <Modal.Header
      className="d-flex flex-column text-center border-0 mb-0 text-center font-josefin bg-primary text-secondary pb-16 pt-8 rounded-0"
    >
      <Button variant="white" className="bg-transparent align-self-end border-0 text-secondary fw-bold fs-3 pb-0">
        <FontAwesomeIcon icon={faXmark} className="text-secondary" />
      </Button>
      <p className="fw-semibold fs-3 mb-8">ALERTS</p>
    </Modal.Header>
    <Modal.Body className="rounded-0 px-72 pt-32">
      <p className="mb-32 fs-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, lectus pellentesque adipiscing nullam amet diam.</p>
      <div className="d-flex flex-row justify-content-center w-100">
        <Button
          className="d-flex align-items-center border-0 bg-secondary text-primary rounded-0 px-40 py-8 me-8"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-primary ps-e" />
          <p className="mb-0 ps-4">Back</p>
        </Button>
        <Button
          className="d-flex align-items-center border-0 bg-primary text-secondary rounded-0 px-40 py-8 ms-8"
          onClick={onNext}
        >
          <p className="mb-0 pe-4">Next</p>
          <FontAwesomeIcon icon={faArrowRight} className="text-secondary ps-4" />
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default AlertsModal;
