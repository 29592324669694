/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-wrap-multilines */
import { Button, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState, useEffect } from 'react';
import Field from 'components/global/Field/Field';
import schedulingSignal from 'signals/Scheduling.signal';
// import { programsSignal } from 'components/views/Dashboard/Dashboard.helpers';
import AddAttendeeModal from './AddAttendeeModal';
import { handleFormChange } from '../Scheduling.helpers';

const ScheduleSession1 = ({
  onSubmit,
  show,
  toggleShow,
  onChangeAttendee,
  onSubmitNewAttendee,
  onToggleIsEmailSent,
  unscheduledSession,
  handleInviteAll,
  allPrograms,
  setProgramDocId,
  nameOfProgram,
}) => {
  const [programs, setPrograms] = useState([{ label: '', value: '' }]);
  const signal = schedulingSignal.value;
  const history = useHistory();

  useEffect(() => {
    setPrograms(() =>
      allPrograms.map((program) => ({
        label:
          program.name === 'Part One'
            ? program.name
            : program.name === 'Single Trifecta'
            ? 'Part Two - One Brand Trifecta'
            : `Part Two - ${program.name.split(' ')[0]} Brand ${
                program.name.split(' ')[1]
              }`,
        value: program._id,
      })),
    );
  }, [allPrograms.length]);

  const handleProgramIdChange = ({ value }) => {
    setProgramDocId(value);
  };

  return (
    <div className="d-flex flex-column align-items-center mt-64">
      <h2 className="mb-24 fw-bold text-primary">
        SESSION {unscheduledSession[0]?.uid?.split('-')[1] || 1} -{' '}
        {nameOfProgram === 'Part One'
          ? nameOfProgram
          : `Part Two - ${
              nameOfProgram === 'Single Trifecta'
                ? 'One Brand Trifecta'
                : `${nameOfProgram.split(' ')[0]} Brand ${
                    nameOfProgram.split(' ')[1]
                  }`
            }`}
      </h2>
      <div className="w-100 text-start">
        <Row>
          <Col lg={8}>
            <p className="fs-5 mb-32">Schedule Session</p>
          </Col>
          <Col lg={4}>
            <Field
              type="select"
              name="program_id"
              value=""
              placeholder="Choose Program"
              inputClassName="border-0 bg-gray-100 rounded-2"
              dropDownClassName="border-0 bg-gray-100 rounded-2"
              onChange={handleProgramIdChange}
              options={programs}
            />
          </Col>
        </Row>
        <div className="d-flex flex-row align-items-center justify-content-between mb-80">
          <div className="d-flex flex-row">
            <Image src="/images/calendar-primary.svg" className="me-32" />
            <Field
              label={
                <span className={signal?.sessionDate ? '' : 'text-danger'}>
                  {signal?.sessionDate
                    ? 'Session Date'
                    : 'Please Select Session Date *'}
                </span>
              }
              type="date"
              name="sessionDate"
              value={signal?.sessionDate}
              dateFormat="MMMM d, yyyy"
              inputClassName="bg-transparent border-0 font-josefin fs-5 text-primary"
              onChange={handleFormChange}
            />
          </div>
          <div className="d-flex flex-row">
            <Field
              label={
                <span className={signal?.sessionEndTime ? '' : 'text-danger'}>
                  {signal?.sessionEndTime
                    ? 'Start Time'
                    : 'Please Select Start Time *'}
                </span>
              }
              type="date"
              name="sessionStartTime"
              value={signal?.sessionStartTime}
              dateFormat="h:mm aa"
              showTimeSelect
              showTimeSelectOnly
              inputClassName="bg-transparent border-0 font-josefin fs-5 text-primary"
              onChange={handleFormChange}
            />
            <div hidden={!signal?.sessionEndTime}>
              <Field
                label="End Time"
                type="date"
                disabled
                name="sessionEndTime"
                value={signal?.sessionEndTime}
                dateFormat="h:mm aa"
                showTimeSelect
                showTimeSelectOnly
                inputClassName="bg-transparent border-0 font-josefin fs-5 text-primary"
              />
            </div>
          </div>
        </div>
        {signal.teamMembers && signal.teamMembers.length ? (
          <Row>
            <Col lg={{ offset: 9 }}>
              <Button
                style={{ marginBottom: '30px' }}
                className="btn-outline-secondary"
                onClick={handleInviteAll}
              >
                Send Invite to All
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col lg={5}>
            <p className="fs-5">Attendees</p>
          </Col>
          <Col lg={2}>
            <p className="fs-5">Role</p>
          </Col>
          <Col lg={2}>
            <p className="fs-5">Invited</p>
          </Col>
          <Col lg={3}>
            <p className="fs-5">Action</p>
          </Col>
        </Row>
        <div className="w-100 bg-dark mb-16" style={{ height: '1px' }} />
        {signal.teamMembers &&
          schedulingSignal.value.teamMembers.map((member, idx) => (
            <Row key={idx} className="mb-16">
              <Col lg={5}>
                <p className="fw-bold mb-0">
                  {member.firstName} {member.lastName}
                </p>
              </Col>
              <Col lg={2}>
                <p className="fw-bold mb-0">{member.role}</p>
              </Col>
              <Col lg={2}>
                <label className="checkbox-container">
                  <input
                    checked={member.isEmailSent}
                    disabled
                    type="checkbox"
                    className="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </Col>
              <Col lg={3}>
                <Button
                  style={{ marginLeft: '10px' }}
                  disabled={member.isEmailSent}
                  className="bg-secondary text-primary rounded-0 border-0"
                  onClick={() => onToggleIsEmailSent(idx)}
                >
                  Send Invitation
                </Button>
              </Col>
            </Row>
          ))}
        <Button
          className="bg-transparent text-primary rounded-0 border-0 fw-bold ps-0 py-8"
          onClick={() => toggleShow()}
        >
          Add External Attendee +
        </Button>
      </div>
      <Row className="justify-content-center mx-0 px-0 mt-80">
        <Col lg={5} className="ms-0 me-16 px-0">
          <Button
            className="w-100 d-flex text-nowrap flex-row align-items-center justify-content-center bg-secondary text-primary rounded-0 border-0 px-40 py-16"
            onClick={() => history.push('/dashboard')}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-primary me-8" />
            Back to Dashboard
          </Button>
        </Col>
        <Col lg={6} className="ms-16 me-0 px-0">
          <Button
            className="w-100 d-flex flex-row align-items-center justify-content-center bg-primary text-secondary rounded-0 border-0 px-40 py-16"
            onClick={() => onSubmit('ScheduleSession1')}
            disabled={
              !signal.sessionDate ||
              !signal.sessionStartTime ||
              !signal.sessionEndTime ||
              unscheduledSession.length === 0
            }
          >
            {unscheduledSession.length === 0
              ? 'Program Scheduled'
              : `Schedule Session ${
                  unscheduledSession[0]?.uid?.split('-')[1] || 1
                }`}
            <FontAwesomeIcon
              icon={faArrowRight}
              className="text-secondary ms-8"
            />
          </Button>
        </Col>
      </Row>
      <AddAttendeeModal
        show={show}
        toggleShow={toggleShow}
        onChangeAttendee={onChangeAttendee}
        onSubmitNewAttendee={onSubmitNewAttendee}
      />
    </div>
  );
};

export default ScheduleSession1;
