import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';

const TopTaglineReview = ({ prompt }) => {
  const [values, setValues] = useState({});
  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (savedValues) {
      setValues(savedValues);
    }
  }, []);

  return (
    <div className="border border-dark rounded-0 mb-24 mt-16">
      <Table bordered hover>
        <thead>
          <tr>
            <th className="text-uppercase">Top Taglines</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(values).map((value, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'left' }} key={value}>
                {index + 1}. {values[`${index}`]}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TopTaglineReview;
