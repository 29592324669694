import { Card, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const OrderConfirmation = () => {
  const history = useHistory();
  return (
    <div
      className="position-relative text-primary w-100 px-408 pb-64 pt-136"
      style={{ width: '100vw', minHeight: '100vh', backgroundImage: 'url(/images/bg-squares.png)' }}
    >
      <Card className="text-center w-100 rounded-0 bg-light py-80 px-104">
        <div className="d-flex justify-content-center mb-40">
          <Image src="/images/bt-main.png" className="" style={{ objectFit: 'contain', maxWidth: '200px' }} />
        </div>
        <h3 className="mb-8" style={{ fontWeight: 600 }}>Thank you for your purchase.</h3>
        <p>You will receive an email confirmation shortly</p>
        <div className="bg-secondary mx-344 mb-24" style={{ height: '1px' }} />
        <p className="mb-0">Note about course value and how great it</p>
        <p className="mb-56">is they have purchased the program</p>
        <div className="w-100 d-flex justify-content-center">
          <Button
            className="d-flex flex-row align-items-center justify-content-center bg-primary text-secondary fw-bold rounded-0 border-0 px-40 py-16"
            onClick={() => history.push('/scheduling')}
          >
            What&apos;s Next
            <FontAwesomeIcon icon={faArrowRight} className="text-secondary" />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default OrderConfirmation;
