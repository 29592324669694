const { default: Fetcher } = require('libs/fetcher');

const fetcher = new Fetcher();

const getAllPrograms = async (token) => {
  const results = await fetcher.get({
    path: '/airtable/programs',
    query: { airtable: 'true' },
    headers: { Authorization: token },
  });

  return results;
};

export default getAllPrograms;
