/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { FormControl } from 'react-bootstrap';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';
import MultiColumnSignal from 'signals/MultiColumnSignal';
import { handleMultiColumnTableInputs } from '../MultiColumn.helpers';

const rows = new Array(5).fill(0);
const MultiColumnTable = ({ prompt }) => {
  const [defaultValues, setDefaultValues] = React.useState({
    words: [],
    sentences: [],
  });

  MultiColumnSignal.reset();

  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (!savedValues || savedValues === undefined) {
      MultiColumnSignal.update({
        words: [],
        sentences: [],
      });
    }
    if (savedValues) {
      MultiColumnSignal.update({
        ...savedValues,
      });
      setDefaultValues(savedValues);
    } else {
      setDefaultValues({
        words: [],
        sentences: [],
      });
      MultiColumnSignal.update({
        words: [],
        senteces: [],
      });
    }
  }, [prompt]);

  return (
    <div className="d-flex flex-column">
      <Table responsive bordered>
        <thead>
          <tr>
            <th style={{ backgroundColor: 'rgba(98, 104, 115, 0.60)' }}>
              Word/Phrase
            </th>
            <th style={{ backgroundColor: 'rgba(98, 104, 115, 0.60)' }}>
              Supporting Sentence
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex justify-content-center">
                  {index + 1}.
                  <FormControl
                    className="border-0 mx-8 d-flex text-wrap"
                    placeholder="Begin Typing"
                    name={`words_${index}`}
                    defaultValue={defaultValues?.words?.[index]}
                    as="textarea"
                    rows={2}
                    onBlur={(e) => {
                      handleMultiColumnTableInputs(e, prompt, defaultValues);
                    }}
                  />
                </div>
              </td>
              <td className="text-wrap">
                <FormControl
                  className="border-0 text-wrap"
                  placeholder="Begin Typing"
                  name={`sentences_${index}`}
                  defaultValue={defaultValues?.sentences?.[index]}
                  as="textarea"
                  rows={2}
                  onBlur={(e) => {
                    handleMultiColumnTableInputs(e, prompt, defaultValues);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MultiColumnTable;
