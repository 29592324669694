import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { aWordFromKateSignal, handleQuote } from '../Dashboard.helpers';

const AWordFromKate = ({ modal }) => {
  useEffect(() => {
    handleQuote();
  }, []);

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 p-0 font-josefin"
      style={{ zIndex: modal === 'SnipetModal' ? 2000 : 0 }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-3 ps-8">A WORD FROM KATE</h4>
      </Card.Header>
      <Card.Body className="text-wrap px-32 py-16">
        <div className="w-100">
          <FontAwesomeIcon
            icon={faQuoteLeft}
            className="text-primary"
            style={{ height: '2rem', width: '1.5rem' }}
          />
        </div>
        <div
          style={{ fontFamily: 'Josefin Sans, sans-serif' }}
          className="fs-5 px-8 mb-0"
        >{aWordFromKateSignal.value.quote}
        </div>
        <div className="w-100 d-flex justify-content-end mb-32">
          <FontAwesomeIcon
            icon={faQuoteRight}
            className="text-primary"
            style={{ height: '2rem', width: '1.5rem' }}
          />
        </div>
        <p className="italic lead-400 mb-0 fs-5">- Muting the Megaphone</p>
        {/* <p className="base-bold fs-6 mb-64">pg 28</p> */}
      </Card.Body>
    </Card>
  );
};

export default AWordFromKate;
