/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { agencyPurchasesListSignal } from 'components/views/Agency/Purchases/AgencyPurchasesList.Helpers';
import formatMoney from 'utils/formatMoney';
import Loader from 'components/global/Loader';

const AgencyPurchases = ({ modal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const handleViewOptionsClick = () => {
    history.push('/agency/billing');
  };

  useEffect(() => {
    if (Object.keys(purchases).length > 0) {
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, [4000]);
    }
  }, []);

  const purchases = agencyPurchasesListSignal.value;

  const purchasesToShow = purchases?.slice(0, 5);

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 mb-32 font-josefin"
      style={{
        zIndex: modal === 'ExploreModal' ? 2000 : 0,
        minHeight: '600px',
      }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">PURCHASES</h4>
      </Card.Header>
      <Card.Body className="text-wrap px-0 py-0">
        {isLoading ? (
          <Row
            className="justify-content-center align-items-center"
            style={{ height: '50%' }}
          >
            <Loader className="text-center" />
          </Row>
        ) : purchasesToShow?.length > 0 ? (
          purchasesToShow?.map((purchase) => (
            <Row
              key={purchase.id}
              className="py-32 ps-32 m-0 px-0 border-top border-2 border-light-purple"
            >
              <Col xs={8} className="m-0 p-0">
                <p className="lead-400 fs-5 mb-8">
                  {`${purchase.orgName} - Inv. ${
                    purchase.metadata?.airTableId === 'part-one'
                      ? 'Part One'
                      : 'Part Two'
                  } - ${formatMoney(
                    purchase.webhook?.webhookData?.data?.object?.amount / 100,
                  )}`}
                </p>
                <Button className="d-flex align-items-center bg-transparent border-0 text-primary p-0">
                  <p
                    onClick={handleViewOptionsClick}
                    className="base fs-6 mb-0 pe-8"
                  >
                    View
                  </p>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </Col>
            </Row>
          ))
        ) : (
          <Row className="py-32 m-0 ps-32 pe-0">
            <Col xs={8} className="m-0 p-0">
              <p className="lead-400 fs-5 mb-8">
                Currently, you don&apos;t have purchases to show
              </p>
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleViewOptionsClick}
        >
          View All Invoices
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default AgencyPurchases;
