import { Form, ButtonGroup, Button } from 'react-bootstrap';

const YesNo = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  selectedVariant = 'dark',
  unselectedVariant = 'outline-dark',
}) => {
  const handleChange = (newVal) => {
    onChange({ name, value: newVal });
  };
  return (
    <div className={className}>
      {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
      <Form.Group>
        <ButtonGroup aria-label="Basic example">
          <Button
            role="button"
            variant={value ? selectedVariant : unselectedVariant}
            onClick={() => handleChange(true)}
            className={`${inputClassName}`}
          >
            Yes
          </Button>
          <Button
            role="button"
            variant={!value ? selectedVariant : unselectedVariant}
            onClick={() => handleChange(false)}
            className={`${inputClassName}`}
          >
            No
          </Button>
        </ButtonGroup>
      </Form.Group>
    </div>
  );
};
export default YesNo;
