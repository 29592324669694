import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { apiFetch } from 'api/api';
// import userSignal from 'signals/User.Signal';
import schedulingSignal from 'signals/Scheduling.signal';
import { monthNames } from 'components/views/Calendar/SessionCalendar';
import { fetchAndSetProgramData, programsSignal } from '../Dashboard.helpers';
import { getCourseScheduleFromProgramsSignal } from './courseCalendar.helpers';

const CourseCalendar = ({ modal }) => {
  const [event, setEvent] = useState([]);
  const history = useHistory();

  const handleViewCalendarClick = () => {
    history.push('/calendar');
  };

  const handleScheduleCalendarView = () => {
    history.push('/scheduling');
  };

  useEffect(async () => {
    // const today = new Date();
    await fetchAndSetProgramData();
    const events = getCourseScheduleFromProgramsSignal();
    if (events?.length > 0) {
      // const filteredEvents = events.filter((evnt) => {
      //   const eventDate = new Date(evnt.date);
      //   return eventDate >= today;
      // });
      const sortedFilteredEvents = events.sort((a, b) => a.date - b.date);
      setEvent([sortedFilteredEvents[0]]);
    }
  }, []);
  console.log({ event });

  return (
    <Card
      className="w-100 text-primary rounded-0 border-0 p-0 font-josefin"
      style={{
        minHeight: '25.70rem',
        zIndex: modal === 'ScheduleModal' ? 2000 : 0,
      }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">COURSE CALENDAR</h4>
      </Card.Header>
      <Card.Body className="text-wrap p-0">
        <Row className="py-8 m-0 px-0">
          <Col xs={12}>
            <p className="base text-secondary">Next Session</p>
          </Col>
          <Col
            xs={3}
            className="d-flex flex-column align-items-center m-0 px-8 py-0"
          >
            {event?.length > 0 ? (
              <>
                <h1 className="mb-0 fw-bold">
                  {event ? new Date(event[0]?.date).getDate() : ''}
                </h1>
                <p className="mb-0 fs-4">
                  {event ? monthNames[new Date(event[0]?.date).getMonth()] : ''}
                </p>
              </>
            ) : null}
          </Col>
          <Col xs={9} className="m-0 ps-0 pe-16">
            {event?.length > 0 ? (
              <div className="d-flex flex-row justify-content-between align-items-center  mb-16">
                <div className="d-flex flex-column">
                  <p className="lead-400 mb-16">{event[0]?.description}</p>
                  <p className="base mb-8">
                    {new Date(event[0]?.date).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                  <p className="base-bold mb-0">{`Session ${event[0]?.sessionIdx}`}</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center bg-primary rounded-circle p-8"
                  style={{ height: '2.25rem', width: '2.25rem' }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-light h-100"
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push('/calendar')}
                  />
                </div>
              </div>
            ) : (
              <p className="mb-0">
                {schedulingSignal.value?.userSchedule ||
                  'No upcoming sessions...'}
              </p>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary w-100"
          onClick={handleViewCalendarClick}
          disabled={Object.keys(programsSignal?.value).length === 0}
          hidden={Object.keys(programsSignal?.value).length === 0}
        >
          View All
        </Button>
        {programsSignal.value._id ? (
          <Button
            style={{ marginLeft: '10px' }}
            className="btn-outline-secondary w-100"
            onClick={handleScheduleCalendarView}
          >
            Schedule
          </Button>
        ) : (
          <Button
            style={{ marginLeft: '10px' }}
            className="btn-outline-secondary w-100"
            onClick={() => history.push('/packages')}
          >
            Purchase Program
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default CourseCalendar;
