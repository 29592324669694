import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import loaderSignal from 'signals/Loader.signal';
import userSignal from 'signals/User.Signal';
import { fetchAndSetProgramData, getDashboardData } from './Dashboard.helpers';
import DashboardFooter from './other-components/DashboardFooter';
import RegularDashboard from './dashboard-components/RegularDashboard';
import AgencyDashboard from './agency-dashboard/AgencyDashboard';
import { getAgencyPurchasesList } from '../Agency/Purchases/AgencyPurchasesList.Helpers';
import { getClientsData } from '../Agency/Clients/clients.helpers';
/*
import signupSignal from 'signals/Signup.signal';
import ProgressModal from './modal-components/ProgressModal';
import ExploreModal from './modal-components/ExploreModal';
import ScheduleModal from './modal-components/ScheduleModal';
import VideoRecapModal from './modal-components/VideoRecapModal';
import AlertsModal from './modal-components/AlertsModal';
import ControlPanelModal from './modal-components/ControlPanelModal';
import LetsGetYouStartedModal from './modal-components/LetsGetYouStartedModal';
import DocumentsModal from './modal-components/DocumentsModal';
import SnipetModal from './modal-components/SnipetModal';
 */

const Dashboard = (props) => {
  const [modal] = useState('');
  const history = useHistory();
  const { userData } = userSignal.value;
  const { isAgency } = props;
  const userTypeData = JSON.parse(window.localStorage.getItem('creds'));

  const init = useCallback(async () => {
    const promises = [
      fetchAndSetProgramData(),
      getDashboardData({ isAgency, userType: userTypeData?.userType }),
    ];
    if (isAgency) {
      promises.push([
        getAgencyPurchasesList({ orgData: userData?.orgData }),
        getClientsData({ orgData: userData.orgData }),
      ]);
    }

    await Promise.all(promises);
  });

  useEffect(() => {
    if (userTypeData) {
      init();
    }
  }, [userTypeData]);

  if (isAgency === undefined) {
    loaderSignal.update({
      isContentLoading: true,
    });
  } else {
    loaderSignal.reset();
  }

  useEffect(() => {
    if (userData.User_Organization.length === 0 || userData === null) {
      history.push('/onBoarding');
    }
  }, [userData]);

  /* tutoreal handler
  const handleCompleteTutorial = ({ isPurchasing }) => {
    signupSignal.update({
      hasCompletedTutorial: true,
    });
    if (isPurchasing) {
      history.push('/select-plan');
    }
    setModal(null);
  };

  useEffect(() => {
    if (signupSignal.value.hasCompletedTutorial === false && modal === '') {
      setModal('WelcomeModal');
    }
    if (
      modal === 'ScheduleModal' ||
      modal === 'AlertsModal' ||
      modal === 'ProgressModal'
      ) {
      window.scrollTo(0, 0);
    }
    if (modal === 'DocumentsModal' || modal === 'SnipetModal') {
      window.scrollTo(0, window.innerHeight - 300);
    }
  }, [modal]);
  */

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      {isAgency && userData?.role?.name === 'Practitioner' ? (
        <AgencyDashboard modal={modal} />
      ) : (
        <RegularDashboard modal={modal} />
      )}
      {/*
      // These are the tutorial modals
      <WelcomeModal show={modal} onNext={() => setModal('ProgressModal')} />

      <ProgressModal
        show={modal}
        onBack={() => setModal('WelcomeModal')}
        onNext={() => setModal('ExploreModal')}
      />
      <ExploreModal
        show={modal}
        onBack={() => setModal('ProgressModal')}
        onNext={() => setModal('ScheduleModal')}
      />
      <ScheduleModal
        show={modal}
        onBack={() => setModal('ExploreModal')}
        onNext={() => setModal('DocumentsModal')}
      />
      <DocumentsModal
        show={modal}
        onBack={() => setModal('ScheduleModal')}
        onNext={() => setModal('VideoRecapModal')}
      />
      <VideoRecapModal
        show={modal}
        onBack={() => setModal('DocumentsModal')}
        onNext={() => setModal('SnipetModal')}
      />
      <SnipetModal
        show={modal}
        onBack={() => setModal('VideoRecapModal')}
        onNext={() => setModal('AlertsModal')}
      />
      <AlertsModal
        show={modal}
        onBack={() => setModal('SnipetModal')}
        onNext={() => setModal('ControlPanelModal')}
      />
      <ControlPanelModal
        show={modal}
        onBack={() => setModal('AlertsModal')}
        onNext={() => setModal('LetsGetYouStartedModal')}
      />
      <LetsGetYouStartedModal show={modal} onClose={handleCompleteTutorial} />
       */}
      <DashboardFooter />
    </>
  );
};

export default Dashboard;
