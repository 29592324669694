import { useCallback, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import settingsSignal from 'signals/Settings.signal';
import {
  handleFormChange,
  handleInitContributorsData,
  handleSubmitContributor,
} from 'components/views/Settings/Settings.helpers';
import TeamMember from './TeamMember';
import AddTeamMember from './AddTeamMember';

const ManageTeam = () => {
  const bottomRef = useRef(null);

  const init = useCallback(async () => {
    await handleInitContributorsData();
  });

  useEffect(() => {
    init();
  }, []);

  const handleIsAddingContributor = () => {
    handleFormChange({ name: 'isAddingContributor', value: true });
    setTimeout(
      () => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }),
      50,
    );
  };

  return (
    <div className="d-flex flex-column h-100" style={{ background: 'white' }}>
      <div className="d-flex flex-column border border-secondary p-40 h-100">
        <div
          className="d-flex flex-column ps-32"
          style={{
            direction: 'rtl',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          {settingsSignal.value.contributors &&
            settingsSignal.value.contributors.map((member, idx) => (
              <TeamMember key={member.name + idx} member={member} idx={idx} />
            ))}
          {settingsSignal.value.isAddingContributor && <AddTeamMember />}
          <div ref={bottomRef} />
        </div>
        <div className="w-100 mt-16 text-center" style={{ direction: 'ltr' }}>
          <Button
            className="bg-transparent text-primary border-0"
            onClick={() => handleIsAddingContributor()}
          >
            <FontAwesomeIcon icon={faPlus} className="text-primary" />{' '}
            Contributor
          </Button>
          <Button
            variant="secondary"
            className="w-100 bg-primary text-secondary rounded-0 py-xxxl-16 py-8 mt-16"
            onClick={handleSubmitContributor}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageTeam;
