/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  dashboardDataSignal,
  programsSignal,
} from 'components/views/Dashboard/Dashboard.helpers';
import alertSignal from 'signals/Alert.signal';
import userSignal from 'signals/User.Signal';

const ResourcesTab = () => {
  const [downloading, setDownloading] = useState(false);
  const { sessions } = programsSignal.value;
  const newcombinedPrompts = [];
  sessions?.forEach((session) => {
    session?.screens?.forEach((screen) => {
      if (screen?.style === 'prompt') {
        newcombinedPrompts.push(...screen?.prompts);
      }
    });
  });
  const { pdfResources } = dashboardDataSignal.value;

  const handleDataLayerPush = (documentName) => {
    window.dataLayer.push({
      event: 'resourceDownloaded',
      eventSettingsTable: [
        {
          parameter: 'eventCategory',
          parameterValue: 'Resource Downloaded',
        },
        {
          parameter: 'eventAction',
          parameterValue: `Resource Downloaded: ${documentName}`,
        },
        {
          parameter: 'eventLabel',
          parameterValue: userSignal.value?.userData?.email,
        },
      ],
    });
  };

  const handleDownloadAll = async () => {
    setDownloading(true);

    for (const resource of pdfResources) {
      try {
        const url = resource.link;
        if (!url) {
          throw new Error('Invalid URL');
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to download: ${url}`);
        }

        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectURL;
        link.download = resource.link || 'downloaded-file.pdf';
        link.style.display = 'none';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(objectURL);

        handleDataLayerPush(resource.documentName);
      } catch (error) {
        alertSignal.update({
          type: 'alert',
          message: 'Could not download the resources',
          error,
        });
      }
    }

    setDownloading(false);
  };

  const handleSingleDownload = (link, name) => {
    handleDataLayerPush(name);
    window.open(link, '_blank');
  };

  return (
    <div className="py-56">
      <Row className="p-0 m-0 w-100 px-16">
        <Col lg={9} md={8} xs={12} className="p-0 m-0">
          <h4 className="h4 fw-600 text-primary">RESOURCE CENTER</h4>
          <p className="base-400 text-primary-light">
            Download all resources from Part One and Part Two.
          </p>
        </Col>
        <Col
          lg={3}
          md={4}
          xs={12}
          className="text-md-end text-start p-0 my-md-0 my-16"
        />
      </Row>
      <Row className="m-0 w-100 px-16 pt-40">
        <Col lg={9} md={8} xs={12} className="p-0 m-0">
          <p className="lead-600">All Session Resources</p>
        </Col>
        <Col lg={3} md={4} xs={12} className="p-0">
          <Button
            onClick={handleDownloadAll}
            className="base py-8 w-100"
            variant="outline-primary"
            disabled={downloading}
          >
            {downloading ? 'Downloading...' : 'Download All'}
          </Button>
        </Col>
      </Row>
      <Row className="px-16 pt-40">
        {pdfResources?.length <= 0 ? (
          <p className="fw-bold">
            {' '}
            No available resources. Please complete active Sessions to view or
            download Resources
          </p>
        ) : (
          pdfResources?.map((resource) => (
            <Col key={resource?.id} lg={4} md={6} xs={12} className="my-24">
              <Card
                className="border border-primary-light"
                style={{ borderRadius: '5px' }}
              >
                <Card.Body className="px-8 bg-primary-light">
                  <Card.Img
                    className="resourceImage"
                    src={resource.attachment?.thumbnails?.large?.url}
                    style={{ minHeight: '130px' }}
                  />
                </Card.Body>
                <Card.Footer className="bg-white px-32 py-16">
                  <Card.Title className="base fw-bold mb-5 text-truncate">
                    {resource?.documentName}
                  </Card.Title>
                  <Card.Text className="small w-100 d-flex justify-content-end pt-8">
                    <FontAwesomeIcon
                      onClick={() =>
                        handleSingleDownload(
                          resource.link,
                          resource.documentName,
                        )
                      }
                      icon={faDownload}
                      className="text-secondary"
                      role="button"
                    />
                  </Card.Text>
                </Card.Footer>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </div>
  );
};

export default ResourcesTab;
