/* eslint-disable no-nested-ternary */
import { apiFetch } from 'api/api';
import Signal from 'libs/signals';
import alertSignal from 'signals/Alert.signal';
import dashboardSignal from 'signals/Dashboard.signal';
import userSignal from 'signals/User.Signal';
import loaderSignal from 'signals/Loader.signal';
import quotes from '../../../utils/aWordFromKateList';

export const programsSignal = Signal({});
export const allProgramsSignal = Signal([]);
export const programIdSignal = Signal({});
export const aWordFromKateSignal = Signal({});
export const dashboardDataSignal = Signal({});

export async function createOrStartProgram() {
  try {
    const { data } = await apiFetch({
      path: '/programs',
      method: 'POST',
      body: { programId: 'part-one' },
      token: userSignal.value.fbUser.Aa,
    });
    const { id } = data;

    programIdSignal.update({
      id,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'An error occured creating your program',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
}

export async function fetchAndSetProgramData() {
  try {
    const token = userSignal.value.fbUser.Aa;
    const programData = await apiFetch({
      path: '/programs',
      token,
    });
    allProgramsSignal.update(programData.data);
    const nonCompletedPrograms = programData.data.filter(
      (program) => !program.completedProgram,
    );
    if (nonCompletedPrograms.length > 0) {
      programsSignal.update(nonCompletedPrograms[0]);
    } else {
      programsSignal.update(programData.data[0]);
    }
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: 'Unable to fetch programs',
    });
  } finally {
    loaderSignal.reset();
  }
}

export const handleQuote = () => {
  const min = 0;
  const max = quotes.length;

  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  aWordFromKateSignal.update(quotes[randomNumber]);
};

export async function getDashboardData({ isAgency, userType }) {
  try {
    const { data } = await apiFetch({
      path: isAgency ? '/dashboard?isAgency' : userType === 'Client' ? '/dashboard?isClient' : '/dashboard',
      method: 'GET',
      token: userSignal.value.fbUser.Aa,
    });

    dashboardDataSignal.update(data[0]);
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'An error occured creating your program',
      error,
    });
  }
}

export const sendReferralEmail = async (email) => {
  try {
    const data = {
      user: {
        senderFirstName: userSignal.value.userData.firstName,
        senderLastName: userSignal.value.userData.lastName,
        email,
      },
    };
    const response = await apiFetch({
      path: '/email/send-coupon-email',
      method: 'POST',
      body: data,
      token: userSignal.value.fbUser.Aa,
    });
    alertSignal.update({
      type: 'notification',
      message: 'Referral email sent',
      className: 'bg-success',
    });
    return response;
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'An error occured sending your referral email',
      error,
    });
    return error;
  }
};

export default dashboardSignal;
