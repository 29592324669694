import userSignal from 'signals/User.Signal';
import { apiFetch } from 'api/api';
import Signal from 'libs/signals';
import AlertsModal from '../modal-components/AlertsModal';
import { programsSignal } from '../Dashboard.helpers';

export const agencyScheduleSignal = Signal([]);

export const getCourseScheduleFromProgramSignal = (program) => {
  if (program) {
    const { sessions } = program;
    if (sessions) {
      const scheduledEvents = sessions.map((session, index) => {
        if (session?.schedule) {
          return {
            ...session.schedule,
            title: session.title,
            description: session.title,
            scheduleIdx: index,
            sessionIdx: session.number,
          };
        }
        return null;
      });
      const eventsData = scheduledEvents.filter((event) => event !== null);
      return eventsData;
    }
  }

  return [];
};

export const getCourseScheduleFromProgramsSignal = () => {
  const { sessions } = programsSignal.value;
  if (sessions) {
    const scheduledEvents = sessions.map((session, index) => {
      if (session?.schedule) {
        return {
          ...session.schedule,
          title: session.title,
          description: session.title,
          scheduleIdx: index,
          sessionIdx: session.number,
        };
      }
      return null;
    });
    const eventsData = scheduledEvents.filter((event) => event !== null);
    return eventsData;
  }
};

export const getAgencySchedules = async ({ orgData }) => {
  const token = userSignal.value.fbUser.Aa;

  try {
    const agencyEvents = await apiFetch({
      path: '/agency/schedules',
      method: 'POST',
      body: orgData,
      token,
    });

    agencyScheduleSignal.update(agencyEvents.data);
  } catch (error) {
    AlertsModal.update({
      type: 'alert',
      message: 'Could not load the data',
      error,
    });
  }
};

export default {};
