const init = () => {
  const url = document.location.href;
  let env = 'production';

  ['.qa', '-qa', 'qa.', 'qa-'].forEach(domain => {
    if (url.includes(domain)) env = 'qa';
  });

  ['.feature', '-feature', 'feature.', 'feature-'].forEach(domain => {
    if (url.includes(domain)) env = 'feature';
  });

  ['localhost'].forEach(domain => {
    if (url.includes(domain)) env = 'development';
  });

  return env;
};

export default init;
