/* eslint-disable react/jsx-wrap-multilines */
import {
  Button,
  FormControl,
  Row,
  Tab,
  Tabs,
  Form,
  Col,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  getPromptValueFromProgramSignal,
  handlePromptChange,
} from 'components/views/Sessions/Session.helpers';
import { handleWithTabsInputChange } from './repeaterTextInputTable.helpers';
import { repeaterTextInputTableWithTabsSignal } from './repeaterTextInputTableWithTabs.signal';

// The repeaterTextInputTableWithTabsSignal is a dummy signal and should be replaced with
// the signal that holds the sessions data

const RepeaterTextInputTableWithTabs = ({ prompt }) => {
  const forms = new Array(5).fill(0);
  const [rows, setRows] = useState({ 0: [...forms] });
  const [tabs, setTabs] = useState([1]);
  const [tabIdx, setTabIdx] = useState(0);

  const handleAddRow = () => {
    const newRows = { ...rows };
    newRows[tabIdx].push(newRows[tabIdx].length + 1);
    setRows(newRows);
    repeaterTextInputTableWithTabsSignal.update({
      ...repeaterTextInputTableWithTabsSignal.value,
      rows: newRows,
    });
    handlePromptChange({
      value: repeaterTextInputTableWithTabsSignal.value,
      prompt,
    });
  };

  const handleAddTabs = () => {
    const newTabs = [...tabs];
    const newRows = { ...rows };
    newTabs.push(newTabs.length + 1);
    setTabs(newTabs);
    repeaterTextInputTableWithTabsSignal.update({
      ...repeaterTextInputTableWithTabsSignal.value,
      tabs: newTabs,
    });
    handlePromptChange({
      value: repeaterTextInputTableWithTabsSignal.value,
      prompt,
    });
    newRows[newTabs.length - 1] = [...forms];
    setRows(newRows);
  };

  const handleRemoveTabs = () => {
    const newTabs = [...tabs];
    const newRows = { ...rows };
    newTabs.pop();
    setTabs(newTabs);
    repeaterTextInputTableWithTabsSignal.update({
      ...repeaterTextInputTableWithTabsSignal.value,
      tabs: newTabs,
    });
    handlePromptChange({
      value: repeaterTextInputTableWithTabsSignal.value,
      prompt,
    });
    delete newRows[newTabs.length];
    setRows(newRows);
  };

  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    if (!values) {
      return;
    }
    if (values) {
      const arrayOfKeys = Object.keys(values);
      repeaterTextInputTableWithTabsSignal.update(values);
      if (values?.rows) {
        setRows(values?.rows);
      } else if (
        arrayOfKeys.includes(
          'targetAudience0',
          'targetAudience1',
          'targetAudience2',
          'targetAudience3',
          'targetAudience4',
        )
      ) {
        const newRows = {};
        arrayOfKeys.forEach((key) => {
          if (key.includes('targetAudience') && !key.includes('Name')) {
            const tabNum = parseInt(key.split('targetAudience')[1], 10);
            newRows[tabNum] = [...forms];
          }
        });
        setRows(newRows);
      } else {
        setRows({ 0: [...forms] });
      }
      if (values?.tabs) {
        setTabs(values.tabs);
      } else {
        setTabs([1]);
      }
    }
  }, [prompt]);

  return (
    <Tabs
      defaultActiveKey={tabIdx}
      className="border-0 p-0 align-items-center"
      onSelect={(key) => setTabIdx(key)}
    >
      {tabs?.map((tab, index) => (
        <Tab
          eventKey={index}
          title={`Audience ${index + 1}`}
          tabClassName="tab-background-none"
          className="px-40"
        >
          <div className="border border-1 border-primary px-32 py-48">
            <h4 className="text-center mb-32 text-uppercase">
              Buyer criteria for:
            </h4>
            <Row className="px-24">
              <Col className="d-flex justify-content-center" lg={12}>
                <Form.Label className="mx-8 ">{}</Form.Label>
                <FormControl
                  className="border rounded-0 mb-24"
                  name={`targetAudience${index}Name`}
                  defaultValue={
                    repeaterTextInputTableWithTabsSignal.value?.[
                      `targetAudience${index}Name`
                    ] || ''
                  }
                  placeholder="Type your target audience name here..."
                  onBlur={(e) => {
                    handleWithTabsInputChange(e, prompt);
                  }}
                />
              </Col>
            </Row>
            {rows[tabIdx]?.map((form, idx) => (
              <Row className="px-24" key={idx}>
                <Col className="d-flex justify-content-center" lg={12}>
                  <Form.Label className="mx-8">{idx + 1}.</Form.Label>
                  <FormControl
                    key={idx}
                    className="border rounded-0 mb-24"
                    name={`audience${idx}`}
                    defaultValue={
                      repeaterTextInputTableWithTabsSignal.value?.[
                        `targetAudience${index}`
                      ]?.[`audience${idx}`] || ''
                    }
                    placeholder="Begin typing..."
                    onBlur={(e) => {
                      handleWithTabsInputChange(e, prompt, index);
                    }}
                  />
                </Col>
              </Row>
            ))}
            <Col>
              <div style={{ textAlign: 'start' }}>
                <Button
                  className="px-0 mt-8"
                  variant="none"
                  onClick={handleAddRow}
                >
                  <FontAwesomeIcon size="lg" icon={faPlusCircle} /> Add row
                </Button>
              </div>
            </Col>
          </div>
        </Tab>
      ))}
      <Tab
        tabClassName=""
        title={
          <Button variant="none" onClick={handleAddTabs}>
            <FontAwesomeIcon icon={faPlusCircle} /> Add another audience
          </Button>
        }
      />
      <Tab
        tabClassName=""
        title={
          <Button
            variant="none"
            onClick={handleRemoveTabs}
            disabled={tabs.length === 1}
          >
            <FontAwesomeIcon icon={faMinusCircle} />
            {` Remove Audience ${tabs.length}`}
          </Button>
        }
      />
    </Tabs>
  );
};

export default RepeaterTextInputTableWithTabs;
