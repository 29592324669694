/* eslint-disable class-methods-use-this */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import TableTemplateDocument from './PDFTemplates/TableTemplate';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CreatePdfDocument {
  constructor(values, name) {
    this.values = values;
    this.name = name;
    this.docDefinition = {
      content: [],
    };
  }

  createDifferentiatorStatementsPdf() {
    return TableTemplateDocument('differentiator', this.values);
  }

  createTopTargetAudiencePdf() {
    return TableTemplateDocument('toptargetaudience', this.values);
  }

  createFiveWhysPdf() {
    return TableTemplateDocument('fivewhys', this.values);
  }

  createBrandArchetypePdf() {
    return TableTemplateDocument('brandarchetype', this.values);
  }

  createTextPdf() {
    return TableTemplateDocument('textarea', this.values);
  }

  createPersonalityChartPdf() {
    return TableTemplateDocument('personalitychart', this.values);
  }

  createTopTaglinePdf() {
    return TableTemplateDocument('topTagline', this.values);
  }

  createBuyerCriteriaPdf() {
    return TableTemplateDocument('repeaterTextTable', this.values);
  }

  createCombinedPdfPartOne() {
    return TableTemplateDocument('combined', this.values, this.name);
  }

  openInNewTab(pdfDoc) {
    pdfDoc.getDataUrl((dataUrl) => {
      const newTab = window.open();
      newTab.document.write(`<iframe width="100%" height="100%" src="${dataUrl}"></iframe>`);
    });
  }

  downloadCombinedPdf() {
    // pdfMake.createPdf(this.createCombinedPdf()).download('BrandTrifecta.pdf');
    const pdfDoc = pdfMake.createPdf(this.createCombinedPdf());
    this.openInNewTab(pdfDoc);
  }

  downloadFiveWhysPdf() {
    // pdfMake.createPdf(this.createFiveWhysPdf()).download('FiveWhys.pdf');
    const pdfDoc = pdfMake.createPdf(this.createFiveWhysPdf());
    this.openInNewTab(pdfDoc);
  }

  downloadBrandArchetypePdf() {
    // pdfMake.createPdf(this.createBrandArchetypePdf()).download('BrandArchetype.pdf');
    const pdfDoc = pdfMake.createPdf(this.createBrandArchetypePdf());
    this.openInNewTab(pdfDoc);
  }

  downloadTopTargetAudiencePdf() {
    // pdfMake.createPdf(this.createTopTargetAudiencePdf()).download('TopTargetAudience.pdf');
    const pdfDoc = pdfMake.createPdf(this.createTopTargetAudiencePdf());
    this.openInNewTab(pdfDoc);
  }

  downloadTextAreaPdf() {
    // pdfMake.createPdf(this.createTextPdf()).download('TextArea.pdf');
    const pdfDoc = pdfMake.createPdf(this.createTextPdf());
    this.openInNewTab(pdfDoc);
  }

  downloadPersonalityChartPdf() {
    // pdfMake.createPdf(this.createPersonalityChartPdf()).download('PersonalityChart.pdf');
    const pdfDoc = pdfMake.createPdf(this.createPersonalityChartPdf());
    this.openInNewTab(pdfDoc);
  }

  downloadTopTaglinePdf() {
    // pdfMake.createPdf(this.createTopTaglinePdf()).download('TopTagline.pdf');
    const pdfDoc = pdfMake.createPdf(this.createTopTaglinePdf());
    this.openInNewTab(pdfDoc);
  }

  downloadPartOnePdf() {
    // pdfMake.createPdf(this.createCombinedPdfPartOne()).download('BrandTrifecta.pdf');
    const pdfDoc = pdfMake.createPdf(this.createCombinedPdfPartOne());
    this.openInNewTab(pdfDoc);
  }

  downloadBuyerCriteriaPdf() {
    // pdfMake.createPdf(this.createBuyerCriteriaPdf()).download('BuyerCriteria.pdf');
    const pdfDoc = pdfMake.createPdf(this.createBuyerCriteriaPdf());
    this.openInNewTab(pdfDoc);
  }
}
const createSessionPdf = (values, name) => new CreatePdfDocument(values, name);

export default createSessionPdf;
