import TopBar from 'components/global/TopBar';
import { Col, Container, Row } from 'react-bootstrap';

const TermsAndConditions = ({ hideTopBar }) => (
  <>
    <div className="bg-primary w-100 py-24" />
    { hideTopBar ? null : <TopBar />}
    <div
      className="position-relative w-100 px-8 pb-64 pt-80"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
      }}
    >
      <Container>
        <Row>
          <Col className="bg-white border border-secondary border-3 py-40 px-40">
            <h2 className="text-primary mb-32 text-center">Terms and Conditions</h2>
            <p className="base-bold mb-0 text-center">The Brand Trifecta LLC</p>
            <p className="base-bold text-center">Service Terms and Conditions</p>
            <p className="base mb-0">This Agreement is between The Brand Trifecta LLC, a Minnesota Limited Liability Company (“TBT”) and the person or entity agreeing to these terms and conditions (“Client’”) and sets forth the terms and conditions governing Client’s access to and use of TBT service and related support.  By clicking the box on our website, you consented to these terms and conditions.  Additional or different terms in any purchase order confirmation or other communication from Client are void.</p>
            <p className="base mb-0">1.  The Service. TBT’s service allows Clients to identify, develop and refine their brands, or if an agency, their client’s brands, using TBT’s proprietary processes, videos and other content (the “Service”). The Service includes software and program updates as determined to be useful or necessary by TBT or its suppliers.</p>
            <p className="base mb-0">2.  License Grant. TBT grants you a limited license to use the Service to identify, develop and refine your brand, or if an agency, the brands of your clients. You agree not to download our videos or content not designed for download, or use our materials to create a substantially similar product or service. For agencies receiving multiple licenses, TBT hereby grants you the right to provide one instance of the TBT service per license purchased to agency clients only.</p>
            <p className="base mb-0">3.  Fees and Payment. Client agrees to pay the then current fee for the portions of the Service that Client desires to access. Fess are due and payable in advance. Client hereby grants us the right to charge the credit card on file for all Services selected. All amounts due are in United States Dollars. TBT may modify its pricing at any time.  All prices are exclusive of sales, use, excise or other taxes. All payment processing is through TBT’s payment processor, and TBT has no liability for information provided to that payment processor or transmitted through the payment processor’s website links. All fees are non-refundable, unless the process set forth in paragraph 4 below is followed.</p>
            <p className="base mb-0">4.  Refunds.  TBT offers a 30 day money back guarantee to its Clients if they are not satisfied with the Service. To request a refund, Client must contact TBT at <u>help@brandtrifecta.com</u>, submit a copy of their completed playbook from Part One of the program, and complete a survey. The request must be made 30 days from the date Client pays for the service. The refund will be made within 10 business days of receipt of the completed survey.</p>
            <p className="base mb-0">5.  Credentials.  TBT will issue usernames and passwords to Client for Client’s use in accessing the Service. Client represents that all email addresses and registration information provided to TBT are accurate.  It is Client’s sole responsibility to provide TBT written notice of any changes to user information and/or email addresses. Client will ensure that each username and password issued to a Client will be used only by an authorized user of Client.  Client agrees to promptly notify TBT if it becomes aware of any actual or suspected unauthorized use of its account, usernames or passwords, or any other breach or suspected breach of security related to the Service. Client will cooperate with TBT in investigating the same.</p>
            <p className="base mb-0">6.  <u>Personal and Business Information</u>.  We collect some personal information, as well as information about your brand and marketing strategy. We will treat your information according to our Privacy Policy.  We may change our Privacy Policy at any time.</p>
            <p className="base mb-0">7. <u>Client Content</u>.  Our service requires you to submit information and artwork (e.g. logo design).  All material that you upload is, as between TBT and Client, is considered Client content. You agree that you will only upload or otherwise provide content that is yours, or that you have the right to share with others. You grant TBT a non-exclusive, worldwide, perpetual, royalty-free, fully paid up, transferable, sublicensable license to copy, display, transmit, perform, distribute, store, modify, and otherwise use your content in connection with the Service. We may remove your content from our system within 12 months of the date that you first pay for the Service. Please download your completed worksheets, playbook and other content on a regular basis.</p>
            <p className="base mb-0">8. <u>TBT Content and Materials</u>.  All right, title, and interest in the service and content provided by TBT, including but not limited to our logo, processes, text, documentation, graphics, photographs, video and audio files, and data (collectively “TBT Materials”) are TBT’s property or that of TBT’s licensors.</p>
            <p className="base mb-0">9.  <u>Termination</u>.  You may terminate Your use of the Service at any time. The outcomes of the Service are meant to be completed within 90 days of the date you first pay for the Service. Therefore, TBT does not guarantee forever access to the Service and may terminate your use of the Service at any time after the initial 12 months of providing the Service. We may terminate your use of the Services if Client fails to pay fees that are due or violates the terms of this Agreement.</p>
            <p className="base mb-0">10.  <u>Disclaimers and Limitation of Liability</u>.  TBT IS PROVIDING YOU THE SERVICE, ALONG WITH TBT’S CONTENT ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.  WITHOUT LIMITING THE FOREGOING, TBT EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, TITLE, ACCURACY AND COMPLETENESS, UNINTERRUPTED OR ERROR-FREE SERVICE, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND NONINFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR TRADE USAGE.  TBT MAKES NO PROMISES WITH RESPECT TO, AND EXPRESSLY DISCLAIMS ALL LIABILITY FOR ANY THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LISTED ON OR ACCESSIBLE TO YOU THROUGH THE SERVICE  WE MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS, (b) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR (c) THE RESULTS OR INFORMATION THAT YOU MAY OBTAIN FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE.</p>
            <p className="base mb-0">11.  YOU AGREE THAT TO THE MAXIMUM EXTENT PERMITTED BY LAW, TBT WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF LIABILITY. WITHOUT LIMITING THE FOREGOING, YOU AGREE THAT TBT WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, LOSS OF PROFITS, BUSINESS INTERRUPTION, REPUTATIONAL HARM, OR LOSS OF DATA (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES ARE FORESEEABLE) ARISING OUT OF IN ANY WAY CONNECTED WITH YOUR USE OF, OR INABILITY TO USE, THE SERVICE. CLIENT’S SOLE REMEDY FOR DISSATISFACTION WITH THE SERVICE IS TO STOP USING THE SERVICE.  WITHOUT LIMITING THE FOREGOING, TBT’S MAXIMUM AGGREGATE LIABILITY TO CLIENT FOR LOSSES OR DAMAGES IS LIMITED TO THE COST YOU PAID FOR THE SERVICE.</p>
            <p className="base mb-0">12. <u>Indemnification</u>.  You agree to release, indemnify, and defend TBT from all third-party claims and costs (including reasonable attorneys’ fees) arising out of or related to: i) your use of the Service, ii) Your content, or iii) Your breach of any part of this Agreement.  We will promptly notify You of any such claim, and will provide You (at Your expense) with reasonable assistance in defending the claim.  You will allow us to participate in the defense, and will not settle any such claim without our prior written consent. We reserve the right, at our own expense, to assume the exclusive defense of any matter otherwise subject to indemnification by You. In that event, You will have no further obligation to defend Us in that matter.</p>
            <p className="base mb-0">13. <u>Dispute Resolution</u>.  We hope that We can resolve any issues you may have before an actual dispute arises.  However, if that does not work, then both parties agree to resolve any dispute arising out of these terms exclusively by individual, binding arbitration.  The term “dispute” is to be given the broadest possible meaning that will be enforced, and will include disputes related to your use of the Service and this Agreement (including the scope of this provision), regardless of whether such disputes are based in contract, tort, statute, fraud, unfair competition, or some other legal theory.  Each party agrees to try in good faith for 30 days to informally resolve any dispute before starting arbitration. A party who intends to seek arbitration must first send the other a written notice that describes the nature and basis of the dispute as well as the relief sought.  If the dispute is not resolved within 30 days after the date the notice was sent, then the parties may start arbitration as described below.  The American Arbitration Association (AAA) will administer the arbitration, and the arbitration will be governed by the AAA’s Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes, as modified by these terms.  The arbitrator’s award will be final and specifically enforceable under applicable law, and judgment may be entered upon it in any court with jurisdiction. The arbitration costs, including arbitrator compensation, will be paid by the party losing the arbitration. If the arbitration decision is split (e.g. we both win on some issues) the costs will be shared equally between You and TBT.  This section does not: apply to disputes arising out of or related to infringement or other misuse of TBT’s intellectual property rights.</p>

            <p className="base mt-24 mb-0">14.  <u>Governing Law and Jurisdiction</u>.  The laws of the state of Minnesota will govern this Agreement, without regard to any conflict of laws provisions.  You agree that We are located and operated in the United States and will be deemed to be solely based in Minnesota.  For any claims for which arbitration is inapplicable, you agree that such claims will be brought in federal or state court in Minnesota.</p>
            <p className="base mb-0">15.  <u>Use Outside of the United States</u>.  We expressly disclaim any representation or warranty that the Service complies with all applicable laws and regulations outside of the United States. If you use the Service outside of the United States, you expressly understand and agree that you are responsible for determining compliance with different laws, regulations, or customs that may apply in connection with your use of the Service.</p>
            <p className="base mb-0">16.  <u>Testimonials</u>.  By giving us a testimonial, review or similar commentary (collectively “Testimonial”), you hereby consent to our publication and reproduction of that Testimonial in perpetuity.</p>
            <p className="base mb-0">17.  <u>Survival</u>.  The following provisions will survive expiration or termination of this Agreement: 8, 10, 11, 12, 13, and 16</p>
            <p className="base mb-0">18.  <u>Electronic Communications</u>.  You consent to receive communications from us by email in accordance with this Agreement and applicable law. You acknowledge and agree that all agreements, notices, disclosures, and other communications that we provide to you electronically will satisfy any legal requirement that such communications be in writing.</p>
            <p className="base mb-0">19.  <u>Entire Agreement / Severability</u>.  This Agreement, together with our Privacy Policy, supersedes all prior terms, agreements, discussions and writings regarding the community and service and constitutes the entire agreement between Client and TBT regarding the community and the service.  If any provision in this Agreement is found to be unenforceable, then that provision will not affect the enforceability of the remaining provisions of the agreement, which will remain in full force and effect.</p>
          </Col>
        </Row>
      </Container>
    </div>
  </>

);

export default TermsAndConditions;
