/* eslint-disable radix */
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';

// The repeaterTextInputTableWithTabsSignal is a dummy signal and should be replaced with
// the signal that holds the sessions data

const RepeaterTextInputTableWithTabsReview = ({ prompt }) => {
  const [values, setValues] = useState({});
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (!savedValues) {
      return;
    }
    if (savedValues) {
      setValues(savedValues);
      Object.keys(savedValues).forEach((key) => {
        if (key.includes('targetAudience') && !key.includes('Name')) {
          const tab = key.replace('targetAudience', '');
          setTabs((prevTabs) => [...prevTabs, tab]);
        }
      });
    }
  }, [prompt]);

  return (
    <div className="bg-light border border-3 border-secondary px-24">
      <h4 className="text-center mb-16 mt-32">BUYER CRITERIA</h4>
      {tabs?.map((tab, index) => (
        <div className="mb-56" key={index}>
          <h5 className="mt-8 mb-8">{`Version ${index + 1}`}</h5>
          <Table style={{ textAlign: 'left' }} className="mt-24 mb-16">
            <thead>
              <tr>
                <th className="px-24 py-8 text-lead border border-3 border-primary">
                  {`${values?.[`targetAudience${index}Name`]}`}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object?.keys(values?.[`targetAudience${index}`])
                ?.sort((a, b) => {
                  const numberA = parseInt(a.replace('audience', ''));
                  const numberB = parseInt(b.replace('audience', ''));
                  return numberA - numberB;
                })
                .map((key, idx) => (
                  <tr key={idx}>
                    <td className="px-24 py-8 text-lead border border-3 border-primary">
                      {`${idx + 1}. ${
                        values?.[`targetAudience${index}`]?.[key]
                      }`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default RepeaterTextInputTableWithTabsReview;
