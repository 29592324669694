import Signal from 'libs/signals';

export const addClientSignal = Signal({});
export const addUserSignal = Signal({});

export const handleFormChange = ({ name, value }) => {
  addClientSignal.update({
    [name]: value,
  });
};

export const handleUserChange = ({ name, value }) => {
  addUserSignal.update({
    [name]: value,
  });
};
