import { useCallback, useEffect, useState } from 'react';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import Moment from 'react-moment';
import TopBarAgency from 'components/views/Dashboard/agency-dashboard/agency-components/TopBarAgency';
import userSignal from 'signals/User.Signal';
import AddClientModal from 'components/views/Dashboard/agency-dashboard/agency-modals/AddClientModal';
import usePageReloadDetector from 'hooks/usePageReloadDetector';
import { clientsSignal, getClientsData } from './clients.helpers';

const AgencyClientsList = () => {
  const program = clientsSignal?.value;
  const orgData = userSignal.value?.userData?.orgData;
  const history = useHistory();
  const [show, setShow] = useState(false);
  const isReloaded = usePageReloadDetector();

  const refetch = useCallback(async () => {
    if (isReloaded) {
      await getClientsData({ orgData });
    }
  }, [isReloaded]);

  useEffect(() => {
    refetch();
  }, [isReloaded]);

  const handleBackToDashboardClick = () => {
    history.push('/dashboard');
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBarAgency />
      <div
        className="position-relative w-100 px-lg-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header
            className="h3 bg-secondary-light text-primary text-center text-uppercase rounded-0 py-32"
            as="h3"
          >
            My Clients
          </Card.Header>
          <Card.Body className="px-xl-80 px-md-64 pt-xl-80 pt-md-64">
            <Row>
              <Col>
                <h5 className="fw-semibold">My Clients</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr
                  style={{ borderTop: 'solid 4px', marginBottom: '20px' }}
                  className="text-secondary-light"
                />
              </Col>
            </Row>
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-lg-0 px-16 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  {orgData && program.length > 0 ? (
                    <Table className="text-center" striped hover>
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Course in Progress</th>
                          <th>Start Date</th>
                          <th>Total Companies: {program?.length || 0}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {program?.map((org, idx) => (
                          <tr key={idx}>
                            <td>{org?.orgName}</td>
                            <td>
                              {org?.name
                                ? org?.name
                                : 'There is no course in progress'}
                            </td>
                            <td>
                              {org?.sessions && org?.sessions[0]?.schedule ? (
                                <Moment format="YYYY-MM-DD">
                                  {org?.sessions[0]?.schedule?.startTime}
                                </Moment>
                              ) : (
                                'No schedule'
                              )}
                            </td>
                            <td>
                              <Link to={`/agency/client/${org.organizationId}`}>
                                View Details
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>
                      Currently, you don&apos;t have clients in your Agency.
                      Start adding them here!
                    </p>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={{ offset: 10 }}>
                <Button
                  style={{ width: '180px' }}
                  className="btn-outline-primary py-8"
                  onClick={() => setShow(true)}
                >
                  + Add Client
                </Button>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center border-0 bg-white pb-64">
            <Button
              className="btn-outline-secondary py-16"
              onClick={handleBackToDashboardClick}
            >
              Back to Dashboard
            </Button>
          </Card.Footer>
        </Card>
        <AddClientModal show={show} setShow={setShow} />
      </div>
    </>
  );
};

export default AgencyClientsList;
