import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { handleFormChange } from 'components/views/Settings/Settings.helpers';
import userSignal from 'signals/User.Signal';
import { apiFetch } from 'api/api';
import alertSignal from 'signals/Alert.signal';
import Field from '../../Field/Field';

const ProfileSettings = () => {
  const signal = userSignal.value.userData;

  const [firstName, setFirstName] = useState(signal.firstName);
  const [lastName, setLastName] = useState(signal.lastName);
  const [phone, setPhone] = useState(signal.phone);

  const handleOnChange = (event) => {
    switch (event.name) {
      case 'firstName':
        setFirstName(event.value);
        break;
      case 'lastName':
        setLastName(event.value);
        break;
      case 'phone':
        setPhone(event.value);
        break;
      default:
        break;
    }
  };

  const handleRequest = async () => {
    const token = userSignal.value.fbUser.Aa;
    const body = {
      firstName,
      lastName,
      phone,
    };

    try {
      const request = await apiFetch({
        path: '/users',
        body,
        method: 'PUT',
        token,
      });

      if (request) {
        const userData = await apiFetch({ path: '/users', token });

        userSignal.update({
          userData,
        });
        alertSignal.update({
          type: 'notification',
          message: 'Information updated',
          className: 'bg-success',
        });
      }
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: 'An error occured while updating your data',
        error,
      });
    }
  };

  return (
    <div
      className="border border-secondary ps-xl-40 pe-xl-104 ps-24 pe-56 py-xxxl-56 py-32"
      style={{ background: 'white' }}
    >
      <Field
        type="text-input"
        name="firstName"
        value={firstName}
        label="First Name"
        className="text-primary"
        labelClassName="mb-lg-8 mb-0 text-dark"
        inputClassName="border-secondary rounded-0 ps-8"
        onChange={handleOnChange}
      />
      <Field
        type="text-input"
        name="lastName"
        value={lastName}
        label="Last Name"
        className="pt-xxxl-24 pt-16 text-primary"
        labelClassName="mb-lg-8 mb-0 text-dark"
        inputClassName="border-secondary rounded-0 ps-8"
        onChange={handleOnChange}
      />
      <Field
        type="email"
        name="email"
        value={signal.email}
        label="Email Address"
        className="pt-xxxl-24 pt-16 text-primary"
        labelClassName="mb-lg-8 mb-0 text-dark"
        inputClassName="border-secondary rounded-0 ps-8"
        onChange={handleFormChange}
        disabled
      />
      <Field
        type="phone"
        name="phone"
        value={phone}
        label="Phone Number"
        className="pt-xxxl-24 pt-16 text-primary"
        labelClassName="mb-lg-8 mb-0 text-dark"
        inputClassName="border-secondary rounded-0 ps-8"
        onChange={handleOnChange}
      />
      <Field
        type="text-input"
        name="role"
        value={signal.role.name}
        label="Role in the Company"
        className="pt-xxxl-24 pt-16 text-primary"
        labelClassName="mb-lg-8 mb-0 text-dark"
        inputClassName="border-secondary rounded-0 ps-8"
        onChange={handleFormChange}
      />
      <div className="w-100 mt-xxxl-56 mt-32">
        <Button
          onClick={handleRequest}
          variant="secondary"
          className="w-100 bg-primary text-secondary rounded-0 py-xxxl-16 py-8"
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ProfileSettings;
