import React from 'react';
import { FormControl } from 'react-bootstrap';

export const InputGroupContainer = ({ children }) => (
  <div
    className="bg-light px-80 py-80 rounded-lg"
  >
    {children}
  </div>
);

export const InputsContainer = ({ children }) => (
  <div
    style={{
      border: '2px solid var(--secondary, #B2A05E)',
      opacity: '0.6000000238418579',
    }}
    className="p-24"
  >
    {children}
  </div>
);

export const BottomLineInputs = ({ number, onChange }) => (
  <div className="d-flex justify-content-center">
    <subtitle>{number}.</subtitle>
    <FormControl
      name={`needs-${number}`}
      required
      as="textarea"
      onChange={onChange}
      style={{ height: '100px' }}
      className="w-75 my-16"
      placeholder="Begin typing ..."
    />
  </div>
);
