import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TopBarAgency from './agency-components/TopBarAgency';
import AgencyVideos from './agency-components/AgencyVideos';
import AgencyPurchases from './agency-components/AgencyPurchases';
import AgencyOfferings from './agency-components/AgencyOfferings';
import AgencyClients from './agency-components/AgencyClients';
import AgencySchedules from './agency-components/AgencySchedules';
import AgencyResources from './agency-components/AgencyResources';

const AgencyDashboard = ({ modal }) => (
  <>
    <TopBarAgency modal={modal} />
    <div
      className="position-relative w-100 px-8 pb-64 pt-136"
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg-squares.png)',
      }}
    >
      <Container style={{ maxWidth: '1856px' }}>
        <Row className="w-100 m-0 pb-16 justify-content-center">
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <AgencyClients modal={modal} />
          </Col>
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <AgencyPurchases modal={modal} />
          </Col>

          <Col
            lg={3}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <AgencySchedules modal={modal} />
          </Col>
        </Row>
        <Row className="w-100 m-0 pt-16 justify-content-center">
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <AgencyVideos modal={modal} />
          </Col>
          <Col
            lg={4}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <AgencyOfferings modal={modal} />
          </Col>
          <Col
            lg={3}
            md={6}
            xs={12}
            className="position-relative px-md-16 pe-16 mb-md-0 mb-32"
          >
            <AgencyResources modal={modal} />
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default AgencyDashboard;
