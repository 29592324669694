/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { FormControl, Tab, Tabs, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  handlePromptChange,
  getPromptValueFromProgramSignal,
} from 'components/views/Sessions/Session.helpers';
import { InputGroupContainer } from '../InputGroups/components/inputContainer';

const TaglineChart = ({ prompt }) => {
  const [tabs, setTabs] = useState([1]);
  const [values, setValues] = useState({});

  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (savedValues) {
      setValues(savedValues);
      setTabs(
        Object.keys(savedValues).map((key) => parseInt(key.split('-')[1], 10)),
      );
    }
  }, [prompt]);

  const handleAddTabClick = () => {
    if (tabs.length >= 3) return;
    setTabs([...tabs, tabs.length + 1]);
  };

  const handleFormChange = (e) => {
    const { name, value, id } = e.target;
    const newValues = { ...values };
    if (!newValues[`theme-${id}`]) {
      newValues[`theme-${id}`] = {};
    }
    newValues[`theme-${id}`][name] = value;
    setValues(newValues);
    handlePromptChange({ value: newValues, prompt });
  };

  return (
    <InputGroupContainer>
      <Tabs
        style={{ border: 'none', backgroundColor: 'transparent' }}
        className="border-0 bg-transparent"
      >
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            className="border-0"
            eventKey={`Theme ${idx + 1}`}
            title={`Theme ${idx + 1}`}
            tabClassName="tab-background-none fw-bold"
          >
            <div className="border-1 border px-48 py-40">
              <h4>Tagline Structures</h4>
              <Row className="mt-32">
                <Form.Label className="text-dark">
                  Phrase starting with a Gerund
                </Form.Label>
                <FormControl
                  as="input"
                  name="gerund"
                  id={idx + 1}
                  placeholder="Enter text here"
                  onBlur={(e) => handleFormChange(e)}
                  defaultValue={values[`theme-${idx + 1}`]?.gerund || ''}
                />
              </Row>
              <Row className="mt-32">
                <Form.Label className="text-dark">
                  Phrase starting with a Verb
                </Form.Label>
                <FormControl
                  as="input"
                  rows={5}
                  id={idx + 1}
                  name="verb"
                  placeholder="Enter text here"
                  onBlur={(e) => handleFormChange(e)}
                  defaultValue={values[`theme-${idx + 1}`]?.verb || ''}
                />
              </Row>
              <Row className="mt-32">
                <Form.Label className="text-dark">
                  Phrase starting with a Noun
                </Form.Label>
                <FormControl
                  as="input"
                  rows={5}
                  id={idx + 1}
                  name="noun"
                  placeholder="Enter text here"
                  onBlur={(e) => handleFormChange(e)}
                  defaultValue={values[`theme-${idx + 1}`]?.noun || ''}
                />
              </Row>
              <Row className="mt-32">
                <Form.Label className="text-dark">
                  Phrase starting with an Adjective
                </Form.Label>
                <FormControl
                  as="input"
                  rows={5}
                  id={idx + 1}
                  name="adjective"
                  placeholder="Enter text here"
                  onBlur={(e) => handleFormChange(e)}
                  defaultValue={values[`theme-${idx + 1}`]?.adjective || ''}
                />
              </Row>
              <Row className="mt-32">
                <Form.Label className="text-dark">
                  Two Words. Two Words.
                </Form.Label>
                <FormControl
                  as="input"
                  rows={5}
                  id={idx + 1}
                  name="twoWords"
                  placeholder="Enter text here"
                  onBlur={(e) => handleFormChange(e)}
                  defaultValue={values[`theme-${idx + 1}`]?.twoWords || ''}
                />
              </Row>
              <Row className="mt-32">
                <Form.Label className="text-dark">Word. Word. Word.</Form.Label>
                <FormControl
                  as="input"
                  rows={5}
                  name="wordWordWord"
                  id={idx + 1}
                  placeholder="Enter text here"
                  onBlur={(e) => handleFormChange(e)}
                  defaultValue={values[`theme-${idx + 1}`]?.wordWordWord || ''}
                />
              </Row>
            </div>
          </Tab>
        ))}
        <Tab
          className="border-0"
          title={
            <Button
              variant="none"
              className="tab-background-none fw-bold"
              onClick={() => handleAddTabClick()}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Add another variation
            </Button>
          }
        />
      </Tabs>
    </InputGroupContainer>
  );
};

export default TaglineChart;
