import { Form } from 'react-bootstrap';

const Integer = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
}) => {
  const handleChange = (e) => {
    const eTmp = { ...e };
    eTmp.target.value = e.target.value.replace('.', '');
    onChange(eTmp);
  };

  return (
    <Form.Group className={className}>
      {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
      <div>
        <Form.Control
          name={name}
          type="number"
          value={value}
          id="inputInteger"
          className={inputClassName}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </Form.Group>
  );
};

export default Integer;
