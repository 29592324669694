import { useCallback, useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import Loader from 'components/global/Loader';
import userSignal from 'signals/User.Signal';
import fetchAndSetSessionConfig from './SessionLoader.helpers';

const SessionLoader = () => {
  const [isSessionValid, setIsSessionValid] = useState(false);
  const { programId } = useParams();
  const history = useHistory();

  const { fbUser } = userSignal.value;

  const init = useCallback(async (sessionIdData) => {
    await Promise.all([
      fetchAndSetSessionConfig(sessionIdData, fbUser.Aa)
        .then((response) => {
          if (response) {
            setIsSessionValid(true);
          }
        })
        .catch((error) => {
          if (error) {
            setIsSessionValid(false);
          }
        }),
    ]);
  });

  useEffect(() => {
    init(programId);

    if (isSessionValid) history.push(`/sessions/${programId}`);
  }, [programId, isSessionValid]);

  return (
    <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
      <Loader message="Creating your program..." className="text-center" />
    </div>
  );
};

export default SessionLoader;
