/* eslint-disable no-shadow */
import { Form } from 'react-bootstrap';
import Checkbox from './FieldComponents/Checkbox';
import CheckboxGroup from './FieldComponents/CheckboxGroup';
import Currency from './FieldComponents/Currency';
import CurrencyDecimal from './FieldComponents/CurrencyDecimal';
import Country from './FieldComponents/Country';
import TextInput from './FieldComponents/TextInput';
import DateSelect from './FieldComponents/DateSelect';
import Email from './FieldComponents/Email';
import Ein from './FieldComponents/Ein';
import Integer from './FieldComponents/Integer';
import Percent from './FieldComponents/Percent';
import Phone from './FieldComponents/Phone';
import MultiSelect from './FieldComponents/MultiSelect';
import State from './FieldComponents/State';
import TextArea from './FieldComponents/TextArea';
import ToggleSwitch from './FieldComponents/ToggleSwitch';
import Radio from './FieldComponents/Radio';
import Select from './FieldComponents/Select';
import YesNo from './FieldComponents/YesNo';
import Zip from './FieldComponents/Zip';
import PasswordInput from './FieldComponents/PasswordInput';

const Field = ({
  disabled,
  type,
  name,
  label,
  onChange,
  className,
  labelClassName,
  inputClassName,
  evenRowClassName,
  options,
  value = '',
  selectedClass = '',
  countryValue = null,
  placeholder,
  dropDownClassName,
  // disabled,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  checkboxClassName,
  checkboxLabelClassName,
  style,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
}) => {
  const commonProps = {
    type,
    name,
    label,
    className,
    labelClassName,
    inputClassName,
  };
  const handleInputChange = (e) => {
    if (e.target.value === 'true') {
      onChange({ name: e.target.name, value: true });
    } else if (e.target.value === 'false') {
      onChange({ name: e.target.name, value: false });
    } else {
      onChange({ name: e.target.name, value: e.target.value });
    }
  };

  const handleSelectChange = ({ name, value }) => {
    onChange({ name, value });
  };

  const ComponentMap = {
    checkbox: (
      <Checkbox
        {...commonProps}
        value={value}
        checkboxClassName={checkboxClassName}
        onChange={handleSelectChange}
      />
    ),
    'checkbox-group': (
      <CheckboxGroup
        {...commonProps}
        onChange={handleSelectChange}
        options={options}
        value={value}
        evenRowClassName={evenRowClassName}
        labelClassName={labelClassName}
        checkboxClassName={checkboxClassName}
        checkboxLabelClassName={checkboxLabelClassName}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}
        xxxl={xxxl}
      />
    ),
    country: (
      <Country {...commonProps} onChange={handleSelectChange} value={value} />
    ),
    currency: (
      <Currency
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    'currency-decimal': (
      <CurrencyDecimal
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    date: (
      <DateSelect
        disabled={disabled}
        {...commonProps}
        onChange={handleSelectChange}
        value={value}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        label={label}
      />
    ),
    ein: (
      <Ein
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    email: (
      <Email
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    integer: (
      <Integer
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    'multi-select': (
      <MultiSelect
        {...commonProps}
        onChange={handleSelectChange}
        options={options}
        value={value || []}
        selectedClass={selectedClass}
      />
    ),
    percent: (
      <Percent
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    phone: (
      <Phone
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    radio: (
      <Radio
        {...commonProps}
        onChange={handleInputChange}
        options={options}
        value={value}
      />
    ),
    select: (
      <Select
        {...commonProps}
        onChange={handleInputChange}
        options={options}
        value={value}
        dropDownClassName={dropDownClassName}
        placeholder={placeholder}
      />
    ),
    state: (
      <State
        {...commonProps}
        onChange={onChange}
        value={value}
        countryValue={countryValue}
      />
    ),
    'text-area': (
      <TextArea
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
        style={style}
      />
    ),
    'password-input': (
      <PasswordInput
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    'text-input': (
      <TextInput
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
    'toggle-switch': (
      <ToggleSwitch
        {...commonProps}
        onChange={handleSelectChange}
        options={options}
        value={value}
      />
    ),
    'yes-no': (
      <YesNo
        {...commonProps}
        onChange={onChange}
        options={options}
        value={value}
      />
    ),
    zip: (
      <Zip
        {...commonProps}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
      />
    ),
  };
  if (!ComponentMap[type]) {
    <Form.Label>{label}</Form.Label>;
  }

  const Component = ComponentMap[type];
  return Component;
};

export default Field;
