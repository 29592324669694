/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import {
  allProgramsSignal,
  fetchAndSetProgramData,
  // programsSignal,
} from 'components/views/Dashboard/Dashboard.helpers';
import {
  downloadAllDocuments,
  downloadCombinedDocument,
  downloadDocument,
  getPDFValuesFromProgramSessions,
} from '../Documents.helpers';

const PlaybooksTab = () => {
  const [combinedPrompts, setCombinedPrompts] = useState([]);
  const [sessionDownloads, setSessionDownloads] = useState('1');
  // const [completedSessions, setCompletedSessions] = useState(false);

  useEffect(async () => {
    await fetchAndSetProgramData();
    const programs = allProgramsSignal.value;
    const allPDFValues = {};
    await programs?.forEach((program) => {
      const { sessions } = program;
      const completedSessions = sessions?.filter(
        (session) => session?.completed === true,
      );
      if (completedSessions.length <= 0) {
        return;
      }
      const values = getPDFValuesFromProgramSessions(completedSessions);
      allPDFValues[program?.name] = values;
    });
    setCombinedPrompts(allPDFValues);
  }, []);

  return (
    <div className="py-56">
      <Row className="m-0 w-100 px-16">
        <Col lg={9} md={8} xs={12} className="p-0 m-0">
          <h4 className="h4 fw-600 text-primary">ACCESS PLAYBOOKS HERE</h4>
          <p className="base-400 text-primary-light">
            Download versions of your Part One and Part Two Playbooks.
          </p>
        </Col>
      </Row>
      <Row className="mt-40">
        {Object.values(combinedPrompts).length < 1 ? (
          <p className="fw-bold">
            {' '}
            No available Playbooks. Please complete active sessions to download
            Playbooks.
          </p>
        ) : (
          allProgramsSignal.value?.map(({ sessions, name }, idx) => {
            const completedSessions = sessions?.filter(
              (session) => session?.completed === true,
            );
            if (completedSessions.length > 0) {
              return (
                <div key={idx}>
                  <div key={`head-div${idx}`} className="">
                    <Row key={`head-row ${idx}`} className="m-0 w-100 px-16">
                      <Col
                        key={`head-col ${idx}`}
                        lg={9}
                        md={8}
                        xs={12}
                        className="p-0 m-0"
                      >
                        <p
                          key={`head-p ${idx}`}
                          className="pe-16 lead-600 text-uppercase"
                        >
                          {name === 'Part One' ? 'Part One' : 'Part Two'}
                        </p>
                      </Col>
                      <Col
                        key={`head-col-${idx}`}
                        lg={3}
                        md={4}
                        xs={12}
                        className="p-0"
                      >
                        <Button
                          key={`head-button ${idx}`}
                          className="base py-8 w-100"
                          variant="outline-primary"
                          disabled={combinedPrompts[name] === undefined}
                          onClick={() =>
                            downloadCombinedDocument(
                              combinedPrompts[name],
                              name,
                            )
                          }
                        >
                          {`Download All ${
                            name === 'Part One' ? 'Part One' : 'Part Two'
                          } Answers`}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Tabs
                    className="mx-24 border-0 mt-32"
                    onSelect={(k) => setSessionDownloads(k)}
                    key={`tab-${idx}-1`}
                  >
                    {combinedPrompts[name] === undefined ||
                    Object.values(combinedPrompts[name]).length < 1 ? (
                      <p key={`p--${idx}`} className="fw-bold mt-24">
                        {' '}
                        No Available Playbooks.
                      </p>
                    ) : (
                      sessions?.map((doc, sessionIdx) => {
                        if (doc?.completed === undefined) {
                          return (
                            <p key={sessionIdx}>
                              Please complete entire Session to download
                              Playbook.
                            </p>
                          );
                        }
                        if (doc?.completed === true) {
                          return (
                            <Tab
                              key={`tab-${idx}-${sessionIdx}`}
                              eventKey={doc?.number}
                              title={`Session  ${doc?.number}`}
                              tabClassName="lead-600 border-0 fs-1"
                            >
                              <div
                                key={`bottom-div-${sessionIdx}`}
                                className="mb-32 d-flex flex-wrap"
                              >
                                <Col
                                  key={`bottom-col-${sessionIdx}`}
                                  lg={4}
                                  md={6}
                                  xs={12}
                                  className="my-24 mx-8"
                                >
                                  <div
                                    key={`bottom-div-1-${sessionIdx}`}
                                    className="p-24 border border-primary-light h-100"
                                    style={{ borderRadius: '5px' }}
                                  >
                                    <div
                                      key={`bottom-div-2-${sessionIdx}`}
                                      className="d-flex flex-row justify-content-between"
                                    >
                                      <p
                                        key={`bottom-p-${sessionIdx}`}
                                        className="fw-bold mb-0  "
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {doc?.title}
                                      </p>
                                      <Button
                                        key={`Bottom-button -${sessionIdx}`}
                                        className="bg-transparent border-0"
                                        onClick={() => {
                                          downloadAllDocuments(
                                            combinedPrompts[name],
                                            doc?.uid,
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          key={`icon-${sessionIdx}`}
                                          className="text-secondary"
                                          role="button"
                                          icon={faDownload}
                                        />
                                      </Button>
                                    </div>
                                    {/* <p className="small mt-16 mb-0">
                                  Updated -{' '}
                                  {new Date(
                                    programsSignal.value._updatedAt._seconds * 1000,
                                  ).toLocaleDateString()}
                                </p> */}
                                    <p
                                      key={`bottom-p-2${sessionIdx}`}
                                      className="small mt-16 mb-0"
                                    >
                                      {/* {doc?.titleWithPackage} */}
                                    </p>
                                  </div>
                                </Col>
                              </div>
                            </Tab>
                          );
                        }
                      })
                    )}
                  </Tabs>
                </div>
              );
            }
          })
        )}
      </Row>
    </div>
  );
};

export default PlaybooksTab;
