import { useState } from 'react';
import { Row, Col, Tab, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ProfileSettings from './components/ProfileSettings';
import ManageTeam from './components/ManageTeam';
import Notifications from './components/Notifications';

const SettingsModal = () => {
  const [tab, setTab] = useState('ProfileSettings');

  return (
    <div className="pt-xl-40 pb-lg-24" style={{ maxHeight: '92%' }}>
      <Row>
        <Col style={{ marginLeft: '95px', marginBottom: '80px' }}>
          <Link className="base back-button" to="/dashboard">
            <FontAwesomeIcon style={{ marginRight: 16 }} icon={faArrowLeft} />{' '}
            Back to Dashboard
          </Link>
        </Col>
      </Row>
      <Row className="h-100">
        <Col
          xxl={4}
          xl={5}
          lg={5}
          md={6}
          sm={12}
          className="mx-0 ps-xl-104 ps-lg-56 px-md-32 h-100"
          style={{
            borderRight: '4px solid rgba(51, 57, 74, 0.5)',
          }}
        >
          <h2 className="pb-xl-72 mb-0">Settings</h2>
          <div className="pt-xxxl-32 pt-16">
            <Button
              className={`bg-white mb-lg-32 mb-16 w-100 py-16 rounded-0 ${
                tab === 'ProfileSettings' &&
                'border border-secondary custom-shadow'
              }`}
              onClick={() => setTab('ProfileSettings')}
            >
              <Row className="flex-nowrap align-items-center">
                <Col lg={2} xs={2}>
                  <FontAwesomeIcon icon={faUser} className="text-primary" />
                </Col>
                <Col lg={10} xs={10}>
                  <div className="d-flex flex-column text-start">
                    <div className="lead text-dark pb-lg-16">
                      Profile Settings
                    </div>
                    <div className="base text-primary-light">
                      Update name, email, contact...
                    </div>
                  </div>
                </Col>
              </Row>
            </Button>
            <Button
              className={`bg-white mb-lg-32 mb-16 w-100 py-16 rounded-0 ${
                tab === 'ManageTeam' && 'border border-secondary custom-shadow'
              }`}
              onClick={() => setTab('ManageTeam')}
            >
              <Row className="flex-nowrap align-items-center">
                <Col lg={2} xs={2}>
                  <FontAwesomeIcon icon={faUsers} className="text-primary" />
                </Col>
                <Col lg={10} xs={10}>
                  <div className="d-flex flex-column text-start">
                    <div className="lead text-dark pb-lg-16">Manage team</div>
                    <div className="base text-primary-light">
                      Edit contributors to Brand Trifecta
                    </div>
                  </div>
                </Col>
              </Row>
            </Button>
            {/*
              <Button
                className={`bg-white mb-lg-32 mb-16 w-100 py-16 rounded-0 ${tab === 'Notifications' && 'border border-secondary custom-shadow'}`}
                onClick={() => setTab('Notifications')}
              >
                <Row
                  className="flex-nowrap align-items-center"
                >
                  <Col
                    lg={2}
                    xs={2}
                  >
                    <FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
                  </Col>
                  <Col
                    lg={10}
                    xs={10}
                  >
                    <div className="d-flex flex-column text-start">
                      <div className="lead text-dark pb-lg-16">
                        Notifications
                      </div>
                      <div className="base text-primary-light">
                        Lorem ipsum dolor sit amet, consec...
                      </div>
                    </div>
                  </Col>
                </Row>
              </Button>
              */}
          </div>
        </Col>
        <Col
          xxl={5}
          xl={7}
          lg={7}
          md={6}
          xs={12}
          className="h-100 mx-auto px-xxl-32 px-lg-88 px-md-32 px-sm-48"
        >
          <Tab.Container activeKey={tab} className="border-0">
            <Tab.Content className="w-100 h-100">
              <Tab.Pane eventKey="ProfileSettings" className="h-100">
                <ProfileSettings />
              </Tab.Pane>
              <Tab.Pane eventKey="ManageTeam" className="h-100">
                <ManageTeam />
              </Tab.Pane>
              <Tab.Pane eventKey="Notifications" className="h-100">
                <Notifications />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsModal;
