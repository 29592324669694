import { apiFetch } from 'api/api';
import editScheduleSignal from 'signals/EditSchedule.signal';
import alertSignal from 'signals/Alert.signal';
import userSignal from 'signals/User.Signal';
import { fetchAndSetProgramData } from '../Dashboard/Dashboard.helpers';

export const handleEditScheduleFormChange = ({ name, value }) => {
  if (name === 'sessionStartTime') {
    const time = new Date(value);
    const endTime = new Date(time.getTime() + 90 * 60000);
    editScheduleSignal.update({
      [name]: value,
      sessionEndTime: endTime,
    });
  }
  if (name === 'sessionDate') {
    const date = new Date(value);
    const startTime = editScheduleSignal.value.sessionStartTime || date;
    const endTime = editScheduleSignal.value.sessionEndTime || date;
    const newStartTime = new Date(startTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate()));
    const newEndTime = new Date(endTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate()));
    editScheduleSignal.update({
      [name]: value,
      sessionStartTime: newStartTime,
      sessionEndTime: newEndTime,
    });
  }
  editScheduleSignal.update({
    [name]: value,
  });
};

export const handleEditScheduleSubmit = async (session) => {
  const { id } = session;
  const { sessionDate, sessionStartTime, sessionEndTime } = editScheduleSignal.value;
  const body = {
    data: {
      date: sessionDate,
      startTime: sessionStartTime,
      endTime: sessionEndTime,
    },
    id,
  };
  try {
    await apiFetch({
      path: '/schedule/update-schedule',
      method: 'POST',
      body,
      token: userSignal.value.fbUser.Aa,
    });
    alertSignal.update({
      type: 'notification',
      message: 'Schedule updated successfully',
      className: 'bg-success',
    });
    fetchAndSetProgramData();
    // window.location.reload();
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: 'Unable to update schedule',
      error,
    });
  }
};

export default {};
