import signupSignal from 'signals/Signup.signal';
import alertSignal from 'signals/Alert.signal';
import { apiFetchUnAuthenticated } from 'api/api';
import { auth } from 'utils/firebase';
import loaderSignal from 'signals/Loader.signal';

export const handleFormChange = ({ name, value }) => {
  signupSignal.update({
    [name]: value,
  });
};

export const handleSubmit = async ({ isAgency }) => {
  // e.preventDefault();
  try {
    loaderSignal.update({ isContentLoading: true });
    // TODO: This block needs to sign in the user
    const data = {
      firstName: signupSignal.value.firstName,
      lastName: signupSignal.value.lastName,
      email: signupSignal.value.email,
      password: signupSignal.value.password,
      role: isAgency ? 'Practitioner' : signupSignal.value.role || 'Owner',
      isInvitation: signupSignal?.value?.isInvitation,
      organizationId: signupSignal.value.organizationId,
    };
    const response = await apiFetchUnAuthenticated({
      path: '/users/signup',
      body: data,
      method: 'POST',
    });

    if (response.token) {
      signupSignal.update({
        signupToken: response.token,
      });

      await auth.signInWithCustomToken(response.token);

      window.dataLayer.push({
        event: 'accountCreated',
        eventSettingsTable: [
          {
            parameter: 'eventCategory',
            parameterValue: 'Account Created',
          },
          {
            parameter: 'eventAction',
            parameterValue: `Account Type: ${isAgency ? 'Agency' : 'Regular'}`,
          },
          { parameter: 'eventLabel', parameterValue: signupSignal.value.email },
        ],
      });

      return true;
    }

    alertSignal.update({
      type: 'alert',
      message: response.error.message,
      response,
    });
    return false;
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
    return false;
  } finally {
    loaderSignal.reset();
  }
};
