/* eslint-disable indent */
import settingsSignal from 'signals/Settings.signal';
import alertSignal from 'signals/Alert.signal';
import { apiFetch } from 'api/api';
import userSignal from 'signals/User.Signal';

export const handleFormChange = ({ name, value }) => {
  settingsSignal.update({
    ...settingsSignal.value,
    [name]: value,
  });
};

export const handleAddContributor = () => {
  handleFormChange({
    name: 'contributors',
    value: (settingsSignal.value.contributors && settingsSignal.value.contributors.length)
      ? [...settingsSignal.value.contributors, { name: settingsSignal.value.newName, email: settingsSignal.value.newEmail, role: settingsSignal.value.newRole }]
      : [{ name: settingsSignal.value.newName, email: settingsSignal.value.newEmail, role: settingsSignal.value.newRole }],
  });
  handleFormChange({ name: 'isAddingContributor', value: false });
  handleFormChange({ name: 'newName', value: '' });
  handleFormChange({ name: 'newEmail', value: '' });
  handleFormChange({ name: 'newRole', value: '' });
};

export const handleSubmitContributor = async () => {
  try {
    const request = await apiFetch({
      path: '/users/invitation',
      method: 'Post',
      token: userSignal.value.fbUser.Aa,
      body: settingsSignal.value.contributors,
    });

    if (request) {
      alertSignal.update({
        type: 'notification',
        message: 'Contributors saved',
        className: 'bg-success',
      });
    }
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'Could not add the new contributor',
      error,
    });
  }
};

export const handleCancelAddContributor = () => {
  handleFormChange({ name: 'isAddingContributor', value: false });
  handleFormChange({ name: 'newName', value: '' });
  handleFormChange({ name: 'newEmail', value: '' });
  handleFormChange({ name: 'newRole', value: '' });
};

export const handleDeleteContributor = (idx) => {
  const newContributors = settingsSignal.value.contributors;
  newContributors.splice(idx, 1);
  handleFormChange({ name: 'contributors', value: newContributors });
};

export const handleEditContributor = (idx) => {
  const newContributors = settingsSignal.value.contributors;
  newContributors[idx] = { name: settingsSignal.value.newName, email: settingsSignal.value.newEmail, role: settingsSignal.value.newRole };
  handleFormChange({ name: 'contributors', value: newContributors });
  handleFormChange({ name: 'newName', value: '' });
  handleFormChange({ name: 'newEmail', value: '' });
  handleFormChange({ name: 'newRole', value: '' });
};

export const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    // TODO: Update user's settings in prisma when we have the db set up
    alertSignal.update({ // show success notification on successful save
      type: 'notification',
      className: 'bg-success',
      message: 'Settings saved!',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  }
};

export const handleInitContributorsData = async () => {
  handleFormChange({
    name: 'contributors',
    value: [],
  });

  try {
    const { users } = await apiFetch({
      path: '/users/invitation',
      method: 'Get',
      token: userSignal.value.fbUser.Aa,
    });

    users?.forEach((user) => {
      handleFormChange({
        name: 'contributors',
        value: [...settingsSignal.value.contributors,
        { name: user.fullName, email: user.email, role: user.role, isAccepted: user.isAccepted },
        ],
      });
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'An error occured when updating the data',
      error,
    });
  }
};

// ASK JUSTIN HOW TO HANDLE SIGNALS WHEN IT COMES TO STORING PERMANENT DATA LIKE SETTINGS
