import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import TopBarAgency from 'components/views/Dashboard/agency-dashboard/agency-components/TopBarAgency';
import { findBusinessTypeName } from 'components/views/Dashboard/agency-dashboard/agency-components/AgencyClients';
import userSignal from 'signals/User.Signal';
import usePageReloadDetector from 'hooks/usePageReloadDetector';
import alertSignal from 'signals/Alert.signal';
import { clientsSignal } from '../Clients/clients.helpers';
import AddUserModal from '../../Dashboard/agency-dashboard/agency-modals/AddUserModal';

const ClientDetails = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const program = clientsSignal?.value;
  const { userData } = userSignal.value;
  const isReloaded = usePageReloadDetector();

  const client = program.filter((item) => item.organizationId === Number(id));

  useEffect(() => {
    if (isReloaded) {
      history.push('/dashboard');
      alertSignal.update({
        type: 'alert',
        message: 'An error ocured while refreshing the page',
      });
    }
  }, [isReloaded]);

  const handleBackToDashboardClick = () => {
    history.push('/dashboard');
  };

  const handleVisitClientDashboard = () => {
    window.localStorage.setItem(
      'creds',
      JSON.stringify({
        isAgency: false,
        userType: userData?.role?.name,
      }),
    );
    window.localStorage.setItem('organizationId', id);

    window.location.href = '/dashboard';
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBarAgency />
      <div
        className="position-relative w-100 px-lg-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header
            className="h3 bg-secondary-light text-primary text-center text-uppercase rounded-0 py-32"
            as="h3"
          >
            Client Profile
          </Card.Header>
          <Card.Body className="px-xl-80 px-md-64 pt-xl-80 pt-md-64">
            <Row>
              <Col lg={6} className="d-flex justify-content-start">
                <h5 className="fw-semibold">Company Information</h5>
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <h5 style={{ fontSize: '15px' }} className="fw-semibold">
                  <button
                    type="button"
                    onClick={handleVisitClientDashboard}
                    style={{
                      border: 'none',
                      background: 'none',
                      textDecoration: 'underline',
                    }}
                  >
                    Visit Client Dashboard
                  </button>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr
                  style={{ borderTop: 'solid 4px', marginBottom: '20px' }}
                  className="text-secondary-light"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '20px' }} className="text-muted">
                  {client[0]?.orgName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-muted">
                  {findBusinessTypeName({ value: client[0]?.orgType })}
                </p>
              </Col>
            </Row>

            <Row style={{ marginTop: '40px' }}>
              <Col lg={6} className="d-flex justify-content-start">
                <h5 className="fw-semibold">Company Details</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr
                  style={{ borderTop: 'solid 4px', marginBottom: '20px' }}
                  className="text-secondary-light"
                />
              </Col>
            </Row>
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-lg-0 px-16 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  <Table striped hover>
                    <tbody>
                      <tr>
                        <td>Company Type</td>
                        <td>
                          {findBusinessTypeName({ value: client[0]?.orgType })}
                        </td>
                      </tr>
                      <tr>
                        <td>Company Size</td>
                        <td>{client[0]?.orgSize}</td>
                      </tr>
                      <tr>
                        <td>Contact Name</td>
                        <td>{client[0]?.users?.users[1]?.fullName}</td>
                      </tr>
                      <tr>
                        <td>Contact Email</td>
                        <td>{client[0]?.users?.users[1]?.email}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="d-flex justify-content-start">
                <h5 className="fw-semibold">Company Users</h5>
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <h5 style={{ fontSize: '15px' }} className="fw-semibold">
                  <button
                    type="button"
                    style={{ border: 'none', background: 'none' }}
                    onClick={() => setShow(true)}
                  >
                    + Add User
                  </button>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr
                  style={{ borderTop: 'solid 4px', marginBottom: '20px' }}
                  className="text-secondary-light"
                />
              </Col>
            </Row>
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-lg-0 px-16 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>User Role</th>
                        <th>Email</th>
                        <th>Total Users: {client[0]?.users?.users?.length}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {client[0]?.users?.users?.map((user) => (
                        <tr key={user.id}>
                          <td>{user.fullName}</td>
                          <td>{user.role}</td>
                          <td>{user.email}</td>
                          <td>{user.isAccepted ? 'Active' : 'Pending'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="d-flex justify-content-start">
                <h5 className="fw-semibold">Program Progress</h5>
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <h5 style={{ fontSize: '15px' }} className="fw-semibold">
                  {/* View Full Schedule */}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr
                  style={{ borderTop: 'solid 4px', marginBottom: '20px' }}
                  className="text-secondary-light"
                />
              </Col>
            </Row>
            <Row>
              {client.map((session, index) => (
                <>
                  <Row key={index}>
                    {session.name ? (
                      <Col>{`${session.name} Status`}</Col>
                    ) : (
                      <Col>No program active</Col>
                    )}
                  </Row>
                  <Row>
                    <Col className="d-flex">
                      {session?.sessions?.map((item, idx) => (
                        <div key={idx}>
                          <p className="text-center">{idx + 1}</p>
                          <hr
                            style={{
                              borderTop: 'solid 8px',
                              marginBottom: '20px',
                              width: '40px',
                            }}
                            className={
                              item.completed ? 'text-secondary' : 'text-primary'
                            }
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                </>
              ))}
              {client.session ? (
                <Row>
                  <Row>
                    <Col>Part Two Status</Col>
                  </Row>
                  <Row>
                    <Col className="d-flex">View Purchasing Options</Col>
                  </Row>
                </Row>
              ) : null}
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center border-0 bg-white pb-64">
            <Button
              className="btn-outline-secondary py-16"
              onClick={handleBackToDashboardClick}
            >
              Back to Dashboard
            </Button>
          </Card.Footer>
        </Card>
        <AddUserModal clientId={id} show={show} setShow={setShow} />
      </div>
    </>
  );
};

export default ClientDetails;
