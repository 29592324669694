/* eslint-disable react/jsx-wrap-multilines */
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Field from 'components/global/Field/Field';
import schedulingSignal from 'signals/Scheduling.signal';

const AddAttendeeModal = ({
  show,
  toggleShow,
  onChangeAttendee,
  onSubmitNewAttendee,
}) => {
  const signal = schedulingSignal.value;

  return (
    <Modal
      show={show}
      centered
      contentClassName="rounded-0 border border-2 border-secondary bg-light"
      onHide={() => toggleShow()}
      size="lg"
      className="text-center"
    >
      <Modal.Header className="border-0 mb-0 pb-0" closeButton />
      <Modal.Body className="d-flex flex-column pb-32 rounded-0 text-primary px-80">
        <h3 className="mb-48">Add Attendee</h3>
        <Row className="mx-0 mb-40">
          <Col xs={6} className="ps-0 pe-16">
            <Field
              type="text-input"
              name="firstName"
              value={signal.newAttendee ? signal.newAttendee.firstName : ''}
              label={
                <span
                  className={
                    signal?.newAttendee?.firstName ? '' : 'text-danger'
                  }
                >
                  {signal?.newAttendee?.firstName
                    ? 'First Name'
                    : 'Please Add First Name *'}
                </span>
              }
              className="d-flex flex-column w-100"
              labelClassName="align-self-start fw-bold"
              inputClassName="w-100 fs-5 bg-transparent border-0 border-bottom border-primary border-1 rounded-0 py-0 ps-32"
              onChange={onChangeAttendee}
            />
          </Col>
          <Col xs={6} className="ps-16 pe-0">
            <Field
              type="text-input"
              name="lastName"
              value={signal.newAttendee ? signal.newAttendee.lastName : ''}
              label={
                <span
                  className={signal?.newAttendee?.lastName ? '' : 'text-danger'}
                >
                  {signal?.newAttendee?.lastName
                    ? 'Last Name'
                    : 'Please Add Last Name *'}
                </span>
              }
              className="d-flex flex-column w-100"
              labelClassName="align-self-start fw-bold"
              inputClassName="w-100 fs-5 bg-transparent border-0 border-bottom border-primary border-1 rounded-0 py-0 ps-32"
              onChange={onChangeAttendee}
            />
          </Col>
        </Row>
        <Row className="mx-0 mb-80">
          <Col xs={6} className="ps-0 pe-16">
            <Field
              type="text-input"
              name="email"
              value={signal.newAttendee ? signal.newAttendee.email : ''}
              label={
                <span
                  className={signal?.newAttendee?.email ? '' : 'text-danger'}
                >
                  {signal?.newAttendee?.email
                    ? 'Email Address'
                    : 'Please Add Email Addres *'}
                </span>
              }
              className="d-flex flex-column w-100"
              labelClassName="align-self-start fw-bold"
              inputClassName="w-100 fs-5 bg-transparent border-0 border-bottom border-primary border-1 rounded-0 py-0 ps-32"
              onChange={onChangeAttendee}
            />
          </Col>
          <Col xs={6} className="ps-16 pe-0">
            <Field
              type="text-input"
              name="role"
              value={signal.newAttendee ? signal.newAttendee.role : ''}
              label="Company Role"
              className="d-flex flex-column w-100"
              labelClassName="align-self-start fw-bold"
              inputClassName="w-100 fs-5 bg-transparent border-0 border-bottom border-primary border-1 rounded-0 py-0 ps-32"
              onChange={onChangeAttendee}
            />
          </Col>
        </Row>
        <div className="w-100 px-lg-128 px-md-72">
          <Button
            variant="secondary"
            className="py-16 px-24 rounded-0 text-primary"
            onClick={() => onSubmitNewAttendee()}
            disabled={
              !signal?.newAttendee?.firstName ||
              !signal?.newAttendee?.lastName ||
              !signal?.newAttendee?.email
            }
          >
            Add Session Attendee
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAttendeeModal;
