const quotes = [
  {
    id: 1,
    quote: 'Your brand’s job is not to speak to everyone in the world. Your brand’s job is to get the right prospect to the right table at the right time. The way to do that is to systematically speak to only the target audiences who will deeply resonate with your message.',
    page: '',
  }, {
    id: 2,
    quote: 'Even though everyone may potentially need what you have to offer, not everyone is going to care. Your brand should therefore speak to only the most critical target audiences whom you believe will account for the majority of your revenue.',
    page: '',
  }, {
    id: 3,
    quote: 'If you want to compel your target audiences to have a conversation with you, and if you desire to get the right prospects to the right table at the right time, then you must put some stakes in the ground and decide who you are and who you serve.',
    page: '',
  }, {
    id: 4,
    quote: 'You cannot be a chameleon and try to be like your competition. That will certainly not differentiate your organization in the marketplace. You must be you, and remember that by confidently owning who you are, you will attract confident buyers who equally own who they are and know what they need.',
    page: '',
  }, {
    id: 5,
    quote: 'Great brands aren’t pretentious. They are provocative. They stand on the truth without being arrogant or boastful.  They are simply confident, and they own their expertise.',
    page: '',
  }, {
    id: 6,
    quote: 'Great brands don’t tell stories or inundate people with paragraphs of information. Great brands create conversations that convert by delivering a series of short but powerful statements that give space for the target audience to respond.',
    page: '',
  }, {
    id: 7,
    quote: 'Whether you sit on the sales or marketing side of the organization, it doesn’t matter. It is critical to become aligned on what your brand says and to whom to ensure you work in tandem to create synergistic systems that increase your customer acquisition, create cross-functional efficiencies, and ultimately improve your bottom line.',
    page: '',
  }, {
    id: 8,
    quote: 'When used correctly, your Brand Trifecta will become the crux of every marketing and sales strategy leveraged by your organization. If not utilized, you will be throwing darts at a dartboard hoping you hit the mark.',
    page: '',
  }, {
    id: 9,
    quote: 'Brand is not marketing fluff. It is a tangible, practical, living and breathing thing that is your most powerful weapon in revenue generation.',
    page: '',
  }, {
    id: 10,
    quote: 'Your brand must speak to more than the features and benefits you provide. It must strategically hit on the ultimate pain your customer is experiencing and then state how you solve it.',
    page: '',
  }, {
    id: 11,
    quote: 'In creating a brand message that enables you to stand out from the competition, it is imperative to understand how you speak, sound, and show up. Your brand has a personality and tone of voice unique to you, and it is therefore critical to write your brand so that it sounds like you and feels like you.',
    page: '',
  }, {
    id: 12,
    quote: 'While it may seem like you’re leaving money on the table, the principle of niching in enables you to better prioritize your resources so you are simultaneously improving your top-line and bottom-line and. Your ability to make firm decisions regarding who you go after will ensure you build a brand for long-term success.',
    page: '',
  }, {
    id: 13,
    quote: 'The Brand Trifecta’s order for delivering a brand message ensures that you can provoke your target audiences to want to know more about you each step of the way. In fact, by not deviating from the order of The Brand Trifecta, each component of your brand message will systematically come together to create conversations that convert.',
    page: '',
  }, {
    id: 14,
    quote: 'Whether your Brand Trifecta is emblazoned on the homepage of your website, layered into your various marketing tactics, or said aloud in the Zoom room, the goal is to get to the point in less than 20 seconds so that someone understands what you do, how you solve their problem, and how you’re different from the competition.',
    page: '',
  }, {
    id: 15,
    quote: 'Great brands do not inundate people with paragraphs of information. They deliver a series of short but powerful statements that give space for the target audience to respond.',
    page: '',
  }, {
    id: 16,
    quote: 'Your Tagline should be provocative and specifically tell your audience what you do. If there is any place where you should own your expertise, it is here! Now is not the time to worry that you’ll come across as arrogant. Be audacious in stating the promise of how you serve your customers.',
    page: '',
  }, {
    id: 17,
    quote: 'Your Value Proposition Statement isn’t about you. It is about your targeted audiences. Focus on speaking directly to their pain, instead of using words like “our” or “we,” and then say how you will solve that pain. Keep it clear, simple, and impactful.',
    page: '',
  }, {
    id: 18,
    quote: 'Once you have told your audiences what you do (Tagline), and how you solve their problem (Value Proposition Statement), you must serve up the facts of how you are different and better than the rest. This is where your Differentiator Statements come into play.',
    page: '',
  }, {
    id: 19,
    quote: 'Everything you do when writing your Brand Trifecta is about delivering a message that gets the right prospect to the right table at the right time. It isn’t about telling a story. It’s about having a conversation that converts prospects into customers.',
    page: '',
  }, {
    id: 20,
    quote: 'Whether you deliver it digitally or face-to-face, remember that until your target audiences hear your Brand Trifecta, they are not ready to hear about your products or services. Embrace this and stick with the Brand Trifecta formula to bring your target audiences through the buyer journey to where they do want to know more.',
    page: '',
  }, {
    id: 21,
    quote: 'Brand is the path of least resistance to revenue, but only if it is put into the world. No matter your role, you have a responsibility to positively impact your company&apos;s growth by getting your brand to market as fast as possible.',
    page: '',
  }, {
    id: 22,
    quote: 'A good rule of thumb for implementing your brand is that if you update it because your company colors or logo have changed, it should be updated because your brand message has changed.',
    page: '',
  }, {
    id: 23,
    quote: 'A second rule of thumb for deploying your brand is to not deviate. Implement your Brand Trifecta across all your channels so that your brand is a clear, consistent message that invites your customers, employees, and partners to have a conversation with you.',
    page: '',
  }, {
    id: 24,
    quote: 'Stay persistent, consistent, and timely in implementing your brand. Get it done quickly and don’t allow your brand to live in a box. Show it. Speak it. Live it.',
    page: '',
  },
];

export default quotes;
