import multiSelectData from 'components/views/TableMultiSelect/components/data';

const defaultTable1 = {
  headerRows: 1,
  body: [
    [{ text: 'Word/Phrase', style: 'tableHeader', alignment: 'center', margin: [12, 6, 12, 6] }, { text: 'Supporting Sentences', style: 'tableHeader', margin: [12, 6, 12, 6] }],
    [{ text: 'Template Function' },
      'Column 2'],
    [{ text: 'One value goes here', margin: [12, 6, 12, 6] }, 'Another one here'],
  ],
};

const differentialStatementsTable = (values) => {
  const { sentences, words } = values;
  let tableLengthArray;
  const sentenceLength = sentences.length;
  const wordsLength = words.length;
  if (words.length === sentenceLength) {
    tableLengthArray = new Array(wordsLength).fill(0);
  }
  if (words.length < sentenceLength) {
    tableLengthArray = new Array(wordsLength).fill(wordsLength);
  }
  if (words.length > sentenceLength) {
    tableLengthArray = new Array(sentenceLength).fill(sentenceLength);
  }
  return ({
    headerRows: 1,
    widths: ['*', '*'],
    body: [
      [
        { text: 'Word/Phrase', style: 'tableHeader', alignment: 'center', margin: [12, 6, 12, 6], bold: true }, { text: 'Supporting Sentences', style: 'tableHeader', margin: [12, 6, 12, 6], bold: true }],
      ...tableLengthArray.map((value, index) => ([{ text: `${index + 1}.  ${words[index]}`, margin: [12, 6, 12, 6] }, sentences[index]])),
    ],
  });
};

const topTargetAudienceTable = (values) => {
  const topTargetAudience = { ...values };
  delete topTargetAudience.rows;
  return ({
    widths: ['*'],
    body: [
      ...Object.keys(topTargetAudience).map((value, index) => [
        { text: `${index + 1}. ${topTargetAudience[value]}`, margin: [12, 6, 12, 6] }]),
    ],
  });
};

const topTaglineTable = (values) => ({
  headerRows: 1,
  widths: ['*'],
  body: [
    [{ text: 'Top Taglines', alignment: 'center', fontSize: 16, bold: true, margin: [12, 6, 6, 12] }],
    ...Object.keys(values).map((value, index) => (
      [
        { text: `${index + 1}. ${values[`${index}`]}`, fontSize: 12, alignment: 'left' },
      ]
    )),
  ],
});

const fiveWhysTable = (values) => {
  if (Object.keys(values).includes('0') || Object.keys(values).includes('1')) {
    const tabValues = Object.keys(values);
    const table = [];
    tabValues.forEach((version, idx) => {
      if (version === 'tabs') return;
      table.push(
        [{ text: values[version].title, bold: true, margin: [12, 6, 12, 6], fontSize: 10 }],
        [{ text: `${values[version]['needs-1']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values[version]['needs-2']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values[version]['needs-3']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values[version]['needs-4']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values[version]['needs-5']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Bottom Line Needs (AKA the Heart Pain You Solve)', bold: true, margin: [12, 6, 12, 6], fontSize: 10 }],
        [{ text: values[version].bottomLineNeeds, margin: [12, 6, 12, 6], fontSize: 8 }],
      );
      if (tabValues.length - 1 !== idx) {
        table.push([{ text: '', border: [false, false, false, true], margin: [0, 0, 0, 20] }]);
      }
    });
    return ({
      widths: ['*'],
      body: [
        ...table,
      ],
    });
  }
  return (
    {
      widths: ['*'],
      body: [
        [{ text: 'Bottom Line Needs for Well-Funded and Founder-Led Start Ups', bold: true, margin: [12, 6, 12, 6], fontSize: 10 }],
        [{ text: `${values['needs-1']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values['needs-2']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values['needs-3']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values['needs-4']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: `${values['needs-5']}`, margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Why?', margin: [12, 6, 12, 6], fontSize: 8 }],
        [{ text: 'Bottom Line Needs (AKA the Heart Pain You Solve)', bold: true, margin: [12, 6, 12, 6], fontSize: 10 }],
        [{ text: values.bottomLineNeeds, margin: [12, 6, 12, 6], fontSize: 8 }],
      ],
    });
};

const brandArchetypeTable = (values) => {
  const filteredData = multiSelectData.filter((item, idx) => values.includes(idx.toString()));
  return ({
    headerRows: 1,
    keepWithHeaderRows: '*',
    keepWithFooterRows: '*',
    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
    body: [
      [
        { text: 'Archetypes', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: 'Drivers', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: 'Values', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: 'Goals', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: 'Fears', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: 'Order', style: 'tableHeader', bold: true, padding: [12, 6] },
      ],
      ...filteredData.map((value, index) => (
        [
          { text: value.title, fontSize: 8, padding: [12, 6] },
          { text: value.lines[0], fontSize: 8, padding: [12, 6] },
          { text: value.lines[1], fontSize: 8, padding: [12, 6] },
          { text: value.lines[2], fontSize: 8, padding: [12, 6] },
          { text: value.lines[3], fontSize: 8, padding: [12, 6] },
          { text: `${index + 1}`, fontSize: 8, padding: [12, 6] },
        ]
      )),
    ],
  });
};

const textAreaTable = (values) => ({
  headerRows: 1,
  widths: ['*'],
  body: [
    [{ text: values.title?.toUpperCase(), alignment: 'center', fontSize: 14, bold: false, margin: [12, 6, 6, 28] }],
    [{ text: values.value, fontSize: 12, alignment: 'left' }],
  ],
});

const personalityChartTable = (values) => ({
  headerRows: 1,
  widths: ['auto', '*', '*', '*', '*', '*', 'auto'],
  body: [
    [
      { text: 'Characteristics', style: 'tableHeader', colSpan: 1, bold: true, padding: [12, 6] },
      { text: '1', style: 'tableHeader', colSpan: 1 },
      { text: '2', style: 'tableHeader', colSpan: 1 },
      { text: '3', style: 'tableHeader', colSpan: 1 },
      { text: '4', style: 'tableHeader', colSpan: 1 },
      { text: '5', style: 'tableHeader', colSpan: 1 },
      { text: 'Characteristics', style: 'tableHeader', colSpan: 1, bold: true, padding: [12, 6] },
    ],
    ...values.rows.map((value) => (
      [
        { text: value.labelOne, style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: value.selectedValue.toString() === '1' ? 'X' : '', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: value.selectedValue.toString() === '2' ? 'X' : '', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: value.selectedValue.toString() === '3' ? 'X' : '', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: value.selectedValue.toString() === '4' ? 'X' : '', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: value.selectedValue.toString() === '5' ? 'X' : '', style: 'tableHeader', bold: true, padding: [12, 6] },
        { text: value.labelTwo, style: 'tableHeader', bold: true, padding: [12, 6] },
      ]
    )),
  ],
});

export const buyerCriteriaTable = (values) => {
  const tabValues = values.tabs || [1];
  const table = [];
  const valuesLength = tabValues.length - 1;
  tabValues.forEach((_, index) => {
    Object.keys(values[`targetAudience${index}`]).map((audience, idx) => {
      if (idx === 0) {
        table.push([{ text: values[`targetAudience${index}Name`], bold: true, style: 'tableHeader' }]);
      }
      table.push([{ text: values[`targetAudience${index}`][`audience${idx}`] }]);
      if (idx === Object.keys(values[`targetAudience${index}`]).length - 1 && index !== valuesLength) {
        table.push([{ text: '', border: [false, false, false, true], margin: [0, 0, 0, 20] }]);
      }
      return 'succes';
    });
  });
  return ({
    widths: ['*'],
    pageBreak: 'after',
    body: [
      ...table,
    ],
  });
};

const taglineWithTabsTable = (values) => {
  const tabValues = Object.keys(values);
  const table = [];
  const valuesLength = tabValues.length - 1;
  tabValues.forEach((theme, index) => {
    Object.keys(values[theme]).map((tagline, idx) => {
      if (idx === 0) {
        table.push([{ text: `Top Tagline ${idx + 1}`, bold: true, style: 'tableHeader' }]);
      }
      table.push([{ text: values[theme][tagline] }]);
      if (idx === Object.keys(values[theme]).length - 1 && index !== valuesLength) {
        table.push([{ text: '', border: [false, false, false, true], margin: [0, 0, 0, 20] }]);
      }
      return 'succes';
    });
  });
  return ({
    widths: ['*'],
    pageBreak: 'after',
    body: [
      ...table,
    ],
  });
};

export {
  defaultTable1,
  differentialStatementsTable,
  topTargetAudienceTable,
  fiveWhysTable,
  brandArchetypeTable,
  textAreaTable,
  personalityChartTable,
  topTaglineTable,
  taglineWithTabsTable,
};
