import selectPlanSignal from 'signals/SelectPlan.signal';
import alertSignal from 'signals/Alert.signal';

const formatExpirationDate = (value) => value.replace(
  /[^0-9]/g, '', // To allow only numbers
).replace(
  /^([2-9])$/g, '0$1', // To handle 3 > 03
).replace(
  /^(1{1})([3-9]{1})$/g, '0$1/$2', // 13 > 01/3
).replace(
  /^0{1,}/g, '0', // To handle 00 > 0
)
  .replace(
    /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2', // To handle 113 > 11/3
  );

export const handleFormChange = ({ name, value }) => {
  selectPlanSignal.update({
    [name]: value,
  });
};

export const handleCardNumberChange = () => {
  // TODO: Need to have a way to validate card numbers.
};

export const handleCvcChange = ({ value }) => {
  if (value.length <= 3 && !!Number(value)) {
    selectPlanSignal.update({
      cvc: value,
    });
  }
};

export const handleExpirationDateChange = (value) => {
  handleFormChange({ name: 'expirationDate', value: formatExpirationDate(value) });
};

export const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    // TODO: This block needs to bring up something like a payment method modal
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  }
};
