import { Form, Col } from 'react-bootstrap';

const Checkbox = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  checkboxClassName,
}) => {
  const handleSelect = () => {
    onChange({ name, value: !value });
  };

  return (
    <Form.Check
      id="name"
      type="checkbox"
      className={`d-flex align-items-center ps-0 mb-0 ${className}`}
    >
      <Col className="px-0">
        <Form.Check.Input
          type="checkbox"
          checked={!!value}
          className={`m-0 me-8 ${checkboxClassName}`}
          onChange={() => handleSelect(value)}
        />
      </Col>
      <Form.Check.Label className={`mb-0 ${labelClassName}`}>
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default Checkbox;
