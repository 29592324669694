import { Form } from 'react-bootstrap';

const TextInput = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
  disabled,
}) => (
  <div className={className}>
    {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
    <div>
      <Form.Control
        name={name}
        id="input"
        value={value}
        placeholder={placeholder}
        className={inputClassName}
        onChange={onChange}
        disabled={disabled || false}
      />
    </div>
  </div>
);

export default TextInput;
