import { Modal, Button } from 'react-bootstrap';

const MismatchedPasswordsModal = ({
  show,
  closeModal,
}) => (
  <Modal
    show={show}
    centered
    contentClassName="rounded-0 border border-2 border-secondary bg-light"
    onHide={closeModal}
    className="text-center"
  >
    <Modal.Header className="border-0 mb-0 pb-0" closeButton />
    <Modal.Body className="d-flex flex-column pb-32 rounded-0 text-primary">
      <h3>Passwords Do Not Match</h3>
      <div className="px-lg-80">
        <p>Please try again</p>
      </div>
      <div className="w-100 px-lg-128 px-md-72">
        <Button
          variant="secondary"
          className="w-100 py-8 rounded-0 text-primary"
          onClick={closeModal}
        >Continue
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default MismatchedPasswordsModal;
