/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import userSignal from 'signals/User.Signal';
import { PROGRAMS_NAMES, desiredOrder, excludedIds } from 'constants/index';
import brandTrifectaLogo from '../../../../../images/brand-trifecta-logo.png';
import { differentialStatementsTable, topTargetAudienceTable, fiveWhysTable, brandArchetypeTable, textAreaTable, personalityChartTable, buyerCriteriaTable, topTaglineTable, taglineWithTabsTable } from './tableTemplates';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  JosefinSans: {
    normal: 'https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf',
    bold: 'https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XXMFrLgTsQV0.ttf',
  },
};

const switchTable = (tableStyle, values) => {
  switch (tableStyle.toLowerCase()) {
    case 'muticolumntable':
      return {
        table: differentialStatementsTable(values),
        title: 'DIFFERENTIATOR STATEMENTS',
      };
    case 'multicolumntable':
      return {
        table: differentialStatementsTable(values),
        title: 'DIFFERENTIATOR STATEMENTS',
      };
    case 'toptargetaudience':
      return {
        table: topTargetAudienceTable(values),
        title: 'TOP TARGET AUDIENCES',
      };
    case 'fivewhys':
      return {
        table: fiveWhysTable(values),
        title: 'FIVE WHYS',
      };
    case 'brandarchetype':
      return {
        table: brandArchetypeTable(values),
        title: "OUR BRAND'S TOP THREE ARCHETYPES",
      };
    case 'tablemultiselect':
      return {
        table: brandArchetypeTable(values),
        title: "OUR BRAND'S TOP THREE ARCHETYPES",
      };
    case 'repeatertextinputtable':
      return {
        table: topTargetAudienceTable(values),
        title: 'TOP TARGET AUDIENCES',
      };
    case 'targettablebuyercriteria':
      return {
        table: buyerCriteriaTable(values),
        title: 'BUYER CRITERIA',
      };
    case 'textarea':
      return {
        table: textAreaTable(values),
        title: '',
        type: 'textarea',
      };
    case 'personalitychart':
      return {
        table: personalityChartTable(values),
        title: "OUR BRAND'S PERSONALITY TRAITS",
      };
    case 'inputgroupsrepeaterwithtabsinput2':
      return {
        table: taglineWithTabsTable(values),
        title: 'TAGLINES',
      };
    case 'toptagline':
      return {
        table: topTaglineTable(values),
        title: 'TOP TAGLINES',
      };
    default:
      return {
        table: textAreaTable(values),
        title: '',
      };
  }
};

export const TableTemplateDocument = (tableStyle, values, name) => {
  const tableArray = [];
  let title; let table;
  let tableData;

  if (tableStyle === 'combined') {
    const isFiveTrfectas = name === PROGRAMS_NAMES.fiveTrifectas;

    if (isFiveTrfectas) {
      values = desiredOrder.map(id => values.find(obj => obj.recordId === id));
    }

    values.forEach((value) => {
      const isTextArea = value.type === 'textarea' || value.type === 'textArea';

      if ((isFiveTrfectas && !excludedIds.includes(value.recordId)) || (!isFiveTrfectas)) {
        tableData = isTextArea
          ? switchTable(value.type, { title: value.prompt, value: value.value })
          : switchTable(value.type, value.value);

        tableArray.push(tableData);
      }
    });
  } else {
    tableData = switchTable(tableStyle, values);
    title = tableData.title;
    table = tableData.table;
  }
  const orgIdFromLS = window.localStorage.getItem('organizationId');
  const { userData } = userSignal.value;
  const organization = userData?.orgData?.filter(org => org.id === Number(orgIdFromLS));
  const brandNameTitle = organization[0]?.name ? organization[0].name : '';

  return ({
    background() {
      return [
        {
          canvas: [
            { type: 'line', x1: 0, y1: 0, x2: 595, y2: 0, lineWidth: 6, lineColor: '#B2A05E' }, // Up line
            { type: 'line', x1: 0, y1: 0, x2: 0, y2: 841, lineWidth: 6, lineColor: '#B2A05E' }, // Left line
            { type: 'line', x1: 0, y1: 841, x2: 595, y2: 841, lineWidth: 6, lineColor: '#B2A05E' }, // Bottom line
            { type: 'line', x1: 595, y1: 0, x2: 595, y2: 841, lineWidth: 6, lineColor: '#B2A05E' }, // Rigth line
          ],

        },
      ];
    },
    footer: {
      columns: [
        { text: 'Copyright © 2023 Brand Trifecta®', alignment: 'center', color: '#33394A' },
      ],
      margin: [0, 20, 0, 24],
    },
    content: [
      {
        image: brandTrifectaLogo,
        alignment: 'center',
        margin: [0, 16, 0, 24],
        width: 150,
        height: 75,
      },
      { text: brandNameTitle, alignment: 'center', fontSize: 16, bold: true, border: [false, false, false, false], margin: [0, 0, 0, 24] },
      tableArray.length > 0 ?
        tableArray.map((arrayTable) => ({
          table: {
            widths: ['*'],
            alignment: 'center',
            body: [
              [{ text: arrayTable.title, alignment: 'center', margin: [0, 16, 0, 0], fontSize: 14, headlineLevel: 1 }],
              [
                {
                  table: arrayTable.table,
                  layout: arrayTable?.type?.toLowerCase() === 'textarea' ? 'noBorders' : {
                    hLineWidth() { return 1; },
                    vLineWidth() { return 1; },
                  },
                  margin: [32, 32, 32, 48],
                },
              ],
            ],
          },
          layout: {
            hLineWidth(i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 0;
            },
            vLineWidth() { return 1; },
            hLineColor() { return '#B2A05E'; },
            vLineColor() { return '#B2A05E'; },
          },
          margin: [0, 0, 0, 24],
          pageBreak: 'after',
        }))
        :
        {
          table: {
            widths: ['*'],
            body: [
              [{ text: title, alignment: 'center', margin: [0, 16, 0, 0], fontSize: 14 }],
              [
                {
                  table,
                  layout: tableStyle.toLowerCase() === 'textarea' ? 'noBorders' : {
                    hLineWidth() { return 1; },
                    vLineWidth() { return 1; },
                  },
                  margin: [32, 32, 32, 32],
                },
              ],
            ],
          },
          layout: {
            hLineWidth(i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 0;
            },
            vLineWidth() { return 1; },
            hLineColor() { return '#B2A05E'; },
            vLineColor() { return '#B2A05E'; },
          },
          margin: [24, 24, 24, 24],
        },
    ],
    defaultStyle: {
      font: 'JosefinSans',
    },
    // pageBreakBefore: function (currentNode) {
    //   if (currentNode.table && currentNode.table.pageBreak) {
    //     console.log('table', currentNode);
    //     return true;
    //   }
    //   return false;
    // },
  });
};

export default TableTemplateDocument;
