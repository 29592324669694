/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import TableMultiSelectResultsTable from './components/MultiSelectResultsTable';
import { getPromptValueFromProgramSignal } from '../Sessions/Session.helpers';

const TableMultiSelectResults = ({ prompt }) => {
  const [checkedArray, setCheckedArray] = React.useState([]);
  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    setCheckedArray(values);
  }, [prompt]);

  return (
    <div className="d-flex flex-column h-100">
      <div
        className="bg-white py-32"
        style={{ border: '3px solid var(--secondary, #B2A05E)' }}
      >
        <div className="container-fluid py-32">
          <div>
            <h5 className="text-uppercase mb-32" style={{ fontSize: '18px' }}>
              Brand Archetypes
            </h5>
            <TableMultiSelectResultsTable inputData={checkedArray} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableMultiSelectResults;
