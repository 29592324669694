import React, { useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Button } from 'react-bootstrap';
import createSessionPdf from './PDFBuildUtility';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exampleDiff = ['1', '3', '5'];
const fiveWhys = {
  'needs-5': 'Stone Cold',
  'needs-4': 'And 4??',
  'needs-1': 'or maybe noy',
  'needs-3': 'two and three?',
  'needs-2': 'Did I not add',
  bottomLineNeeds: 'Thats the bottom line cause',
};
const diffentiators = new Array(5).fill({ word: 'Commitment post event every info dog cant.', sentence: 'Commitment post event every info dog cant.' });
const topTarget = {
  1: 'dddfrwew',
  2: 'ergggrwedns',
  3: 'Another Additional Row',
  4: 'And One more additional row',
  rows: [
    '1',
    '2',
    '3',
    4,
  ],
};
const PDFViewer1 = () => {
  const [pdf, setPdf] = useState(null);
  useEffect(() => {
    const pdfDocGenerator = pdfMake.createPdf(createSessionPdf('Example Brand Name', fiveWhys).createFiveWhysPdf());
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setPdf(dataUrl);
      return dataUrl;
    });
  }, []);

  const handleFiveWhys = () => {
    const pdfDocGenerator = pdfMake.createPdf(createSessionPdf('Example Brand Name', fiveWhys).createFiveWhysPdf());
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setPdf(dataUrl);
      return dataUrl;
    });
  };

  const handleTopTarget = () => {
    const pdfDocGenerator = pdfMake.createPdf(createSessionPdf('Example Brand Name', topTarget).createTopTargetAudiencePdf());
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setPdf(dataUrl);
      return dataUrl;
    });
  };

  const handleDifferentiators = () => {
    const pdfDocGenerator = pdfMake.createPdf(createSessionPdf('Example Brand Name', diffentiators).createDifferentiatorStatementsPdf());
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setPdf(dataUrl);
      return dataUrl;
    });
  };

  const handleBrandArchetype = () => {
    const pdfDocGenerator = pdfMake.createPdf(createSessionPdf('Example Brand Name', exampleDiff).createBrandArchetypePdf());
    pdfDocGenerator.getDataUrl((dataUrl) => {
      console.log(dataUrl);
      setPdf(dataUrl);
      return dataUrl;
    });
  };

  return (
    <div className="text-center mb-16">
      <h2 className="mt-32">PDF Template Viewer</h2>
      <div className="text-center mb-16">
        <Button className="mx-8" onClick={() => handleFiveWhys()}>Five Why's</Button>
        <Button className="mx-8" onClick={() => handleTopTarget()}>Top Target</Button>
        <Button className="mx-8" onClick={() => handleDifferentiators()}>Differentiator</Button>
        <Button className="mx-8" onClick={() => handleBrandArchetype()}>Brand Archetype</Button>

      </div>
      <div className="text-center">
        <iframe className="vh-100 w-100" id="pdViewer" title="tt" src={pdf} />
      </div>
    </div>
  );
};

export default PDFViewer1;
