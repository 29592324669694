import { Card, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import TopBar from 'components/global/TopBar';
import PlaybooksTab from './components/PlaybooksTab';
import ResourcesTab from './components/ResourcesTab';

const PlaybooksAndResources = () => {
  const history = useHistory();

  const handleBackToDashboardClick = () => {
    history.push('/dashboard');
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBar />
      <div
        className="position-relative w-100 px-lg-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header
            className="h3 bg-success text-primary text-center text-uppercase rounded-0 py-32"
            as="h3"
          >
            Playbooks and Resources
          </Card.Header>
          <Card.Body className="px-xl-80 px-24 pt-xl-80 pt-md-64">
            <Row className="p-0 m-0 w-100">
              <Col xs={12} className="px-0 h-100">
                <Row className="w-100 m-0 pb-16 justify-content-center">
                  <Tabs
                    defaultActiveKey="playbooks"
                    id="playbooks-tab"
                    className="mb-3 border-0 px-8"
                  >
                    <Tab
                      key="playbooks"
                      eventKey="playbooks"
                      title="Playbooks"
                      tabClassName="lead-600 border-0"
                    >
                      <PlaybooksTab />
                    </Tab>
                    <Tab
                      eventKey="resources"
                      title="Resources"
                      tabClassName="lead-600 border-0"
                    >
                      <ResourcesTab />
                    </Tab>
                  </Tabs>
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center border-0 bg-white pb-64">
            <Button
              className="btn-outline-secondary bg-success border-success py-16"
              onClick={handleBackToDashboardClick}
            >
              Back to Dashboard
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

export default PlaybooksAndResources;
