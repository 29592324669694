import { Col, Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';

const dummyData = {
  sessionNumber: 5,
  title: 'brand architecture selection',
  instructions:
    'Please select the brand trifecta that fits best with your company',
  subtitle: 'Brand Architectures',
  prompt: [
    {
      id: 0,
      columnOneLabel: 'Single Brand Trifecta',
      columnOneText:
        'For an organization needing a single Brand Trifecta that speaks to all of their target audiences, and where all target audiences have the same bottom-line heart pain which the brand solves.',
      imageSrc: '/images/single-brand-trifecta.svg',
    },
    {
      id: 1,
      columnOneLabel: 'Two Brand Trifectas',
      columnOneText:
        'For an organization needing two Brand Trifectas - one for their B2B sales channel and another for their B2C sales channel. Each Brand Trifecta speaks to how the brand solves the bottom-line heart pain for each channel’s respective target audiences.',
      imageSrc: '/images/two-brand-trifecta.svg',
    },
    {
      id: 2,
      columnOneLabel: 'Three Brand Trifectas',
      columnOneText:
        'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top two target audiences. These supporting Brand Trifectas speak to how the brand solves each target audience’s bottom-line heart pain.',
      imageSrc: '/images/three-brand-trifecta.svg',
    },
    {
      id: 3,
      columnOneLabel: 'Four Brand Trifectas',
      columnOneText:
        'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top two target audiences. These supporting Brand Trifectas speak to how the brand solves each target audience’s bottom-line heart pain.',
      imageSrc: '/images/four-brand-trifecta.svg',
    },
    {
      id: 4,
      columnOneLabel: 'Five Brand Trifectas',
      columnOneText:
        'For a brand that not only needs an organizational Brand Trifecta that speaks to everyone, but two supporting Brand Trifectas - one for each of the brand’s top two target audiences. These supporting Brand Trifectas speak to how the brand solves each target audience’s bottom-line heart pain.',
      imageSrc: '/images/five-brand-trifecta.svg',
    },
  ],
};

const TableRadioWithImageReview = ({ prompt }) => {
  const [results, setResults] = useState(null);

  useEffect(() => {
    const newResults = getPromptValueFromProgramSignal(prompt);
    if (newResults && newResults !== undefined) {
      setResults(newResults);
    }
  }, [prompt]);

  return (
    <Container
      className="border-secondary  pt-48 pb-112 rounded-0 bg-light  "
      style={{ borderWidth: '3px' }}
    >
      <Row className="text-center">
        <p className="h4 text-primary text-uppercase mb-0">{dummyData.title}</p>
        <div
          className="bg-secondary w-25 mx-auto"
          style={{ height: '4px', margin: '5px' }}
        />
      </Row>
      <div className="border border-secondary border-2 px-40 pb-40 pt-32 mt-56">
        <Container className="border border-primary border-2 mt-32">
          {dummyData.prompt.map((row, index) => (
            <Row className={index % 2 === 0 ? 'bg-white' : 'bg-light'}>
              <Col xs={5}>
                <div
                  style={{ paddingLeft: 32, paddingRight: 16 }}
                  className="d-flex h-100 w-100 justify-content-end align-items-center"
                >
                  <div className="mt-80 mb-64">
                    <p className="lead-600 text-uppercase">
                      {row.columnOneLabel}
                    </p>
                    <p className="base">{row.columnOneText}</p>
                  </div>
                </div>
              </Col>
              <Col xs={7}>
                <div
                  className="d-flex h-100 justify-content-center align-items-center"
                  style={{ position: 'relative' }}
                >
                  <img alt="img" src={row.imageSrc} className="img-fluid" />
                  <img
                    alt="img"
                    hidden={results !== row.id}
                    src="/images/selected-checkbox.svg"
                    style={{ position: 'absolute', right: 0, top: 16 }}
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </Container>
  );
};

export default TableRadioWithImageReview;
