/* eslint-disable indent */
import { useState, useEffect } from 'react';
import { Navbar, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { signOut } from 'utils/auth';
import userSignal from 'signals/User.Signal';
import resetAllSignalsOnSignout from 'components/global/TopBar/TopBar.helpers';
import AddClientModal from '../agency-modals/AddClientModal';
import logo from '../../../../../images/BT-Main.svg';

const TopBarAgency = ({ modal }) => {
  const [addClientModal, setAddClientModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { userData } = userSignal.value;

  const history = useHistory();

  useEffect(() => {
    if (modal === 'ControlPanelModal') {
      setExpanded(true);
    }
  }, [modal]);

  const handleRouter = (path) => {
    history.push(path);
  };

  return (
    <Navbar
      className="d-flex justify-content-between w-100 bg-light mx-0 px-80 font-josefin"
      style={{ height: '9rem' }}
    >
      <Navbar.Brand href="/dashboard" className="h-100">
        <img
          src={logo}
          alt=""
          style={{ height: '100%', objectFit: 'contain' }}
        />
      </Navbar.Brand>
      <div className="d-flex align-items-center">
        <Dropdown
          as={ButtonGroup}
          show={expanded}
          style={{ zIndex: expanded ? 2000 : 0 }}
          onClick={() => setExpanded(!expanded)}
        >
          <Dropdown.Toggle className="d-flex align-items-center bg-light border border-2 border-secondary-light rounded-0 py-8">
            <div className="d-flex flex-column text-start ps-16">
              <p className="mb-0 text-primary-light">Brand Trifecta Portal</p>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <p className="mb-0 text-primary fw-bold">View Clients</p>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-primary-light"
                />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="bg-light w-100 rounded-0 border border-2 border-secondary-light"
            style={{ transform: 'translate(0, -3px)', zIndex: 2000 }}
          >
            <Dropdown.Item
              eventKey="2"
              className="text-primary"
              onClick={() => handleRouter('/settings')}
            >
              Settings
            </Dropdown.Item>
            {userData?.role?.name === 'Operations' ? (
              <Dropdown.Item
                eventKey="4"
                className="text-primary"
                onClick={() => history.push('/editor/programs')}
              >
                Editor Mode
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item
              eventKey="4"
              className="text-primary"
              onClick={() => {
                window.localStorage.clear('creds');
                resetAllSignalsOnSignout();
                signOut();
              }}
            >
              Logout
            </Dropdown.Item>
            {userData?.orgData?.map((org) => {
              if (org.type === 'agency') {
                return null;
              }
              return (
                <Dropdown.Item
                  key={org.id}
                  onClick={() => {
                    window.localStorage.setItem(
                      'creds',
                      JSON.stringify({
                        isAgency: false,
                        userType: userData?.role?.name,
                      }),
                    );
                    window.localStorage.setItem('organizationId', org.id);

                    window.location.href = '/dashboard';
                  }}
                >
                  {org.name}
                </Dropdown.Item>
              );
            })}
            <Dropdown.Item
              onClick={() => setAddClientModal(true)}
              className="text-secondary"
            >
              + Add Client
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <AddClientModal show={addClientModal} setShow={setAddClientModal} />
    </Navbar>
  );
};

export default TopBarAgency;
