import { Form } from 'react-bootstrap';

const Email = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
}) => (
  <div className={className}>
    {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
    <div>
      <Form.Control
        name={name}
        type="email"
        value={value}
        id="inputEmail"
        className={inputClassName}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  </div>
);

export default Email;
