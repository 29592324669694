/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
// import { css } from '@emotion/react';
import GlobalCalendar from 'components/global/GlobalCalendar';
import usePageReloadDetector from 'hooks/usePageReloadDetector';
import { fetchAndSetProgramData } from 'components/views/Dashboard/Dashboard.helpers';
import userSignal from 'signals/User.Signal';
import TopBarAgency from 'components/views/Dashboard/agency-dashboard/agency-components/TopBarAgency';
import {
  agencyScheduleSignal,
  getAgencySchedules,
} from '../../Dashboard/card-components/courseCalendar.helpers';

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const AgencyScheduleList = () => {
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState();
  const eventsData = agencyScheduleSignal.value;
  const { userData } = userSignal.value;

  const isReloaded = usePageReloadDetector();

  const refetch = useCallback(async () => {
    if (isReloaded) {
      await Promise.all([
        await fetchAndSetProgramData(),
        await getAgencySchedules({ orgData: userData?.orgData }),
      ]);
    }
  }, [isReloaded]);

  useEffect(() => {
    refetch();
  }, [isReloaded]);

  useEffect(() => {
    if (eventsData?.length > 0) {
      const filteredData = eventsData.map((event) => {
        const dateString = event.date;
        const dateObj = new Date(dateString);
        const timeString = dateObj.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
        const splittedDate = dateObj.toISOString().split('T')[0].split('-');
        event.day = splittedDate[2];
        event.month = splittedDate[1] - 1;
        event.time = timeString;
        return event;
      });
      setFilteredEvents(filteredData);
      setEvents(eventsData);
    }
  }, [eventsData]);

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      <TopBarAgency />
      <div
        className="position-relative w-100 px-xl-128 px-md-40 px-32 pb-64 pt-136 calendar"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Card className="rounded-0 border-0">
          <Card.Header className="bg-success text-primary text-center rounded-0 py-32">
            <h3 className="h3 mb-0 text-uppercase">Session Schedule</h3>
          </Card.Header>
          <Card.Body className="p-0 m-0">
            <Row className="w-100 py-80 p-0 m-0">
              <Col lg={7} xs={12} className="ps-64">
                {filteredEvents &&
                  filteredEvents.map((session, idx) => (
                    <Row
                      key={idx}
                      className="w-100 py-40 px-48 m-0 border-bottom position-relative session-row"
                      style={{ borderBottom: '2px solid #33394A26' }}
                    >
                      <div
                        className="position-absolute fs-6 top-0 end-0 pt-8 pe-8 text-end change-date"
                        style={{ display: 'none' }}
                      >
                        Change date <FontAwesomeIcon icon={faPencil} />
                      </div>
                      <Col xs={2} className="text-center">
                        <p className="lead fs-3 fw-bold mb-0">{session.day}</p>
                        <p className="lead">{monthNames[session.month]}</p>
                      </Col>
                      <Col xs={10} className="pt-16">
                        <Row>
                          <Col xs={10}>
                            <h5 className="fw-semibold">{session.orgName}</h5>
                            <p className="base">{session.time}</p>
                            <p className="base fw-bold">{session.title}</p>
                            <p className="base">{session.description}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
              </Col>
              <Col lg={5} xs={12} className="ps-32 pe-40 pt-64">
                <GlobalCalendar eventsData={events} setEvents={setEvents} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="text-center w-100 my-40">
          <Button
            className="btn-outline-primary"
            onClick={() => history.push('/dashboard')}
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    </>
  );
};

export default AgencyScheduleList;
