/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { Container, Card, Form, Col, Row } from 'react-bootstrap';
import { screenSignal, switchPromptType } from '../Session.helpers';

const SessionPrompts = () => (
  <Container>
    <Card
      className="border-secondary pt-xl-80 pt-48 pb-32 rounded-0 px-lg-40 px-32 bg-light"
      style={{ borderWidth: '3px' }}
    >
      {/* <div className="fs-4 text-primary text-uppercase text-center fw-bold">
        {screenSignal.value.session?.[0].title}
      </div>
      <div
        className="bg-secondary w-25 my-16 mx-auto"
        style={{ height: '1px' }}
      />
      <div className="text-dark lead-400 mb-lg-48 mb-32 text-center">
        {screenSignal.value.title}
      </div> */}
      {screenSignal.value.prompts?.map((prompt) => (
        <Row className="mb-lg-16 mb-lg-48" key={prompt.uid}>
          <Col
            className={
              prompt.type === 'textarea'
                ? 'offset-lg-3 col-lg-6 col-12'
                : 'col-12'
            }
          >
            <Form.Label className="text-dark lead-400 text-uppercase">
              {prompt.prompt}
            </Form.Label>
            {switchPromptType({ type: prompt.type, prompt })}
          </Col>
        </Row>
      ))}
      {screenSignal.value?.prompts[0]?.instructions ? (
        <Container
          style={{ maxWidth: '900px', maxHeight: '200px', overflow: 'auto' }}
        >
          <Row style={{ backgroundColor: '#dad8d8' }}>
            <Col className="offset-lg-2 col-lg-8 col-12 text-center">
              <TextWithLineBreaks
                text={screenSignal?.value?.prompts[0]?.instructions}
              />
            </Col>
          </Row>
        </Container>
      ) : null}
    </Card>
  </Container>
);

export const TextWithLineBreaks = ({ text }) => {
  let paragraphs;
  let newText = text;
  if (text === undefined || text === null) {
    return null;
  }
  if (Array.isArray(text)) {
    newText = text[0];
  } else {
    newText = text;
  }
  if (newText) {
    paragraphs = newText
      .split('\n')
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  }

  return (
    <p className="mt-40" style={{ opacity: '1' }}>
      {paragraphs}
    </p>
  );
};

export default SessionPrompts;
