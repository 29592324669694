/* eslint-disable indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tab, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from 'components/global/Loader';
import {
  updateSessionAndScreenSignals,
  sessionSignal,
  screenSignal,
  checkpointModeSignal,
  subscribeOrGetProgram,
  handleSessionCompleted,
  startTimer,
  pauseTimer,
  setScreenToComplete,
} from './Session.helpers';
import SessionPrompts from './components/SessionPrompts';
import SessionContainer from './components/SessionContainer';
import SessionHeader from './components/SessionHeader';
import SessionContent from './components/SessionContent';
import SessionFooter from './components/SessionFooter';
import SessionCheckpoint from './components/SessionCheckpoint';
import userSignal from '../../../signals/User.Signal';
import ResourceTab from './components/ResourceTab';
import ExitModal from './components/ExitModal';

const Session = () => {
  const [tab, setTab] = useState('main');
  const [show, setShow] = useState(false);
  const [ending, setEnding] = useState(false);
  const { programId, sessionId, screenId } = useParams();
  const history = useHistory();
  const path = history.location.pathname.split('/')[1];
  const isEditor = path === 'editor';

  const { fbUser } = userSignal.value;

  const init = useCallback(async () => {
    await subscribeOrGetProgram({ programId, isEditor, token: fbUser.Aa });
    updateSessionAndScreenSignals({ screenId, sessionId });
  }, [programId, screenId, sessionId, isEditor]);

  const handleSubmitClick = () => {
    handleNextClick();
  };

  useEffect(() => {
    init();
  }, [programId, sessionId, screenId, isEditor]);

  const handlePrevClick = () => {
    if (isEditor) {
      if (screenId !== '1') {
        history.push(
          `/editor/${programId}/${sessionId}/screen/${Number(screenId) - 1}`,
        );
      }
    } else if (screenId !== '1') {
      history.push(
        `/session/${programId}/${sessionId}/screen/${Number(screenId) - 1}`,
      );
    }
  };

  const handleNextClick = async () => {
    checkpointModeSignal.reset();
    if (isEditor) {
      if (Number(screenId) < sessionSignal.value.screens?.length) {
        history.push(
          `/editor/${programId}/${sessionId}/screen/${Number(screenId) + 1}`,
        );
      }
    } else if (Number(screenId) < sessionSignal.value.screens?.length) {
      history.push(
        `/session/${programId}/${sessionId}/screen/${Number(screenId) + 1}`,
      );
      await setScreenToComplete({ screenId, sessionId });
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div
        style={{
          minHeight: '100vh',
          width: '100vw',
          backgroundImage: 'url(/images/bg-sessions.png)',
          backgroundSize: 'cover',
          flex: 1,
        }}
      >
        <Tab.Container className="mb-16" activeKey={tab}>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              {sessionSignal?.value?.title ? (
                <Container
                  fluid
                  className="vh-100 d-flex flex-column justify-content-between"
                >
                  <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                    <Row>
                      <Col>
                        <h5 className="session-main-screen text-center">
                          Session {sessionSignal?.value?.number}
                        </h5>
                        <h6 className="session-main-session-title text-center">
                          {sessionSignal?.value?.title?.toUpperCase()}
                        </h6>
                        <div className="text-center">
                          <Button
                            className="btn-outline-primary rounded-0 border-0 px-40 py-16 mt-40"
                            onClick={async () => {
                              await setScreenToComplete({
                                screenId,
                                sessionId,
                              });
                              setTab('session');
                              startTimer();
                            }}
                          >
                            Begin Session {sessionSignal?.value?.number}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <Link style={{ color: 'white' }} to="/dashboard">
                          <p className="text-center">Return to Dashboard</p>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Container>
              ) : (
                <div
                  style={{
                    minHeight: '100vh',
                    width: '100vw',
                    backgroundImage: 'url(/images/bg-sessions.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    flex: 1,
                  }}
                />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="session">
              <SessionContainer
                sessionContent={screenSignal.value.content?.length}
              >
                {screenSignal.value.sidebarResources ||
                screenSignal.value.sidebarScreens ? (
                  <ResourceTab />
                ) : null}
                <SessionHeader setShow={setShow} screenId={screenId} />
                {screenSignal.value.style !== 'checkpoint' ? (
                  <>
                    {screenSignal.value.content?.length && <SessionContent />}
                    {screenSignal.value.prompts?.length && (
                      <>
                        <SessionPrompts />
                      </>
                    )}
                  </>
                ) : (
                  <SessionCheckpoint />
                )}
              </SessionContainer>
              <Container style={{ paddingBottom: '15%' }} fluid>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        className="btn-outline-primary rounded-0 border-0 px-40 py-16 mt-40"
                        onClick={async () => {
                          setEnding(true);
                          pauseTimer();
                          await Promise.all([
                            await setScreenToComplete({ screenId, sessionId }),
                            await handleSessionCompleted(),
                          ]);
                          history.push('/dashboard');
                        }}
                        disabled={ending}
                        hidden={
                          Number(screenId) !==
                          sessionSignal.value.screens?.length
                        }
                      >
                        {!ending ? 'Complete Session' : <Loader />}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
              <SessionFooter
                onSubmitClick={handleSubmitClick}
                onPrevClick={handlePrevClick}
                onNextClick={handleNextClick}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <ExitModal
        setShow={setShow}
        show={show}
        title={sessionSignal.value?.title}
      />
    </div>
  );
};

export default Session;
