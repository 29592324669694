import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';

const TaglineTableResults = ({ prompt }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (savedValues) {
      setValues(savedValues);
    }
  }, [prompt]);

  return (
    <div className="mx-32">
      <h5 className="text-uppercase">Tagline Variations</h5>
      {Object.keys(values).map((key, idx) => (
        <div key={idx}>
          <h6 className="mb-24 mt-24">{`Theme ${idx + 1}`}</h6>
          <Table className="mb-16 py-32" bordered>
            <thead className="py-16">
              <tr>
                <th style={{ textAlign: 'left' }} colSpan={2}>
                  Tagline Structures
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'left' }}>
              <tr>
                <td className="fw-bold">Phrase starting with a Gerund</td>
                <td colSpan={2}>{values[key].gerund}</td>
              </tr>
              <tr>
                <td className="fw-bold">Phrase starting with a Verb</td>
                <td colSpan={2}>{values[key].verb}</td>
              </tr>
              <tr>
                <td className="fw-bold">Phrase starting with a Noun</td>
                <td colSpan={2}>{values[key].noun}</td>
              </tr>
              <tr>
                <td className="fw-bold">Phrase starting with an Adjective</td>
                <td colSpan={2}>{values[key].adjective}</td>
              </tr>
              <tr>
                <td className="fw-bold">Two Word. Two Word.</td>
                <td colSpan={2}>{values[key].twoWords}</td>
              </tr>
              <tr>
                <td className="fw-bold">Word. Word. Word. </td>
                <td colSpan={2}>{values[key].wordWordWord}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default TaglineTableResults;
