import Signal from 'libs/signals';
import alertSignal from 'signals/Alert.signal';
import { auth } from 'utils/firebase';

export const forgotPasswordSignal = Signal({});

export const handleForgotPasswordChange = ({ name, value }) => {
  forgotPasswordSignal.update({
    [name]: value,
  });
};

export const handleSubmit = async () => {
  try {
    const request = auth.sendPasswordResetEmail(forgotPasswordSignal.value.email);

    if (request) {
      alertSignal.update({
        type: 'notification',
        className: 'bg-success',
        message: 'Email sent!',
      });

      window.dataLayer.push({
        event: 'passwordReset',
        eventSettingsTable: [
          {
            parameter: 'eventCategory',
            parameterValue: 'Password Reset',
          },
          {
            parameter: 'eventAction',
            parameterValue: `Email: ${forgotPasswordSignal.value.email}`,
          },
          { parameter: 'eventLabel', parameterValue: forgotPasswordSignal.value.email },
        ],
      });
    }

    return true;
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'Email could not be sent',
      error,
    });
  }
};
