const businessTypes = [
  { label: 'Agriculture, Forestry, Fishing, and Hunting', value: 'AgricultureForestryFishingAndHunting' },
  { label: 'Alternative Energy Production and Services', value: 'AlternativeEnergyProductionAndServices' },
  { label: 'Architecture, Construction, or Engineering Services', value: 'ArchitectureConstructionOrEngineeringServices' },
  { label: 'Arts, Entertainment, and Recreation', value: 'ArtsEntertainmentAndRecreation' },
  { label: 'Beauty, Cosmetics, and Salon', value: 'BeautyCosmeticsAndSalon' },
  { label: 'Business Consulting Services', value: 'BusinessConsultingServices' },
  { label: 'City, State, and Federal Government', value: 'CityStateAndFederalGovernment' },
  { label: 'Educational Institution, Higher Education, and Educational Services', value: 'EducationalInstitutionHigherEducationAndEducationalServices' },
  { label: 'Fashion, Apparel, and Jewelry', value: 'FashionApparelAndJewelry' },
  { label: 'Film, Radio, and Television', value: 'FilmRadioAndTelevision' },
  { label: 'Financial Products, Services, and Technology', value: 'FinancialProductsServicesAndTechnology' },
  { label: 'Food, Beverage, Restaurants and Dining', value: 'FoodBeverageRestaurantsAndDining' },
  { label: 'Healthcare, Pharmaceutical, and Pharmaceutical Technology', value: 'HealthcarePharmaceuticalAndPharmaceuticalTechnology' },
  { label: 'Hospitality and Leisure', value: 'HospitalityAndLeisure' },
  { label: 'Human Resources Services, HR Technology, and Recruitment', value: 'HumanResourcesServicesHRTechnologyAndRecruitment' },
  { label: 'Insurance Services', value: 'InsuranceServices' },
  { label: 'IT Products and Services', value: 'ITProductsAndServices' },
  { label: 'Legal Consulting Services', value: 'LegalConsultingServices' },
  { label: 'Industrial and Manufacturing', value: 'IndustrialAndManufacturing' },
  { label: 'Marketing Consulting, Products, and Services', value: 'MarketingConsultingProductsAndServices' },
  { label: 'Media, News, and Public Relations', value: 'MediaNewsAndPublicRelations' },
  { label: 'Medical Products, Technology, and Services', value: 'MedicalProductsTechnologyAndServices' },
  { label: 'Non-Profits, Foundations, and Philanthropy', value: 'NonProfitsFoundationsAndPhilanthropy' },
  { label: 'Real Estate and Mortgage', value: 'RealEstateAndMortgage' },
  { label: 'Sales Consulting, Products, and Services', value: 'SalesConsultingProductsAndServices' },
  { label: 'Software Design and Development', value: 'SoftwareDesignAndDevelopment' },
  { label: 'Sports, Sports Technology, and E-Sports', value: 'SportsSportsTechnologyAndESports' },
  { label: 'Transportation, Utilities, Telecom, and Oil', value: 'TransportationUtilitiesTelecomAndOil' },
  { label: 'Venture Capital, Private Equity, or Investment Firms', value: 'VentureCapitalPrivateEquityOrInvestmentFirms' },
  { label: 'Other', value: 'Other' },
];

export default businessTypes;
