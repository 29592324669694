import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { apiFetch } from 'api/api';
import './scss/style.scss';
import ContentWrapper from 'components/global/ContentWrapper';
import userSignal from 'signals/User.Signal';
import { auth } from 'utils/firebase';
import { signOut } from 'utils/auth';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import { getConfig } from 'config/config';
import UserRoutes from './components/global/Routes/UserRoutes';
import userbackLib from './libs/userback';

userbackLib.registerWidget();

const GTM_KEY = getConfig('GTM_KEY');

auth.onAuthStateChanged(async (fbUser) => {
  loaderSignal.update({ isContentLoading: true });
  if (fbUser) {
    window.localStorage.setItem('loggedIn', true);
    const creds = window.localStorage.getItem('creds');
    const orgId = window.localStorage.getItem('organizationId');
    let local = {};
    let localString = '';

    try {
      const userData = await apiFetch({ path: '/users', token: fbUser.Aa });
      let organizationData;
      if (Array.isArray(userData?.User_Organization) && userData?.User_Organization.length > 0) {
        organizationData = await apiFetch({
          path: '/organizations',
          token: fbUser.Aa,
          method: 'POST',
          body: { id: userData?.User_Organization?.[0]?.id },
        });
      }
      userSignal.update({
        userData,
        fbUser,
        isSignedIn: true,
        organizationData,
      });

      if (userData?.role?.name === 'Practitioner'
        && creds === null) {
        const orgIdInLs = userData?.orgData?.filter(({ type }) => type === 'agency');
        local = { userType: userData?.role?.name, isAgency: true };
        localString = JSON.stringify(local);
        window.localStorage.setItem('creds', localString);
        window.localStorage.setItem('organizationId', orgIdInLs[0]?.id);
      } else if (userData?.role?.name !== 'Practitioner'
        && creds === null && orgId === null) {
        local = { userType: userData?.role?.name, isAgency: false };
        localString = JSON.stringify(local);
        window.localStorage.setItem('creds', localString);
        window.localStorage.setItem('organizationId', userData?.orgData[0]?.id);
      } else if (orgId === 'undefined') {
        window.localStorage.setItem('organizationId', userData?.orgData[0]?.id);
      }
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: 'An error occured fetching your user data',
        error,
      });
      signOut();
      window.localStorage.setItem('loggedIn', false);
    } finally {
      loaderSignal.reset();
    }
  } else {
    window.localStorage.setItem('loggedIn', false);
    userSignal.reset();
    loaderSignal.reset();
  }
});

const tagManagerArgs = {
  gtmId: GTM_KEY,
};

const App = () => {
  useEffect(() => {
    console.log({ GTM_KEY });
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ContentWrapper>
      <Router>
        <Switch>
          <Route component={UserRoutes} />
        </Switch>
      </Router>
    </ContentWrapper>
  );
};

export default App;
