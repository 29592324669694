// import { useState } from 'react';
import { Button } from 'react-bootstrap';
import signupSignal from 'signals/Signup.signal';
import Field from '../../../global/Field/Field';
import { handleFormChange } from '../Signup.helpers';

const PersonalInfo = ({ onSubmit }) => {
  const signal = signupSignal.value;
  // const [showModal, setShowModal] = useState(false);

  const handleGetStartedClick = () => {
    onSubmit('CreatePassword');
  };

  return (
    <>
      <div className="pt-lg-64 pt-48 text-center text-primary">
        <p className="mb-0 fs-3">Please tell us about yourself</p>
      </div>
      <Field
        type="text-input"
        name="firstName"
        value={signal.firstName}
        label="First Name"
        className="pt-lg-56 pt-32 text-primary"
        labelClassName="fw-bold mb-lg-8 mb-0"
        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
        onChange={handleFormChange}
      />
      <Field
        type="text-input"
        name="lastName"
        value={signal.lastName}
        label="Last Name"
        className="pt-lg-32 pt-24 text-primary"
        labelClassName="fw-bold mb-lg-8 mb-0"
        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
        onChange={handleFormChange}
      />
      <Field
        type="email"
        name="email"
        value={signal.email}
        label="Email Address"
        className="pt-lg-32 pt-24 text-primary"
        labelClassName="fw-bold mb-lg-8 mb-0"
        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
        onChange={handleFormChange}
      />
      <div className="w-100 px-32 mt-56">
        <Button
          className="w-100 btn-outline-secondary rounded-0 py-16"
          disabled={!signal.firstName || !signal.lastName || !signal.email}
          onClick={() => handleGetStartedClick()}
        >
          Get Started
        </Button>
      </div>
    </>
  );
};

export default PersonalInfo;
