import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { dashboardDataSignal } from '../Dashboard.helpers';

const QuickVideos = ({ modal }) => {
  const history = useHistory();

  const handleViewAllVideosClick = () => {
    history.push('/videos');
  };

  const { quickStartVideos } = dashboardDataSignal.value;
  const videosToCard = quickStartVideos?.slice(0, 4);

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 mb-32 font-josefin"
      style={{ zIndex: modal === 'ExploreModal' ? 2000 : 0 }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">QUICK START VIDEOS</h4>
      </Card.Header>
      <Card.Body className="text-wrap px-0 py-0">
        {videosToCard?.map((video) => (
          <Row key={video.id} className="py-32 m-0 ps-32 pe-0">
            <Col xs={8} className="m-0 p-0">
              <p className="lead-400 fs-5 mb-8">{video.documentName}</p>
              <p className="base fs-6 text-secondary" />
              <Button
                onClick={() => {
                  history.push('/videos');
                }}
                className="d-flex align-items-center bg-transparent border-0 text-primary p-0"
              >
                <p className="base fs-6 mb-0 pe-8">Learn More</p>
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Col>
          </Row>
        ))}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          className="btn-outline-secondary"
          onClick={handleViewAllVideosClick}
        >
          View All Videos
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default QuickVideos;
