import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { pauseTimer } from '../Session.helpers';
import { notesSignal } from './ResourceTab';

function ExitModal({ show, setShow, title }) {
  const handleClose = () => setShow(false);
  const history = useHistory();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        className="border-secondary border-2 rounded-0"
      >
        <Modal.Header className="text-center">
          <Modal.Title className="p-16">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-32 text-center">
          Are you sure you want to exit your current session? All of your
          progress will be saved.
        </Modal.Body>
        <Modal.Footer className="border-0 mt-16">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              history.push('/dashboard');
              notesSignal.reset();
              pauseTimer();
            }}
          >
            Exit Session
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExitModal;
