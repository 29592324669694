/* eslint-disable import/no-anonymous-default-export */
import detectEnvironment from '../utils/detectEnv';

export const getConfig = (key) => {
  const base = {
    BASE_URL: 'http://localhost:3000',
    REACT_APP_FIREBASE_PUB_KEY: process.env.REACT_APP_FIREBASE_PUB_KEY,
    REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    REACT_APP_FIREBASE_DATABASE_URL:
      process.env.REACT_APP_FIREBASE_DATABASE_URL,
    REACT_APP_FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_STORAGE_BUCKET:
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID:
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    REACT_APP_FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
    REACT_APP_API_ENDPOINT:
      'http://127.0.0.1:5001/brand-trifecta/us-central1/api_dev',
    ENV: 'development',
    GTM_KEY: process.env.REACT_APP_GTM_DEV,
  };

  const development = {
    ...base,
  };

  const feature = {
    ...development,
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT_FEATURE,
    ENV: 'feature',
  };

  const qa = {
    ...development,
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT_QA,
    ENV: 'qa',
    GTM_KEY: process.env.REACT_APP_GTM_DEV,
  };

  const production = {
    ...qa,
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT_PROD,
    ENV: 'production',
    GTM_KEY: process.env.REACT_APP_GTM,
  };

  const configSettings = {
    development,
    feature,
    qa,
    production,
  };

  const env =
    process?.env?.REACT_APP_ENV ?? detectEnvironment() ?? 'development';

  const config = configSettings[env] || configSettings.development;

  if (!key) {
    return config;
  }

  return config[key];
};

export default {
  getConfig,
};
