/* eslint-disable import/no-cycle */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Offcanvas, Row, Col, Tab, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import {
  faCaretLeft,
  faCaretRight,
  faArrowLeft,
  faDotCircle,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import Signal from 'libs/signals';
import { screenSignal, getSidebarValues } from '../Session.helpers';
import SidebarNotes from './SidebarNotes';

export const notesSignal = Signal({});

const ResourceTab = () => {
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState('main');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Tab.Container activeKey={tab}>
      <Button variant="light" onClick={handleShow} className="sidebar-button">
        <FontAwesomeIcon size="lg" icon={faCaretLeft} />
      </Button>
      <Offcanvas
        style={{ overflow: 'auto' }}
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Tab.Content>
          <Tab.Pane eventKey="main">
            <MainResourceView onReview={setTab} />
          </Tab.Pane>
          <Tab.Pane eventKey="review">
            <ReviewView onReview={setTab} />
          </Tab.Pane>
          <Button
            onClick={handleClose}
            variant="light"
            className="close-button"
          >
            <FontAwesomeIcon size="lg" icon={faCaretRight} />
          </Button>
          <SidebarNotes
            screenId={screenSignal.value?.uid}
            sessionId={screenSignal.value?.session[0]?.uid}
          />
        </Tab.Content>
      </Offcanvas>
    </Tab.Container>
  );
};

const ReviewView = ({ onReview }) => {
  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    let elements = [];
    elements = screenSignal.value.sidebarScreens?.map((prompt) =>
      getSidebarValues({
        screenId: prompt.screenId,
        sessionId: prompt.sessionId,
      }),
    );
    setAnswers(elements);
  }, []);

  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <div style={{ width: '90%', marginTop: '20px' }}>
          <button
            style={{ border: 'none' }}
            type="button"
            onClick={() => onReview('main')}
          >
            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
          </button>
          <BlockTitle title="review your previous answers" />
        </div>
      </Row>
      <Row className="mt-32">
        <Col style={{ marginBottom: '50px' }} lg={{ offset: 1 }}>
          {screenSignal.value?.sidebarScreens?.length &&
            answers.map((view, index) => (
              <div className="mb-18" key={index}>
                {view}
              </div>
            ))}
        </Col>
      </Row>
    </>
  );
};
const MainResourceView = ({ onReview }) => {
  const { sidebarResources, sidebarScreens } = screenSignal.value;

  return (
    <>
      {/*
      <Row style={{ justifyContent: 'center' }}>
        <div style={{ width: '90%', marginTop: '20px' }}>
          {sidebarResources?.length && <ResourceTabVideo content={sidebarResources} />}
        </div>
      </Row>
       */}
      <Row>
        {sidebarResources?.length && (
          <Row>
            <Col
              style={{ marginBottom: '30px', marginTop: '30px' }}
              lg={{ offset: 1 }}
            >
              <BlockTitle title="resources" />
            </Col>
          </Row>
        )}
        {sidebarResources?.length && (
          <Row>
            <Col style={{ marginBottom: '30px' }} lg={{ offset: 2 }}>
              {sidebarResources?.map((resource, idx) =>
                resource.type === 'PDF' ? (
                  <InnerBlock
                    key={idx}
                    text={resource.documentName}
                    icon={faFilePdf}
                    attachment={resource.link}
                  />
                ) : null,
              )}
            </Col>
          </Row>
        )}
        {/* { sidebarResources?.length && (
        <Row>
          <Col style={{ marginTop: '10px' }} lg={{ offset: 1 }}>
            <BlockTitle title="helpful links" />
          </Col>
        </Row>
        )} */}
        <Row>
          <Col lg={{ offset: 2 }}>
            {sidebarResources?.map((resource, idx) =>
              resource.type === 'URL' ? (
                <div
                  style={{
                    display: 'flex',
                  }}
                  key={idx}
                >
                  <InnerBlock
                    text={resource.documentName}
                    notes={resource.notes}
                    icon={faDotCircle}
                    attachment={resource.link}
                  />
                </div>
              ) : null,
            )}
          </Col>
        </Row>
        {sidebarScreens ? (
          <>
            <Row>
              <Col lg={{ offset: 1 }}>
                <BlockTitle title="review your previous answers" />
              </Col>
            </Row>
            <Row>
              <Col lg={{ offset: 2 }}>
                <button
                  type="button"
                  style={{ border: 'none' }}
                  onClick={() => onReview('review')}
                >
                  <InnerBlock
                    text="Review Your Previous Answers"
                    icon={faFolderOpen}
                  />
                </button>
              </Col>
            </Row>
          </>
        ) : null}
      </Row>
    </>
  );
};

/*
const ResourceTabVideo = ({ content }) => {
  if (!content[0]?.link) {
    return <div className="bg-light p-32"> Missing Video Link</div>;
  }
  return (
    <iframe
      // className="w-100"
      height="300px"
      width="100%"
      src={`${content[0]?.link?.trim()}?enablejsapi=1`}
      title={content[0]?.title}
      frameBorder="0"
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      allowFullScreen
    />
  );
};
 */

const InnerBlock = ({ text, icon, attachment, notes }) => (
  <>
    <a
      href={attachment}
      target="_blank"
      className="fs-4 mt-16 text-nowrap"
      rel="noreferrer"
    >
      <FontAwesomeIcon
        style={{ marginRight: '15px' }}
        color="#B2A05E"
        size="lg"
        icon={icon}
      />
      <span style={{ fontSize: '16px' }}>{text}</span>
    </a>
    <p
      style={{
        marginBottom: 0,
        marginLeft: '15px',
        fontSize: '14px',
        marginTop: '20px',
      }}
    >
      {notes}
    </p>
  </>
);

export const BlockTitle = ({ title }) => (
  <h5 style={{ fontSize: '24px' }} className="sidebar-title mt-32">
    {title.toUpperCase()}
  </h5>
);

export default ResourceTab;
