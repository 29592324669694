import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { secondsToMinutes } from 'utils/time';
import playButton from 'images/play-button.svg';
import { TextWithLineBreaks } from './SessionPrompts';
import {
  screenSignal,
  sessionSignal,
  pauseTimer,
  startTimer,
  timerSignal,
  handleUpdateAnswersClick,
  checkpointModeSignal,
  programSignal,
} from '../Session.helpers';

const SessionFooter = ({ onPrevClick, onNextClick, onSubmitClick }) => {
  const [timeEnded, setTimeEnded] = useState(true);

  useEffect(() => {
    const timer = timerSignal.value.remaining;
    if (timer && timer > 0) {
      setTimeEnded(false);
    } else if (timer === 0) {
      setTimeEnded(true);
      pauseTimer();
    }
  }, [timerSignal.value.remaining]);

  return (
    <div className="fixed-bottom mt-24">
      {screenSignal.value.style !== 'checkpoint' ? (
        <Container
          fluid
          className="mt-24"
          style={{
            padding: '10px',
            overflow: 'auto',
            maxHeight: '155px',
            backgroundColor: 'rgba(12, 12, 12, 0.8)',
          }}
        >
          {!!screenSignal.value.prompts?.[0] && (
            <Container className="text-center">
              <Button
                variant="success"
                className="mx-auto rounded-0"
                onClick={onSubmitClick}
              >
                {screenSignal.value.button}
              </Button>
            </Container>
          )}
          <Container style={{ opacity: 1, color: 'white' }}>
            <TextWithLineBreaks text={screenSignal.value?.footer} />
          </Container>
        </Container>
      ) : (
        <Container
          style={{
            padding: '10px',
            overflow: 'auto',
            maxHeight: '155px',
            backgroundColor: 'rgba(12, 12, 12, 0.8)',
          }}
          fluid
          className="my-0 text-light p-24"
        >
          {checkpointModeSignal.value === 'review' ? (
            <Container className="text-center">
              <Button
                variant="primary"
                className="mx-auto rounded-0 text-success me-24"
                onClick={() => handleUpdateAnswersClick('edit')}
              >
                Update Answers
              </Button>
              <Button
                variant="success"
                className="mx-auto rounded-0 text-primary"
                onClick={onNextClick}
              >
                Ready to Move On
              </Button>
            </Container>
          ) : (
            <Container className="text-center">
              <Button
                variant="success"
                className="mx-auto rounded-0 text-primary"
                onClick={onNextClick}
              >
                Ready to Move On
              </Button>
            </Container>
          )}
          <Container>{screenSignal.value.footer}</Container>
        </Container>
      )}
      <Container fluid className="bg-success my-0 " style={{ padding: '10px' }}>
        <Row className="align-items-center">
          <Col className="d-flex justify-content-start">
            {!programSignal.value.editorMode ? (
              <div
                className={`${
                  timeEnded ? 'bg-danger' : 'bg-primary'
                } text-white px-16 fs-5`}
              >
                {timeEnded
                  ? '00:00'
                  : secondsToMinutes(timerSignal.value.remaining)}{' '}
                Remaining
              </div>
            ) : (
              <Button className="btn-outline-secondary">
                <a
                  href={`${screenSignal?.value.screenUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: 'white',
                    marginLeft: '10px',
                    textDecoration: 'none',
                  }}
                >
                  Visit Page in Airtable
                </a>
              </Button>
            )}
          </Col>
          <Col>
            <div className="text-center">
              <Image
                fluid
                sizes="sm"
                className="mx-auto w-100 text-center"
                src="/images/bt-main.png"
                style={{
                  maxWidth: '100px',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="d-flex">
              <Button variant="p-0" onClick={onPrevClick}>
                <Image
                  src="/images/back-button.png"
                  style={{ height: '32px' }}
                />
              </Button>
              {sessionSignal.value.state === 'active' ? (
                <Image
                  src="/images/pause-button.png"
                  className="mx-0"
                  style={{ height: '32px', margin: '10px' }}
                  onClick={
                    !programSignal.value.editorMode ? () => pauseTimer() : null
                  }
                />
              ) : (
                <Image
                  src={playButton}
                  className="mx-0"
                  style={{ height: '32px', margin: '10px', cursor: 'pointer' }}
                  onClick={
                    !programSignal.value.editorMode ? () => startTimer() : null
                  }
                />
              )}
              <Button variant="p-0" className="border-0" onClick={onNextClick}>
                <Image
                  src="/images/next-button.png"
                  style={{ height: '32px' }}
                />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SessionFooter;
