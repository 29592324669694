/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line import/no-unresolved
import {
  getPromptValueFromProgramSignal,
  handlePromptChange,
} from 'components/views/Sessions/Session.helpers';
// eslint-disable-next-line import/no-unresolved
import fiveWhysInputSignal from 'signals/fiveWhys.signal';
import InputGroups from '../InputGroups/index';

const InputGroupsWithTabs = ({ prompt }) => {
  const [tabs, setTabs] = useState([1]);
  const [tabIdx, setTabIdx] = useState(0);

  const handleAddTabs = () => {
    const newTabs = [...tabs];
    newTabs.push(newTabs.length + 1);
    setTabs(newTabs);
    fiveWhysInputSignal.update({
      ...fiveWhysInputSignal.value,
      tabs: newTabs,
    });
    handlePromptChange({
      value: fiveWhysInputSignal.value,
      prompt,
    });
  };

  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    if (!values) {
      return;
    }
    if (values) {
      if (values?.tabs && values?.tabs?.length > 0) {
        setTabs(values.tabs);
        fiveWhysInputSignal.update(values);
      }
      if (!values?.tabs || values?.tabs?.length === 0) {
        const tempTabs = [];
        Object.keys(values).forEach((_, index) => {
          tempTabs.push(index + 1);
        });
        setTabs(tempTabs);
      }
      if (
        (Object.keys(values).length >= 7 &&
          !Object.keys(values).includes('0')) ||
        (Object.keys(values).length >= 7 && !Object.keys(values).includes('1'))
      ) {
        fiveWhysInputSignal.update({
          0: values,
        });
      }
    } else {
      setTabs([1]);
    }
  }, [prompt]);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={tabIdx}
      defaultActiveKey={tabIdx}
      onSelect={(k) => setTabIdx(k)}
      className="mb-3"
      tabClassName="tab-background-none"
    >
      {tabs?.map((tab, idx) => (
        <Tab eventKey={idx} title={`Audience ${idx + 1}`}>
          <InputGroups
            prompt={prompt}
            idx={tabIdx}
            values={fiveWhysInputSignal.value[idx]}
          />
        </Tab>
      ))}
      <Tab
        tabClassName=""
        title={
          <Button variant="none" onClick={handleAddTabs}>
            <FontAwesomeIcon icon={faPlusCircle} /> Add another audience
          </Button>
        }
      />
    </Tabs>
  );
};

export default InputGroupsWithTabs;
