import { useCallback, useEffect, useState } from 'react';
import { Card, Row, Col, Image, Button, Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROGRAMS_UID } from 'constants/index';
import { apiFetch } from 'api/api';
import Loader from 'components/global/Loader';
import TopBar from 'components/global/TopBar';
import PackagesWelcomeModal from './modal-components/PackagesWelcomeModal';
import packagesSignal from './Packages.helpers';
import TopBarAgency from '../Dashboard/agency-dashboard/agency-components/TopBarAgency';
import {
  allProgramsSignal,
  fetchAndSetProgramData,
} from '../Dashboard/Dashboard.helpers';

const defaultPackageImage =
  'https://files.stripe.com/links/MDB8YWNjdF8xTlRudURER3JsejNNdWp4fGZsX3Rlc3RfVlVtMHFpZEQ2ZUlJTzVmeWdjU29oV3By00N44mXm7m';

const Packages = () => {
  const [showPackagesModal, setShowPackagesModal] = useState(false);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const init = useCallback(async () => {
    await Promise.all([fetchAndSetProgramData(), fetchPackages()]);
  }, []);

  const { isAgency, userType } = JSON.parse(
    window.localStorage.getItem('creds'),
  );

  const fetchPackages = async () => {
    let url = '';
    try {
      if (userType === 'Practitioner') {
        url = '/stripe/list-products?isPractitioner';
      } else {
        url = '/stripe/list-products';
      }
      const productsRequest = await apiFetch({
        path: url,
        method: 'GET',
      });
      packagesSignal.update(productsRequest);
    } catch (fetchPackagesError) {
      setError('Unable to load packages.');
      console.log({
        fetchPackagesError: fetchPackagesError.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkIfPartOneIsBought = () => {
    const programs = allProgramsSignal.value;
    if (programs.length > 0) {
      return !!programs.filter(
        (program) => program.uid === PROGRAMS_UID.partOne,
      );
    }
    return false;
  };

  useEffect(() => {
    init();
    if (!checkIfPartOneIsBought()) {
      setTimeout(() => {
        setShowPackagesModal(true);
      }, [400]);
    }
  }, []);

  const handleSelectItem = (id) => {
    window.dataLayer.push({
      event: 'view_item_list',
      item: id,
    });
    history.push(`/package/${id}`);
  };

  return (
    <>
      <div className="bg-primary w-100 py-24" />
      {isAgency && userType === 'Practitioner' ? <TopBarAgency /> : <TopBar />}
      <div
        className="position-relative w-100 px-xl-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Container>
          <Link
            className="base package-back-button back-button"
            to="/dashboard"
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back to Dashboard
          </Link>
          <Card
            style={{ padding: 0 }}
            className="rounded-0 border-0 container-xl mt-56"
          >
            <Card.Header className="bg-success text-primary text-center rounded-0 py-32">
              <h3 className="h3 text-uppercase">pricing breakdown</h3>
            </Card.Header>
            <Card.Body className="p-0 m-0">
              {isLoading && (
                <div className="text-center p-4 m-4">
                  <br />
                  <Loader />
                  <br />
                </div>
              )}
              {isLoading === false && error && (
                <h4 style={{ margin: '50px 0' }} className="text-center">
                  {error}
                </h4>
              )}
              {isLoading === false &&
                !error &&
                packagesSignal.value
                  .filter((p) => p.active === true)
                  .sort((a, b) => a.metadata.order - b.metadata.order)
                  .map((p, idx) => (
                    <Row
                      className={`w-100 py-80 p-0 m-0 ${
                        idx % 2 ? 'bg-light' : 'bg-white'
                      } `}
                      key={`package-${idx}`}
                    >
                      <Col lg={6} xs={12} className="ps-64 pe-88">
                        <h4>{p.name}</h4>
                        <p>{p.description}</p>
                        {userType === 'Client' ? null : (
                          <Button
                            style={{ paddingLeft: 0 }}
                            className="border-0 bg-transparent text-secondary"
                            disabled={userType === 'Client'}
                            role="link"
                            onClick={() => {
                              handleSelectItem(p.id);
                            }}
                          >
                            {isAgency ? 'View Price' : 'Purchase Now'}
                          </Button>
                        )}
                      </Col>
                      <Col
                        lg={6}
                        xs={12}
                        className="d-flex justify-content-center align-items-center pt-lg-0 pt-40"
                      >
                        <Image
                          src={p?.images?.[0] ?? defaultPackageImage}
                          style={{ maxWidth: '50%' }}
                          className="w-sm-100 w-75 w-md-50"
                        />
                      </Col>
                    </Row>
                  ))}
            </Card.Body>
          </Card>
        </Container>
      </div>
      <PackagesWelcomeModal
        show={showPackagesModal}
        setShow={setShowPackagesModal}
      />
    </>
  );
};

export default Packages;
