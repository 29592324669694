import { Modal, Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Field from 'components/global/Field';
import alertSignal from 'signals/Alert.signal';
import { apiFetch } from 'api/api';
import userSignal from 'signals/User.Signal';
import { addUserSignal, handleUserChange } from './addClientModal.helpers';

const AddUserModal = ({ show, setShow, clientId }) => {
  const handleSubmit = async () => {
    try {
      const body = [
        {
          name: `${addUserSignal.value.userFirstName} ${addUserSignal.value.userLastName}`,
          email: addUserSignal.value.userEmail,
          role: addUserSignal.value.userRole,
        },
      ];
      const request = await apiFetch({
        path: '/users/invitation',
        method: 'Post',
        token: userSignal.value.fbUser.Aa,
        body,
        orgId: clientId,
      });

      if (request) {
        alertSignal.update({
          type: 'notification',
          message: 'Invitation to new user sent!',
          className: 'bg-success',
        });
      }
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: 'Could not add the new contributor',
        error,
      });
    } finally {
      setShow(false);
      window.location.href = '/agency/clients';
    }
  };
  console.log(addUserSignal.value);

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      onHide={() => setShow(false)}
      contentClassName="rounded-0"
      size="lg"
    >
      <Modal.Header className="d-flex flex-column text-center border-0 mb-0 text-center font-josefin text-secondary rounded-0">
        <Button
          variant="white"
          className="bg-transparent align-self-end border-0 text-secondary fw-bold fs-5 pb-0"
          onClick={() => setShow(false)}
        >
          <FontAwesomeIcon icon={faXmark} className="text-secondary" />
        </Button>
        <div className="custom-image-padding">
          <Card.Img variant="top" src="/images/bt-main.png" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={12} lg={12} className="px-0">
            <div className="px-xl-72">
              <Card className="border-0">
                <Card.Body>
                  <div className="text-center text-primary">
                    <p className="mb-0 fs-3">Add User</p>
                  </div>
                  <Row>
                    <Col lg={6}>
                      <Field
                        type="text-input"
                        name="userFirstName"
                        value={addUserSignal.value.userFirstName}
                        label="First Name"
                        className="pt-lg-40 pt-32 text-primary"
                        labelClassName="fw-bold mb-lg-8 mb-0"
                        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                        onChange={handleUserChange}
                      />
                    </Col>
                    <Col lg={6}>
                      <Field
                        type="text-input"
                        name="userLastName"
                        value={addUserSignal.value.userLastName}
                        label="Last Name"
                        className="pt-lg-40 pt-32 text-primary"
                        labelClassName="fw-bold mb-lg-8 mb-0"
                        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                        onChange={handleUserChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Field
                        type="text-input"
                        name="userEmail"
                        value={addUserSignal.value.userEmail}
                        label="Email Address"
                        className="pt-lg-40 pt-32 text-primary"
                        labelClassName="fw-bold mb-lg-8 mb-0"
                        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                        onChange={handleUserChange}
                      />
                    </Col>
                    <Col lg={6}>
                      <Field
                        type="select"
                        name="userRole"
                        label="Role"
                        value={addUserSignal.value.userRole}
                        className="pt-lg-40 pt-32 text-primary"
                        labelClassName="fw-bold mb-lg-8 mb-0"
                        dropDownClassName="border-0 border-bottom border-primary bg-transparant text-primary ps-32"
                        inputClassName="border-0 border-bottom border-primary rounded-0 bg-transparent ps-32"
                        options={[
                          { label: 'Practitioner', value: 'Practitioner' },
                          { label: 'Client', value: 'Client' },
                        ]}
                        onChange={handleUserChange}
                      />
                    </Col>
                  </Row>
                  <div className="w-100 px-32 mt-96">
                    <Button
                      variant="secondary"
                      className="w-100 btn-outline-secondary rounded-0 py-16"
                      onClick={handleSubmit}
                      disabled={
                        !addUserSignal.value.userFirstName ||
                        !addUserSignal.value.userLastName ||
                        !addUserSignal.value.userRole ||
                        !addUserSignal.value.userEmail
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
