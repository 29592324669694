// const loadScript = (baseurl, filename) => {
//   const node = document.createElement('script');
//   node.src = baseurl + filename;
//   document.getElementsByTagName('head')[0].appendChild(node);
// };
import { getConfig } from 'config/config';

const env = getConfig('ENV');

const registerWidget = () => {
  if (env === 'production') return;

  const d = document;
  window.Userback = window.Userback || {};
  window.Userback.access_token = '34769|83622|ksUnFdmWEsWU3CvfGKILuh0kK';
  const s = d.createElement('script'); s.async = true;
  s.src = 'https://static.userback.io/widget/v1.js';
  (d.head || d.body).appendChild(s);
};

export default { registerWidget };
