import { Form } from 'react-bootstrap';

const Zip = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
}) => {
  const handleChange = (e) => {
    const eTmp = { ...e };
    if (e.target.value.toString().length > 6) {
      const v = e.target.value.toString().split('');
      v.pop();
      eTmp.target.value = Number(v.join(''));
      onChange(eTmp);
    } else {
      onChange(eTmp);
    }
  };

  return (
    <div className={className}>
      {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
      <div>
        <Form.Control
          name={name}
          type="number"
          id="inputZip"
          className={inputClassName}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Zip;
