import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const QuickVideo = ({ onSubmit }) => (
  <div className="d-flex flex-column align-items-center mt-72">
    <h4 className="mb-16">Schedule Your Sessions</h4>
    <div
      className="bg-secondary mb-16"
      style={{ height: '1px', width: '28%' }}
    />
    <div style={{ maxWidth: '500px' }} className="text-center mb-24">
      <p className="mb-0">
        Brand Trifecta sessions are 90-minutes in length and are intentionally
        structured to take you through a series of interactive videos and
        exercises. Please schedule your 90-minute sessions and invite team
        members to attend.
      </p>
      <p className="mb-0 mt-40">
        <b>Please Note:</b> The Brand Trifecta platform will send out email
        notifications for scheduled sessions. However, it will not send out
        calendar invites with meeting links. It is your responsibility to send
        out calendar invites with in-person or virtual meeting details.
      </p>
    </div>
    <Button
      className="d-flex flex-row align-items-center justify-content-center bg-primary text-secondary rounded-0 border-0 px-40 py-16 mt-40"
      onClick={() => onSubmit('ScheduleSession1')}
    >
      Start Scheduling
      <FontAwesomeIcon icon={faArrowRight} className="text-secondary" />
    </Button>
  </div>
);

export default QuickVideo;
