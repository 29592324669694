/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { BlockTitle, notesSignal } from './ResourceTab';
import {
  getNotesfromSessionScreen,
  saveSideBarNotes,
} from '../Session.helpers';

const SidebarNotes = ({ screenId, sessionId }) => {
  const [show, setShow] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    getNotesfromSessionScreen({ screenId, sessionId });
  }, []);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <div className="sidebar-notes mx-24">
      <Row>
        <Col
          style={{ marginBottom: '30px', marginTop: '30px' }}
          lg={{ offset: 1 }}
        >
          <BlockTitle title="notes" />
        </Col>
        <Row>
          <Col
            style={{ marginBottom: '30px' }}
            lg={{ offset: 2 }}
            role="button"
          >
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon
                onClick={() => {
                  setShow(!show);
                }}
                className="text-secondary  fs-3"
                icon={faFileAlt}
                size="lg"
              />
              {show ? (
                <>
                  <div className="w-100">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Type your notes here..."
                      className="mx-24 w-100"
                      value={notesSignal.value?.notes}
                      onChange={(e) =>
                        notesSignal.update({
                          notes: e.target.value,
                        })
                      }
                    />
                    <div className="d-flex justify-content-center mt-16">
                      <Button
                        className="w-50"
                        onClick={() => {
                          saveSideBarNotes({
                            sessionId,
                            notes: notesSignal.value?.notes,
                          });
                          setSaved(true);
                        }}
                      >
                        Save Notes
                        {saved && (
                          <FontAwesomeIcon
                            className="ms-8"
                            icon={faCircleCheck}
                            size="lg"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <Button
                  style={{ textAlign: 'left' }}
                  className="border-0 bg-transparent text-primary w-100"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  Take Notes
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default SidebarNotes;
