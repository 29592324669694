import { Form } from 'react-bootstrap';

const Select = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  dropDownClassName,
  placeholder,
  options,
}) => (
  <div className={className}>
    {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
    <Form.Select
      name={name}
      aria-label={label}
      value={value}
      className={dropDownClassName}
      onChange={onChange}
    >
      <option defaultChecked value="">{placeholder || 'Select an Option'}</option>
      {options.map((option, idx) => (
        <option key={name + idx.toString()} value={option.value} className={inputClassName}>{option.label}</option>
      ))}
    </Form.Select>
  </div>
);

export default Select;
