/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { handlePromptChange } from 'components/views/Sessions/Session.helpers';
import { repeaterTextInputTableWithTabsSignal } from './repeaterTextInputTableWithTabs.signal';

// All signals can be replaced with whichever signal we are using to
// store session data

export const handleWithTabsInputChange = (e, prompt, targetAudienceIdx) => {
  const { value, name } = e.target;
  if (targetAudienceIdx === undefined) {
    repeaterTextInputTableWithTabsSignal.update({
      ...repeaterTextInputTableWithTabsSignal.value,
      [name]: value,
    });
    handlePromptChange({ prompt, value: repeaterTextInputTableWithTabsSignal.value });
  } else {
    const newValue = { ...repeaterTextInputTableWithTabsSignal.value[`targetAudience${targetAudienceIdx}`] };
    newValue[name] = value;
    repeaterTextInputTableWithTabsSignal.update({
      ...repeaterTextInputTableWithTabsSignal.value,
      [`targetAudience${targetAudienceIdx}`]: newValue,
    });
    handlePromptChange({ prompt, value: repeaterTextInputTableWithTabsSignal.value });
  }
};
