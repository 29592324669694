import { Form } from 'react-bootstrap';

const Radio = ({
  label,
  name,
  value = [],
  onChange,
  className,
  labelClassName,
  inputClassName,
  options,
}) => {
  const handleSelect = (e, newVal) => {
    if (typeof newVal === 'boolean') {
      e.target.name = name;
      if (newVal) {
        e.target.value = true;
      } else {
        e.target.value = false;
      }
      onChange(e);
      return;
    }
    const eTmp = { ...e };
    eTmp.target.value = newVal;
    onChange(eTmp);
  };
  return (
    <Form.Group className={className}>
      <Form.Label className={label ? labelClassName : 'd-none'}>{label}</Form.Label>
      {
        options[0].label.toLowerCase() === 'yes' && options[1].label.toLowerCase() === 'no'
          ? (
            <>
              <div className="d-flex align-items-center">
                <Form.Check
                  name={name}
                  type="radio"
                  checked={!!value}
                  label={options[0].label}
                  className={inputClassName}
                  onChange={(e) => handleSelect(e, true)}
                />
              </div>
              <div className="d-flex align-items-center">
                <Form.Check
                  name={name}
                  type="radio"
                  checked={!value}
                  label={options[1].label}
                  className={inputClassName}
                  onChange={(e) => handleSelect(e, false)}
                />
              </div>
            </>
          )
          : options.map((option, idx) => {
            const isSelected = value === option.value;
            return (
              <div className="d-flex align-items-center" key={option.value}>
                <Form.Check
                  key={`${name}-${idx}`}
                  id={`${name}-${idx}`}
                  name={name}
                  type="radio"
                  checked={isSelected}
                  label={option.label}
                  className={inputClassName}
                  onChange={(e) => handleSelect(e, option.value)}
                />
              </div>
            );
          })
      }
    </Form.Group>
  );
};

export default Radio;
