import { getConfig } from '../config/config';

const REACT_APP_API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const apiFetch = async ({
  path,
  body = undefined,
  method = 'GET',
  token,
  orgId = window.localStorage.getItem('organizationId'),
}) => {
  const methodToUse = (method) || (body ? 'POST' : 'GET');
  const res = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: methodToUse,
    body: JSON.stringify(body),
    headers: {
      Authorization: token,
      'Content-Type': body ? 'application/json' : undefined,
      OrganizationId: orgId,
    },
  });
  const result = await res.json();
  if (result?.error || res.status > 201) {
    const errorObj = {
      message: result.error.message,
      status: res.status,
    };
    throw errorObj;
  }
  return result;
};

const apiFetchUnAuthenticated = async ({
  path,
  body = undefined,
  method = 'GET',
}) => {
  const methodToUse = (method) || (body ? 'POST' : 'GET');
  const requestOptions = {
    method: methodToUse,
    headers: {
      'Content-Type': body ? 'application/json' : undefined,
    },
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  const res = await fetch(REACT_APP_API_ENDPOINT + path, requestOptions);
  const result = await res.json();
  return result;
};

const recordSignal = async ({ userId, collection, signal, token }) => {
  const { data } = await apiFetch({
    path: '/signals',
    method: 'POST',
    body: JSON.stringify({
      signal,
      userId,
      collection,
    }),
    token,
  });
  return data;
};

export { apiFetch, apiFetchUnAuthenticated, recordSignal };
