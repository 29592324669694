/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import formatMoney from 'utils/formatMoney';
import { apiFetch } from 'api/api';
import packagesSignal from '../Packages/Packages.helpers';
import TermsAndConditions from '../TermsAndConditions';

const Package = () => {
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const packageQuery = packagesSignal.value.find((p) => p.id === id);

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const { userType, isAgency } = JSON.parse(
    window.localStorage.getItem('creds'),
  );

  useEffect(() => {
    window.dataLayer.push({
      event: 'view_item',
      item: id,
    });
  }, []);

  const handleCheckoutSessionRequest = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let url = '';
    try {
      if (userType === 'Practitioner') {
        url = '/stripe/create-checkout?isPractitioner';
      } else {
        url = '/stripe/create-checkout';
      }
      const checkoutSessionLink = await apiFetch({
        path: url,
        method: 'POST',
        body: {
          clientId: window.localStorage.getItem('organizationId'),
          products: [{ priceId: packageQuery.prices[0].id, quantity: 1 }],
        },
      });

      if (!checkoutSessionLink.url) throw new Error('Session Link Error');

      window.location = checkoutSessionLink.url;

      window.dataLayer.push({
        event: 'begin_checkout',
        item: id,
      });
    } catch (error) {
      console.log({ handleCheckoutSessionRequest: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return packageQuery ? (
    <>
      <div
        className="position-relative w-100 px-xl-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Row>
          <Col style={{ marginLeft: '6px', marginBottom: '30px' }}>
            <Link
              className="base package-back-button back-button"
              to="/packages"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back to Packages
            </Link>
          </Col>
        </Row>
        <Row className="p-0 m-0 w-100">
          <Col xs={12} className="px-16 py-104 h-100 bg-light">
            <Row className="m-0 justify-content-center">
              <img
                src="/images/bt-main.png"
                alt=""
                style={{
                  maxWidth: '200px',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Row>
            <Row className="w-50 mt-40 mx-auto justify-content-center bg-white border border-secondary">
              <div className="d-flex align-items-center flex-column py-40">
                <p className="lead text-primary mt-24 mb-32 fw-600">
                  One Time Purchase{' '}
                  {formatMoney(packageQuery.prices[0].unit_amount / 100)}
                </p>
                {isAgency ? null : (
                  <>
                    <Form.Check
                      type="checkbox"
                      label={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <p>
                          {'I agree to the '}
                          <span
                            role="button"
                            rel="noreferrer"
                            href="/terms-and-conditions"
                            style={{ color: '#007bff' }}
                            className="text-decoration-underline fw-bold"
                            onClick={() => setShowTerms(true)}
                          >
                            Terms and Conditions
                          </span>
                        </p>
                      }
                      className="text-primary"
                      onChange={() => setAgreedToTerms(!agreedToTerms)}
                    />
                    <Button
                      className="btn btn-outline-secondary"
                      onClick={handleCheckoutSessionRequest}
                      disabled={isLoading || !agreedToTerms}
                    >
                      {isLoading === true
                        ? 'Generating payment Link'
                        : 'Purchase Now'}
                    </Button>
                  </>
                )}
                <Modal
                  size="xl"
                  show={showTerms}
                  onHide={() => setShowTerms(false)}
                >
                  <Modal.Header className="text-center border-0" closeButton />
                  <Modal.Body>
                    <TermsAndConditions hideTopBar />
                  </Modal.Body>
                  <Modal.Footer />
                </Modal>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  ) : (
    <div />
  );
};

export default Package;
