import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import loginSignal from 'signals/Login.signal';
import { signIn } from 'utils/auth';
// import { auth } from 'utils/firebase';

export const handleLoginFormChange = ({ name, value }) => {
  loginSignal.update({
    [name]: value,
  });
};

export const handleSubmit = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    // Get the current user
    const user = await signIn(loginSignal.value.email, loginSignal.value.password);
    const creds = user.user;

    // Check if the user's email is verified
    if (creds) {
      alertSignal.update({
        type: 'notification',
        className: 'bg-success',
        message: 'Welcome back!',
      });
    } else if (user && !creds.emailVerified) {
      console.log('Email is not verified');
      alertSignal.update({
        type: 'alert',
        message: 'Email is not verified',
      });
    }
    if (creds) {
      return true;
    }
    return false;
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
    return error;
  } finally {
    loaderSignal.reset();
  }
};
