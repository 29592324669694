/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import {
  handlePromptChange,
  getPromptValueFromProgramSignal,
} from '../Session.helpers';

const TextAreaPrompt = ({ prompt }) => {
  const textAreaRef = useRef(null);

  const handleTextAreaClick = (event) => {
    event.stopPropagation();
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  return (
    <FormControl
      ref={textAreaRef}
      defaultValue={getPromptValueFromProgramSignal(prompt)}
      className="border border-dark rounded-0 mb-16"
      as="textarea"
      rows={7}
      onClick={handleTextAreaClick}
      placeholder="Type your answer here..."
      onBlur={({ target }) =>
        handlePromptChange({
          value: target.value,
          prompt,
        })
      }
      onMouseLeave={({ target }) =>
        handlePromptChange({
          value: target.value,
          prompt,
        })
      }
    />
  );
};

export default TextAreaPrompt;
