/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { getPromptValueFromProgramSignal } from 'components/views/Sessions/Session.helpers';

const MultiColumnResultsTable = ({ prompt }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const savedValues = getPromptValueFromProgramSignal(prompt);
    if (!savedValues) return;
    setValues(savedValues);
  }, [prompt]);

  return (
    <>
      <h5 className="mb-40 text-uppercase mt-24"> Differentiator Statements</h5>
      <Table bordered>
        <thead>
          <tr>
            <th className="text-center">Word/Phrase</th>
            <th className="text-center">Supporting Sentence</th>
          </tr>
        </thead>
        <tbody>
          {values?.words?.map((phrase, index) => (
            <tr key={index}>
              <td>
                <div>{`${index + 1}. ${phrase}`}</div>
              </td>
              <td>
                <div>{values?.sentences[index]}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default MultiColumnResultsTable;
