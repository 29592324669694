import { firestore, now } from 'utils/firebase';

export const subscribe = async ({ collection, docId, signal, shouldCreateIfNotExist, persistIfNotExist }) => {
  let existingDoc;
  let exists;
  try {
    existingDoc = await firestore.collection(collection).doc(docId);
    const { exists: docExists } = await (await existingDoc.get());
    exists = docExists;
  } catch (error) {
    // do nothing
  }

  if (!exists) {
    if (!shouldCreateIfNotExist) {
      throw new Error('Cannot subscribe to a document that doesn\'t exist');
    } else if (docId) {
      console.log('SHOULD CREATE');
      await firestore.collection(collection).doc(docId).set({
        _updatedAt: now,
        _createdAt: now,
        _id: docId,
      });
    } else {
      const createdDoc = await firestore.collection(collection).add({
        _updatedAt: now,
        _createdAt: now,
      });
      await existingDoc.update({ _id: createdDoc.id });
    }

    existingDoc = await firestore.collection(collection).doc(docId);

    if (persistIfNotExist) {
      window.localStorage.setItem(`${collection}Session`, existingDoc.id);
    }
  }

  const initDoc = await existingDoc.get();
  const initData = initDoc.data();
  signal.update({
    _id: initDoc.id,
    ...initData,
  });

  firestore.collection(collection)
    .doc(existingDoc.id)
    .onSnapshot((doc) => {
      const data = doc?.data();
      if (JSON.stringify(data || {}) !== JSON.stringify(signal.value)) {
        signal.update({
          _id: existingDoc.id,
          ...data,
        });
      }
    });

  return initData;
};

export default subscribe;
