import Fetcher from 'libs/fetcher';

const fetcher = new Fetcher();

export default async function fetchDataforSessionsList(programId, token) {
  try {
    const response = await fetcher.get({
      path: `/airtable/program/${programId}`,
      query: { airtable: 'true' },
      headers: { Authorization: token },
    });

    return response[0];
  } catch (error) {
    console.log('[ERROR]', error.message);
    return error;
  }
}
