const multiSelectData = [
  {
    id: 1,
    title: 'Archetype',
    lines: [
      'What excites you and spurs you on',
      'What ethics you live and uphold',
      'What you ultimately what to ahieve',
      'What you truly detest or cannot stand',
    ],
    select: 'Select 1-3',
  },
  {
    id: 2,
    title: 'Explorer',
    lines: [
      'Live life on their terms; Experience a full life',
      'Bravery; Adventure, Self Reliance; Discovery',
      'Freedom; Independence',
      'Small-Mindedness; Constraints of daily life',
    ],
    select: null,
  },
  {
    id: 3,
    title: 'Sage',
    lines: [
      'Search for the Truth; Explore Life’s Mysteries',
      'Understanding; Intelligence; Education; Expertise',
      'Wisdom; Knowledge',
      'Deception; Ignorance',
    ],
    select: null,
  },
  {
    id: 4,
    title: 'Innocent',
    lines: [
      'Be loved and appreciated',
      'Beauty; Romance; Connection; Faithfulness',
      'Closeness; Intamcy',
      'Isolation; Reason',
    ],
    select: null,
  },
  {
    id: 5,
    title: 'Every Person',
    lines: [
      'Be a welcome part of the community; Find peace',
      'Authenticity; Realism; Honestly Respect',
      'Beloning, Tranquility',
      'Exclusion; Lack of Authenticity',
    ],
    select: null,
  },
  {
    id: 6,
    title: 'Maverick',
    lines: [
      'Buck the status quo of life, Happily break the rules',
      'Independence; Rebellion; Audacity; Individuality',
      'Revolution; Freedom',
      'Conformity; The System',
    ],
    select: null,
  },
  {
    id: 7,
    title: 'Magician',
    lines: [
      'Dream and Enchant; Preform the Magical ',
      'Imagination; Possibility; Intention; Belief',
      'Transformation; Power',
      'Stagnation; Realty',
    ],
    select: null,
  },
  {
    id: 8,
    title: 'Solider',
    lines: [
      'Overcome with Strength; Fight for a better world',
      'Courage; Accomplishment; Sacrifice, Redemption',
      'Mastery; Redemption',
      'Incapability; Fear',
    ],
    select: null,
  },
  {
    id: 9,
    title: 'Creative',
    lines: [
      'Realize Great Ideas; Develop the new',
      'Expression; Vision; Ingenuity; Non-Conformity',
      'Imagination; Innovation',
      'Mediocrity; Reputation',
    ],
    select: null,
  },
  {
    id: 10,
    title: 'Ruler',
    lines: [
      'Take control of life; Gain deep Security',
      'Authority; Confidence; Respect; Status',
      'Prosperity, Control',
      'Chaos; Rebellion',
    ],
    select: null,
  },
  {
    id: 11,
    title: 'Caregiver',
    lines: [
      'Give generously, Support; Care and help others',
      'Comfort, Protection; Patience; Empathy',
      'Compassion; Service',
      'Exploitation; Selfishness',
    ],
    select: null,
  },
];

export default multiSelectData;
