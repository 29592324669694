import { Card, Button, ProgressBar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { fetchAndSetProgramData, programsSignal } from '../Dashboard.helpers';

const ProgramProgress = ({ modal }) => {
  const history = useHistory();
  const [sessionData, setSessionData] = useState({});

  const { sessions } = programsSignal.value;

  const init = useCallback(async () => {
    await fetchAndSetProgramData();
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (sessions?.length > 0) {
      const numOfCompletedSessions =
        sessions.filter((session) => session.completed).length || 0;
      const numOfSessions = sessions.length;
      const progress = (numOfCompletedSessions / numOfSessions) * 100;
      setSessionData({ numOfCompletedSessions, numOfSessions, progress });
    }
  }, [sessions]);

  const userHasPrograms = Object.keys(programsSignal.value).length > 0;

  return (
    <Card
      className="w-100 h-100 text-primary rounded-0 border-0 mb-32 font-josefin"
      style={{ zIndex: modal === 'ProgressModal' ? 2000 : 0 }}
    >
      <Card.Header className="position-relative bg-secondary-light rounded-0 border-0 py-16">
        <h4 className="h4 mb-0 pt-4 fs-4 ps-8">PROGRAM PROGRESS</h4>
      </Card.Header>
      <Card.Body className="text-wrap p-0">
        {!Object.keys(sessionData).length > 0 ? (
          <p className="text-center fw-bold mt-24"> No Active Program</p>
        ) : (
          <div className="p-40">
            <p className="lead-400 mb-16 fs-5">
              <span className="fw-bold">Current Program: </span>
              {`${programsSignal.value?.name}`}
            </p>
            <p className="lead-400 mb-16 fs-5">{`Sessions 1 - ${sessionData?.numOfSessions}`}</p>

            <ProgressBar
              now={sessionData?.progress}
              label={`${Math.round(sessionData?.progress)}%`}
              variant="secondary"
            />
          </div>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white border-top border-2 border-light-purple p-16">
        <Button
          disabled={!userHasPrograms}
          className="btn-outline-secondary"
          onClick={() =>
            history.push(`/sessions?programId=${programsSignal.value._id}`)
          }
        >
          {userHasPrograms ? 'Session Overview' : 'No program available'}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ProgramProgress;
