import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

const CheckoutConfirmation = () => {
  useEffect(() => {
    window.dataLayer.push({
      event: 'purchase',
    });
  }, []);

  return (
    <>
      <div
        className="position-relative w-100 px-xl-128 px-md-40 px-32 pb-64 pt-136"
        style={{
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: 'url(/images/bg-squares.png)',
        }}
      >
        <Row>
          <Col style={{ marginLeft: '6px', marginBottom: '30px' }}>
            <Link
              className="base package-back-button back-button"
              to="/packages"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back to Packages
            </Link>
          </Col>
        </Row>
        <Row className="p-0 m-0 w-100">
          <Col xs={12} className="px-16 py-104 h-100 bg-light">
            <Row className="m-0 justify-content-center">
              <img
                src="/images/bt-main.png"
                alt=""
                style={{
                  maxWidth: '200px',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Row>
            <Row className="w-50 mt-40 mx-auto justify-content-center bg-white border border-secondary">
              <div className="d-flex align-items-center flex-column py-40">
                <p className="lead text-primary mt-24 mb-32 fw-600">
                  Thank you for your purchase.
                </p>
                <Link
                  className="btn btn-outline-secondary"
                  to="/dashboard"
                >
                  Back to Dashboard
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CheckoutConfirmation;
