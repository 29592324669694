/* eslint-disable import/no-cycle */
import { handlePromptChange } from 'components/views/Sessions/Session.helpers';
import fiveWhysInputSignal from 'signals/fiveWhys.signal';

export const handleInputGroupChange = (e, idx, prompt) => {
  const { name, value } = e.target;
  const previousFiveWhysInput = fiveWhysInputSignal.value || {};
  const currentObj = fiveWhysInputSignal.value?.[idx] || {};
  const newObj = { ...currentObj, [name]: value };
  const newFiveWhysInput = { ...previousFiveWhysInput, [idx]: newObj };
  fiveWhysInputSignal.update(newFiveWhysInput);
  handlePromptChange({ value: newFiveWhysInput, prompt });
};

export default {};
