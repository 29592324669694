/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import userSignal from 'signals/User.Signal';

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      userSignal.value.isSignedIn ? (
        <Redirect to="/dashboard" />
      ) : (
        <Component isAgency={rest?.isAgency} {...props} />
      )
    }
  />
);

export default PublicRoute;
