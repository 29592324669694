/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import {
  handlePromptChange,
  getPromptValueFromProgramSignal,
} from 'components/views/Sessions/Session.helpers';
import defaultData from './data';

const TableMultiSelect = ({ inputData = defaultData, prompt }) => {
  const [checkedArray, setCheckedArray] = useState(prompt.value || []);

  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    setCheckedArray(values);
  }, [prompt]);

  const handleInputChanges = (event) => {
    const { name, checked } = event.target;
    const checkedTempArray = checkedArray?.length > 0 ? [...checkedArray] : [];
    if (checked && checkedTempArray.length < 3) {
      checkedTempArray.push(name);
      handlePromptChange({
        value: checkedTempArray,
        prompt,
      });
      setCheckedArray(checkedTempArray);
    } else if (!checked) {
      const index = checkedTempArray.indexOf(name);
      checkedTempArray.splice(index, 1);
      handlePromptChange({
        value: checkedTempArray,
        prompt,
      });
      setCheckedArray(checkedTempArray);
    } else {
      console.log('checkedArray', checkedArray);
    }
  };

  return (
    <Table
      style={{ tableLayout: 'fixed' }}
      responsive
      className="border border-2 border-black overflow-auto"
    >
      <tbody className="">
        {inputData.map((item, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === 0 ? 'rgba(98, 104, 115, 0.20)' : 'white',
            }}
            className="text-center content-center border border-black border-2"
          >
            <div
              style={{
                verticalAlign: 'middle',
                display: 'table-cell',
                backgroundColor: 'transparent',
              }}
            >
              <td
                style={{ borderBottom: 'none' }}
                className="fs-5 bg-transparent d-flex justify-content-center align-self-center "
              >
                {item.title}
              </td>
            </div>
            <td
              colSpan={3}
              className="bg-transparent border border-black border-2 py-8 "
              style={{ textAlign: 'left' }}
            >
              <p className="mx-32">
                <span className="fw-bold"> Drivers:</span> {item.lines[0]}
              </p>
              <p className="mx-32">
                <span className="fw-bold"> Values:</span> {item.lines[1]}
              </p>
              <p className="mx-32">
                <span className="fw-bold"> Goals:</span> {item.lines[2]}
              </p>
              <p className="mx-32">
                <span className="fw-bold"> Fears:</span> {item.lines[3]}
              </p>
            </td>
            <div
              style={{
                verticalAlign: 'middle',
                display: 'table-cell',
                textAlign: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <td
                style={{ borderBottom: 'none' }}
                className="text-nowrap fs-5 bg-transparent d-flex justify-content-center"
              >
                {item.select === null ? (
                  <input
                    name={index.toString()}
                    type="checkbox"
                    onChange={(e) => {
                      handleInputChanges(e, prompt);
                    }}
                    style={{ width: '32px', height: '32px' }}
                    disabled={
                      checkedArray?.length === 3 &&
                      !checkedArray?.includes(index.toString())
                    }
                    checked={
                      checkedArray?.length > 0 &&
                      checkedArray?.includes(index.toString())
                    }
                  />
                ) : (
                  item.select
                )}
              </td>
            </div>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableMultiSelect;
