import { useRef, useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';

// IMPORTANT: If there is an "all" or "all of the above" option, make its value an array of all other values

const CheckboxGroup = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  evenRowClassName,
  checkboxClassName,
  checkboxLabelClassName,
  options,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(selected)) {
      setSelected(value);
    }
  }, [value]);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const handleSelect = (val) => {
    if (selected.includes(val)) {
      const newArr = [...selected];
      const index = newArr.indexOf(val);
      newArr.splice(index, 1);
      setSelected(newArr);
    } else if (Array.isArray(val)) {
      if (arraysEqual(value, val)) {
        setSelected([]);
      } else {
        setSelected([...val]);
      }
    } else {
      setSelected(prevState => [...prevState, val]);
    }
  };

  useEffect(() => {
    if (value || selected.length) {
      onChange({ name, value: selected || [] });
    }
  }, [selected]);

  const widthMap = [null, '100%', '50%', '33%', '25%', '20%', '16.6%', '14.2%', '12.5%'];

  let columnNumber = 1;
  if ((xs || sm || md || lg || xl || xxl || xxxl)) {
    columnNumber = xs;
    if ((sm || md || lg || xl || xxl || xxxl) && windowSize.current[0] >= 576) {
      columnNumber = sm;
      if ((md || lg || xl || xxl || xxxl) && windowSize.current[0] >= 768) {
        columnNumber = md;
        if ((lg || xl || xxl || xxxl) && windowSize.current[0] >= 992) {
          columnNumber = lg;
          if ((xl || xxl || xxxl) && windowSize.current[0] >= 1200) {
            columnNumber = xl;
            if ((xxl || xxxl) && windowSize.current[0] >= 1400) {
              columnNumber = xxl;
              if (xxxl && windowSize.current[0] >= 1600) {
                columnNumber = xxxl;
              }
            }
          }
        }
      }
    }
  }

  const chunks = [];
  const array = [...options];
  while (array.length) {
    chunks.push(
      array.splice(0, columnNumber),
    );
  }
  const evenChunks = chunks.filter((chunk, idx) => idx % 2 === 0);
  const evenRowItems = evenChunks.flat();
  const evenRowValues = evenRowItems.map(item => item.value);

  return (
    <Form.Group className={className}>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <div className="d-flex flex-row flex-wrap">
        {options.map((option, idx) => {
          let isSelected;
          if (option.label.toLowerCase() === 'all of the above' || option.label.toLowerCase() === 'all') {
            isSelected = option.value.every(val => value.includes(val));
          } else {
            isSelected = !!selected.includes(option.value);
          }
          return (
            <div
              className={`d-flex align-items-center ${evenRowValues.includes(option.value) ? evenRowClassName : ''}`}
              style={{ width: widthMap[columnNumber] }}
              key={option.value + idx}
            >
              <Form.Check
                id={`${name}-${idx}`}
                key={`${name}-${idx}`}
                type="checkbox"
                className={`d-flex align-items-center ps-0 mb-0 ${inputClassName}`}
              >
                <Col className="px-0">
                  <Form.Check.Input
                    type="checkbox"
                    checked={isSelected}
                    className={`m-0 me-8 ${checkboxClassName}`}
                    onChange={() => handleSelect(option.value)}
                  />
                </Col>
                <Form.Check.Label className={`mb-0 ${checkboxLabelClassName}`}>{option.label}</Form.Check.Label>
              </Form.Check>
            </div>
          );
        })}
      </div>
    </Form.Group>
  );
};

export default CheckboxGroup;
