/* eslint-disable import/no-cycle */
import React from 'react';
import { FormControl, Row, Col } from 'react-bootstrap';
import { InputGroupContainer, InputsContainer } from './components/inputContainer';
import { handleInputGroupChange } from './InputGroups.helpers';

const InputGroups = ({ values, idx, prompt }) => (
  <div>
    <InputGroupContainer>
      <InputsContainer>
        <div className="d-flex justify-content-center">
          <h5 className="w-75"> Bottom-Line Needs for</h5>
        </div>
        <Row className="d-flex justify-content-center mx-16">
          <Col className="d-flex align-items-center mx-0" lg={1} />
          <Col className="px-0 mx-0" lg={11}>
            <FormControl
              name="title"
              onBlur={(e) => handleInputGroupChange(e, idx, prompt)}
              className="w-100 my-16 "
              placeholder="Type in your target audience..."
              defaultValue={values?.title}
            />
          </Col>
        </Row>
        {['1', '2', '3', '4', '5'].map((num, index) => (
          <Row key={num} className="d-flex-justify-content-center mx-16">
            <Col className="d-flex align-items-center mx-0" lg={1}>
              <subtitle>{`${index + 1}.`}</subtitle>
            </Col>
            <Col className="px-0 mx-0" lg={11}>
              <FormControl
                name={`needs-${num}`}
                required
                as="textarea"
                onBlur={(e) => handleInputGroupChange(e, idx, prompt)}
                style={{ height: '100px' }}
                className="w-100 my-16"
                placeholder="Begin typing ..."
                defaultValue={values?.[`needs-${num}`]}
              />
            </Col>
            <Row>
              <Col className="d-flex align-items-center mx-0" lg={1} />
              <Col className="d-flex align-items-center mx-0 " lg={10}>
                <p style={{ fontSize: '16px' }}>WHY?</p>
              </Col>
            </Row>
          </Row>

        ))
        }
        <div className="d-flex justify-content-center">
          <h5 className="my-32 w-75"> Bottom-Line Needs (AKA The Heart Pain You Solve):</h5>
        </div>
        <Row className="d-flex justify-content-center mx-16">
          <Col className="d-flex align-items-center mx-0" lg={1} />
          <Col className="px-0 mx-0" lg={11}>
            <FormControl
              name="bottomLineNeeds"
              onBlur={(e) => handleInputGroupChange(e, idx, prompt)}
              as="textarea"
              className="w-100"
              style={{ height: '125px' }}
              placeholder="Begin typing ..."
              defaultValue={values?.bottomLineNeeds}
            />
          </Col>
        </Row>
      </InputsContainer>
    </InputGroupContainer>
  </div>
);

export default InputGroups;
