import React, { useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Table } from 'react-bootstrap';
import {
  handlePromptChange,
  getPromptValueFromProgramSignal,
  screenSignal,
} from 'components/views/Sessions/Session.helpers';

const dummyData = {
  title: "Our Brand's Personality Traits",
  instructions:
    'Select the appropriate number on the scale to indicate the rating, based on how well your company aligns with the given characteristic.',
  tableData: {
    scale: 5,
    headers: {
      labelOne: 'Characteristic',
      labelTwo: 'Characteristic',
    },
    rows: [
      {
        id: 1,
        labelOne: 'Elite',
        labelTwo: 'Approachable',
        selectedValue: 5,
      },
      {
        id: 2,
        labelOne: 'Serious',
        labelTwo: 'Playful',
        selectedValue: null,
      },
      {
        id: 3,
        labelOne: 'Conventional',
        labelTwo: 'Rebel',
        selectedValue: null,
      },
      {
        id: 4,
        labelOne: 'Authoritative',
        labelTwo: 'Friendly',
        selectedValue: null,
      },
      {
        id: 5,
        labelOne: 'Mature',
        labelTwo: 'Youthful',
        selectedValue: null,
      },
      {
        id: 6,
        labelOne: 'Classic',
        labelTwo: 'Innovative',
        selectedValue: null,
      },
      {
        id: 7,
        labelOne: 'Feminine',
        labelTwo: 'Masculine',
        selectedValue: null,
      },
      {
        id: 8,
        labelOne: 'Complex',
        labelTwo: 'Simple',
        selectedValue: null,
      },
      {
        id: 9,
        labelOne: 'Subtle',
        labelTwo: 'Bright',
        selectedValue: null,
      },
      {
        id: 10,
        labelOne: 'Expensive',
        labelTwo: 'Economical',
        selectedValue: null,
      },
      {
        id: 11,
        labelOne: 'Mainstream',
        labelTwo: 'Uncovnentional',
        selectedValue: null,
      },
      {
        id: 12,
        labelOne: 'Elegant',
        labelTwo: 'Casual',
        selectedValue: null,
      },
      {
        id: 13,
        labelOne: 'Refined',
        labelTwo: 'Strong',
        selectedValue: null,
      },
    ],
  },
};

const ScaleChart = ({ prompt }) => {
  const [tableData, setTableData] = useState(dummyData.tableData);
  const emptyScaleArray = new Array(dummyData.tableData.scale).fill(null);

  const handleTableCellClick = (xIndex, yIndex) => {
    const newTableData = JSON.parse(JSON.stringify(tableData));
    newTableData.rows[yIndex].selectedValue = xIndex + 1;
    setTableData(newTableData);
    handlePromptChange({
      value: newTableData,
      prompt,
    });
  };

  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    if (values && values !== undefined && values !== null) {
      setTableData(values);
    } else {
      setTableData(dummyData.tableData);
    }
  }, [prompt]);

  return (
    <Container className="border border-secondary border-3 pt-xl-80 py-48 rounded-0 bg-light">
      <Row className="bg-light">
        <Col lg={8} className="offset-lg-2">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img
              src="/images/decorative-icon-1.svg"
              className="pb-8"
              alt="decorative"
            />
            <div className="h3 text-primary text-uppercase text-center mb-0 mx-56">
              {dummyData.title}
            </div>
            <img src="/images/decorative-icon-1.svg" alt="" className="pb-8" />
          </div>
          <div
            className="bg-secondary w-50 mx-auto"
            style={{ height: '2px' }}
          />
          <div className="text-dark mb-lg-48 my-24 text-center base">
            {dummyData.instructions}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          lg={12}
          xl={12}
          className="text-center d-flex justify-content-center"
        >
          <Table>
            <tr className="bg-primary text-white">
              <th className="py-4 border border-2 border-primary px-lg-24 px-xl-32">
                {tableData?.headers?.labelOne}
              </th>
              {emptyScaleArray.map((value, index) => (
                <th
                  key={index}
                  className="py-4 border border-2 border-primary px-lg-24 px-xl-32 text-center"
                >
                  {index + 1}
                </th>
              ))}
              <th className="py-4 border border-2 border-primary px-lg-24 px-xl-32">
                {tableData?.headers?.labelTwo}
              </th>
            </tr>
            {tableData?.rows?.map((row, yIndex) => (
              <tr>
                <td
                  className="py-8 px-lg-24 px-xl-32 small"
                  style={{
                    borderBottom: '2px solid #33394A',
                    borderLeft: '2px solid #33394A',
                  }}
                >
                  {row.labelOne}
                </td>
                {emptyScaleArray.map((td, xIndex) => (
                  <td
                    key={xIndex}
                    aria-disabled={screenSignal.value?.style === 'checkpoint'}
                    className="text-center scale-chart-cell p-0"
                    style={{
                      borderBottom: '2px solid #33394A',
                      borderLeft: '1px solid #33394A',
                      borderRight: '1px solid #33394A',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleTableCellClick(xIndex, yIndex)}
                  >
                    {row.selectedValue === xIndex + 1 ? (
                      <FontAwesomeIcon
                        className="lead-600 p-0"
                        icon={faXmark}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                ))}
                <td
                  className="px-lg-24 px-xl-32 small"
                  style={{
                    borderBottom: '2px solid #33394A',
                    borderRight: '2px solid #33394A',
                  }}
                >
                  {row.labelTwo}
                </td>
              </tr>
            ))}
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ScaleChart;
