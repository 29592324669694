// import onBoardingSignal from 'signals/OnBoarding.signal';
import { apiFetch } from 'api/api';
import alertSignal from 'signals/Alert.signal';
import Signal from 'libs/signals';
import loaderSignal from 'signals/Loader.signal';

export const onBoardingSignal = Signal({});

export const handleFormChange = ({ name, value }) => {
  onBoardingSignal.update({
    [name]: value,
  });
};

export const handleOnBoardingSubmit = async (businessName, type, companySize, token) => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const data = {
      businessName,
      type,
      companySize,
    };
    const response = await apiFetch({
      path: '/users/onBoarding',
      body: data,
      method: 'POST',
      token,
    });

    alertSignal.update({
      type: 'notification',
      className: 'bg-success',
      message: 'Organization saved!',
    });

    return response;
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
    return error;
  } finally {
    loaderSignal.reset();
  }
};
