import { apiFetch } from 'api/api';
import Signal from 'libs/signals';
import alertSignal from 'signals/Alert.signal';
import userSignal from 'signals/User.Signal';

export const agencyPurchasesListSignal = Signal([]);

export const getAgencyPurchasesList = async ({ orgData }) => {
  const token = userSignal.value.fbUser.Aa;
  let response = [];
  try {
    if (orgData.length > 1) {
      const request = await apiFetch({
        path: '/agency/purchases',
        method: 'POST',
        token,
        body: orgData,
      });
      if (request.data) {
        response = request?.data;
      } else {
        response = [];
      }
    }

    agencyPurchasesListSignal.update(response);
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: 'An error occured when fetching the data',
      error,
    });
  }
};
