// import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelect = ({
  value,
  label,
  name,
  onChange,
  className,
  labelClassName,
  inputClassName,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  disabled,
}) => {
  const handleChange = (newVal) => {
    onChange({ name, value: newVal });
  };
  return (
    <div className={className}>
      {label ? <p className={labelClassName}>{label}</p> : null}
      <DatePicker
        disabled={disabled}
        name={name}
        selected={value || new Date()}
        onChange={handleChange}
        wrapperClassName="custom-datepicker"
        className={inputClassName || ''}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
      />
    </div>
  );
};
export default DateSelect;
