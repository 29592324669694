import { useEffect, useState } from 'react';
import { Button, Container, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import {
  screenSignal,
  sessionSignal,
  progressBarStyle,
} from '../Session.helpers';

const SessionHeader = ({ setShow }) => {
  const [finalValue, setFinalValue] = useState();

  useEffect(() => {
    const calculation = progressBarStyle({
      current: screenSignal.value.number,
      count: sessionSignal.value.screens?.length,
    });

    if (screenSignal.value.number === 1) {
      setFinalValue(0);
    } else if (screenSignal.value.number !== 1 && calculation !== 0) {
      setFinalValue(calculation);
    }
  }, [screenSignal.value.number, sessionSignal.value.screens?.length]);

  return (
    <>
      <div className="d-flex justify-content-end py-16">
        <Button
          className="border-0 bg-transparent"
          onClick={() => setShow(true)}
        >
          <FontAwesomeIcon icon={faX} className="text-secondary fw-bold fs-5" />
        </Button>
      </div>
      <div className="text-light pt-24 text-center">
        <p className="fs-4 mb-8 text-capitalize">
          {sessionSignal.value?.title}
        </p>
        <p>{screenSignal.value.title}</p>
        <div className="px-lg-32">
          <Container className="d-flex justify-content-center">
            <ProgressBar
              className="bg-primary-light w-100 mb-24"
              variant="secondary"
              style={{ maxWidth: '950px' }}
              now={finalValue}
            />
          </Container>
        </div>
      </div>
    </>
  );
};

export default SessionHeader;
