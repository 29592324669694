import React, { useEffect, useState } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import {
  InputGroupContainer,
  InputsContainer,
} from 'components/global/InputGroups/components/inputContainer';
import topTargetAudienceSignal from 'signals/TopTargetAudiences.signal';
import repeaterTextInputSignal from 'signals/RepeaterTextInput.signal';
import {
  getPromptValueFromProgramSignal,
  handlePromptChange,
} from 'components/views/Sessions/Session.helpers';

const TopTargetAudienceInput = ({ prompt }) => {
  const [rows, setRows] = useState([1]);

  const handleAddRow = () => {
    setRows([...rows, rows.length + 1]);
  };

  const handleInputChange = (e) => {
    if (prompt.type === 'topTargetAudience') {
      const { name, value } = e.target;
      topTargetAudienceSignal.update({
        rows,
        [name]: value,
      });
      handlePromptChange({
        value: topTargetAudienceSignal.value,
        prompt,
      });
    } else if (prompt.type === 'repeaterTextInputTable') {
      const { name, value } = e.target;
      repeaterTextInputSignal.update({
        rows,
        [name]: value,
      });
      handlePromptChange({
        value: repeaterTextInputSignal.value,
        prompt,
      });
    }
  };

  useEffect(() => {
    const values = getPromptValueFromProgramSignal(prompt);
    if (values && prompt.type === 'topTargetAudience') {
      topTargetAudienceSignal.update(values);
      setRows(Object.keys(values).filter((key) => !['rows'].includes(key)));
    } else if (values && prompt.type === 'repeaterTextInputTable') {
      repeaterTextInputSignal.update(values);
      setRows(Object.keys(values).filter((key) => !['rows'].includes(key)));
    }
  }, [prompt]);

  return (
    <InputGroupContainer>
      <InputsContainer>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <img alt="img" src="/images/decorative-icon-1.svg" className="pb-8" />
          <div className="h4 text-black text-uppercase text-center mb-0 mx-56">
            Top Target Audiences
          </div>
          <img alt="img" src="/images/decorative-icon-1.svg" className="pb-8" />
        </div>
        <div className="d-flex justify-content-center align-self-center mb-8">
          <hr
            className="text-secondary"
            style={{
              border: '2px solid',
              width: '75%',
            }}
          />
        </div>
        <div className="text-center mb-32">
          <subtitle
            style={{ fontSize: '16px', textAlign: 'center' }}
            className="text-center"
          >
            Please list your target audiences by number.
          </subtitle>
        </div>
        {rows.map((row, idx) => (
          <div key={idx} className="d-flex justify-content-center">
            {row}.
            <FormControl
              className="mx-8 mb-24"
              placeholder="Begin typing..."
              name={row}
              onBlur={handleInputChange}
              defaultValue={
                prompt.type === 'topTargetAudience'
                  ? topTargetAudienceSignal.value[row] || ''
                  : repeaterTextInputSignal.value[row] || ''
              }
            />
          </div>
        ))}
        <Button
          className="px-0 mt-8 text-black"
          variant="none"
          onClick={handleAddRow}
        >
          <FontAwesomeIcon
            className="text-black"
            size="lg"
            icon={faCirclePlus}
          />{' '}
          Add row
        </Button>
      </InputsContainer>
    </InputGroupContainer>
  );
};

export default TopTargetAudienceInput;
