/* eslint-disable no-constant-condition */
/* eslint-disable arrow-body-style */
import { Container, Card, Row, Col, Form, FormControl } from 'react-bootstrap';
import { TextWithLineBreaks } from './SessionPrompts';
import {
  screenSignal,
  checkpointModeSignal,
  switchPromptType,
  handlePromptChange,
  getPromptValueFromProgramSignal,
  getCheckPointChart,
} from '../Session.helpers';

const SessionCheckpoint = () => {
  return (
    <Container className="text-center pb-160">
      {checkpointModeSignal.value === 'review' ? (
        <Card
          className="border-secondary pt-xl-80 pt-48 pb-32 rounded-0 px-lg-40 px-32 bg-light"
          style={{ borderWidth: '3px' }}
        >
          <h3>Review Your Work</h3>
          {screenSignal.value?.checkpoints?.map((checkpoint, idx) => (
            <>
              {checkpoint?.prompts?.map((prompt) => (
                <Row
                  key={idx}
                  className="mt-24 py-24 px-8 border border-secondary"
                >
                  <Col>
                    {prompt.type?.trim() === 'textArea' && ( // For some reason there is a space so we have to trim
                      <>
                        <h5>{prompt.prompt.toUpperCase()}</h5>
                        <div>{getPromptValueFromProgramSignal(prompt)}</div>
                      </>
                    )}
                    {prompt.type?.trim() === 'chart' && ( // For some reason there is a space so we have to trim
                      <>
                        <h5>{prompt.prompt}</h5>
                        <div>{prompt.value}</div>
                      </>
                    )}
                    {getCheckPointChart({ type: prompt.type, prompt }) ===
                    'textarea'
                      ? ''
                      : getCheckPointChart({ type: prompt.type, prompt })}
                  </Col>
                </Row>
              ))}
            </>
          ))}
          {screenSignal.value?.textBelowContent ? (
            <Container
              style={{
                maxWidth: '900px',
                maxHeight: '200px',
                overflow: 'auto',
              }}
              className="mt-32"
            >
              <Row
                className="p-0"
                style={{ backgroundColor: '#dad8d8', textAlign: 'left' }}
              >
                <Col className="py-8 px-24 col-12 text-center">
                  <TextWithLineBreaks
                    text={screenSignal.value?.textBelowContent}
                  />
                </Col>
              </Row>
            </Container>
          ) : null}
        </Card>
      ) : (
        <>
          {screenSignal.value.checkpoints?.map((checkpoint, idx) => (
            <>
              {checkpoint?.prompts?.map((prompt) => (
                <Card
                  key={idx}
                  className="border-secondary pt-xl-80 pt-48 pb-32 mb-32 rounded-0 px-lg-40 px-32 bg-light"
                  style={{ borderWidth: '3px' }}
                >
                  <Row className="mb-lg-16 mb-lg-48" key={prompt.id}>
                    <Col className="col-12 px-32 mt-16">
                      <Form.Label className="text-dark lead-400 text-uppercase">
                        {prompt.prompt}
                      </Form.Label>
                      {'tableMultiSelect' === 'textArea' ? (
                        <FormControl
                          value={getPromptValueFromProgramSignal(prompt)}
                          className="border border-dark rounded-0"
                          as={
                            prompt.type === 'textArea' ? 'textarea' : 'textarea'
                          }
                          rows={5}
                          placeholder="Type your answer here..."
                          onChange={({ target }) =>
                            handlePromptChange({
                              value: target.value,
                              idx,
                              prompt,
                            })
                          }
                        />
                      ) : (
                        switchPromptType({ type: prompt.type, prompt })
                      )}
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          ))}
        </>
      )}
    </Container>
  );
};

export default SessionCheckpoint;
