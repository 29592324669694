import { useState, useEffect } from 'react';
import { Row, Col, Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import {
  handleFormChange,
  handleInitContributorsData,
} from 'components/views/Settings/Settings.helpers';
import settingsSignal from 'signals/Settings.signal';
import { apiFetch } from 'api/api';
import userSignal from 'signals/User.Signal';
import alertSignal from 'signals/Alert.signal';
import EditTeamMember from './EditTeamMember';

const TeamMember = ({ member, idx }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleClickEdit = () => {
    handleFormChange({ name: 'isAddingContributor', value: false });
    handleFormChange({
      name: 'newName',
      value: settingsSignal.value.contributors[idx].name,
    });
    handleFormChange({
      name: 'newEmail',
      value: settingsSignal.value.contributors[idx].email,
    });
    handleFormChange({
      name: 'newRole',
      value: settingsSignal.value.contributors[idx].role,
    });
    setIsEditing(true);
  };

  useEffect(() => {
    if (settingsSignal.value.isAddingContributor) {
      setIsEditing(false);
    }
  }, [settingsSignal.value.isAddingContributor]);

  if (isEditing) {
    return <EditTeamMember idx={idx} setIsEditing={setIsEditing} />;
  }

  const handleClickRemove = async () => {
    try {
      const request = await apiFetch({
        path: '/users',
        method: 'DELETE',
        body: {
          email: settingsSignal.value.contributors[idx].email,
        },
        token: userSignal.value.fbUser.Aa,
      });

      if (request) {
        alertSignal.update({
          type: 'notification',
          message: 'Data updated',
          className: 'bg-success',
        });
      }

      handleInitContributorsData();
    } catch (error) {
      alertSignal.update({
        type: 'alert',
        message: error.message,
        error,
      });
    }
  };

  return (
    <div
      className="position-relative border-bottom border-secondary py-32 ps-32"
      style={{
        direction: 'ltr',
      }}
    >
      <div>
        <Row>
          <Col lg={10} xs={10}>
            <div className="lead mb-8">{member.name}</div>
          </Col>
          <Col lg={2} xs={2} className="position-relative">
            <Dropdown>
              <Dropdown.Toggle className="bg-transparent border-0 w-100 px-16">
                <FontAwesomeIcon icon={faPencil} className="text-primary" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-shadow">
                <Dropdown.Item eventKey="1">
                  <Button
                    className="bg-transparent text-primary border-0"
                    onClick={() => handleClickEdit()}
                  >
                    Edit User
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleClickRemove}
                  eventKey="2"
                  className="text-danger"
                >
                  Remove Contributor
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <div className="base">
        <a href={`mailto:${member.email}`}>{member.email}</a>
      </div>
      <div className="small pt-8">{member.role}</div>
      {member.isAccepted ? (
        <div className="small pt-8">Active</div>
      ) : (
        <div className="small pt-8" style={{ color: 'red' }}>
          Pending
        </div>
      )}
    </div>
  );
};

export default TeamMember;
