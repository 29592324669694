import { useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import Field from 'components/global/Field/Field';
import selectPlanSignal from 'signals/SelectPlan.signal';
import { handleFormChange, handleCvcChange, handleExpirationDateChange } from '../SelectPlan.helpers';

const PaymentInfo = ({ onToggleCoaching, onSubmit }) => {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const signal = selectPlanSignal.value;

  return (
    <>
      <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-64 px-192">
        <h2 className="text-primary mb-16">
          THE BRAND TRIFECTA - {signal.plan === 'oneTimePurchase' ? 'FULL PAYMENT' : '6 MONTHLY PAYMENTS'}
        </h2>
        <h2 className="text-secondary mb-24">{signal.totalCost && signal.totalCost.toLocaleString()}
        </h2>
        <p className="mb-24 align-self-start">Contact Information</p>
        <Row className="m-0 p-0 w-100">
          <Col xs={6} className="ps-0 pe-16">
            <Field
              type="text-input"
              name="firstName"
              value={signal.firstName}
              placeholder="First Name"
              className="w-100"
              inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
              onChange={handleFormChange}
            />
          </Col>
          <Col xs={6} className="ps-16 pe-0">
            <Field
              type="text-input"
              name="lastName"
              value={signal.lastName}
              placeholder="Last Name"
              className="w-100"
              inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
              onChange={handleFormChange}
            />
          </Col>
        </Row>
        <Row className="w-100 mx-0 p-0 mt-24 mb-80">
          <Col xs={6} className="ps-0 pe-16">
            <Field
              type="text-input"
              name="email"
              value={signal.email}
              placeholder="Email Address"
              className="w-100"
              inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
              onChange={handleFormChange}
            />
          </Col>
          <Col xs={6} className="ps-16 pe-0">
            <Field
              type="phone"
              name="phone"
              value={signal.phone}
              placeholder="Phone Number"
              className="w-100"
              inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
              onChange={handleFormChange}
            />
          </Col>
        </Row>
        <p className="mb-24 align-self-start">Contact Information</p>
        {/* TODO: use validate card number code once we know which cards will be accepted */}
        <Field
          type="integer"
          name="cardNumber"
          value={signal.cardNumber}
          placeholder="Card Number"
          className="w-100 mb-24"
          inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
          onChange={handleFormChange}
        />
        <Row className="m-0 p-0 w-100 mb-80">
          <Col xs={6} className="ps-0 pe-16">
            <Field
              type="text-input"
              name="expirationDate"
              value={signal.expirationDate}
              placeholder="MM/YY"
              className="w-100"
              inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
              onChange={(e) => handleExpirationDateChange(e.value)}
            />
          </Col>
          <Col xs={6} className="ps-16 pe-0">
            <Field
              type="text-input"
              name="cvc"
              value={signal.cvc}
              placeholder="CVC"
              className="w-100"
              inputClassName="w-100 bg-transparent border border-primary-light border-2 rounded-0 py-16"
              onChange={handleCvcChange}
            />
          </Col>
        </Row>
        <p className="mb-24 align-self-start">Special Offer</p>
        <Button
          className="d-flex flex-row align-items-center w-100 bg-transparent border border-secondary border-3 rounded-0 py-32 px-40 mb-80"
          onClick={() => onToggleCoaching(!signal.isBuyingCoaching)}
        >
          <div className="h-100">
            <Image src="/images/circle-plus-secondary.svg" className="me-32" />
          </div>
          <div>
            <p className="fs-5 mb-8 text-primary">Follow Up Coaching Session With Kate</p>
            <p className="mb-0 text-wrap text-primary-light">1 hour high value session with Kate to debreif Brand Trifecta and gather tools to launch post course work </p>
          </div>
          <p className="fs-3 fw-bold text-secondary align-self-start ms-32">$250</p>
        </Button>
        <p className="mb-24 align-self-start">Order Summary</p>
        <div className="d-flex flex-row justify-content-between align-items-center w-100 bg-transparent border border-secondary border-3 px-32 py-16 mb-24">
          <p className="text-primary mb-0">The Brand Trifecta - {signal.plan === 'oneTimePurchase' ? 'Full Payment' : '6 Monthly Payments'}</p>
          <p className="text-primary fs-5 mb-0">{signal.plan === 'oneTimePurchase' ? '$1,800' : '$300'}</p>
        </div>
        {signal.isBuyingCoaching && (
        <div className="d-flex flex-row justify-content-between align-items-center w-100 bg-transparent border border-secondary border-3 px-32 py-16 mb-24">
          <p className="text-primary mb-0">Followup Coaching Session With Kate</p>
          <p className="text-primary fs-5 mb-0">$250</p>
        </div>
        )}
        <div className="d-flex flex-row justify-content-between align-items-center w-100 bg-transparent border border-secondary border-3 px-32 py-16 mb-24">
          <p className="text-primary mb-0">Total</p>
          <p className="text-primary fs-5 mb-0">${signal.totalCost && signal.totalCost.toLocaleString()}</p>
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center w-100 bg-transparent border border-secondary border-3 px-32 py-16 mb-24">
          <Field
            type="checkbox"
            value={agreeToTerms}
            className="bg-light my-0 me-8"
            onChange={() => setAgreeToTerms(!agreeToTerms)}
          />
          {/* TODO: Terms and Conditions needs to be underlined and be clickable, once we find out what it does */}
          <p className="mb-0 text-primary">I agree to the Terms and Conditions</p>
        </div>
        <Button
          className="d-flex align-items-center justify-content-center w-100 fs-3 bg-primary text-secondary-light fw-bolder border-0 rounded-0"
          onClick={onSubmit}
          disabled={
            !agreeToTerms
            || !signal.firstName
            || !signal.lastName
            || !signal.email
            || !signal.phone
            || !signal.cardNumber
            || !signal.expirationDate
            || !signal.cvc
          }
        >Place Order Now
        </Button>
      </div>
    </>
  );
};

export default PaymentInfo;
