import { useEffect, useState } from 'react';
import Loader from 'components/global/Loader';
import loaderSignal from 'signals/Loader.signal';
import Alert from '../Alert';

const ContentWrapper = ({ children, className }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [3000]);
  }, []);

  if (isLoading || loaderSignal.value.isContentLoading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
        <Loader message={loaderSignal.value.message} className="text-center" />
      </div>
    );
  }

  return (
    <div className={`container-content-md mt-lg-5 px-2 ${className || ''}`}>
      <Alert />
      {children}
    </div>
  );
};

export default ContentWrapper;
